import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/authActions';
import { Link } from 'react-router-dom';
import TextField from '../inputs/TextField';
import Button from '../common/Button';
import { Translate } from 'react-localize-redux';

export class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.role === 'COORDINATOR') {
        this.props.history.push('/dashboard');
      } else if (
        this.props.auth.user.role === 'ADMIN' ||
        this.props.auth.user.role === 'SUPER'
      ) {
        this.props.history.push('/admin/dashboard');
      } else if (this.props.auth.user.role === 'PARENT') {
        this.props.history.push('/parent/dashboard');
      } else if (this.props.auth.user.role === 'DISTRIBUTOR') {
        this.props.history.push('/distributor/dashboard');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // Once the user is authenticated redirect to dashboard
    if (nextProps.auth.isAuthenticated) {
      if (nextProps.auth.user.role === 'COORDINATOR') {
        this.props.history.push('/dashboard');
      } else if (
        nextProps.auth.user.role === 'ADMIN' ||
        nextProps.auth.user.role === 'SUPER'
      ) {
        this.props.history.push('/admin/dashboard');
      } else if (nextProps.auth.user.role === 'PARENT') {
        this.props.history.push('/parent/dashboard');
      } else if (nextProps.auth.user.role === 'DISTRIBUTOR') {
        this.props.history.push('/distributor/dashboard');
      }
    }
    if (nextProps.errors) {
      // Check for errors\
      this.setState({ errors: nextProps.errors });
      if (nextProps.errors.redirect) {
        this.props.history.push(`/verify-account?email=${this.state.email}`);
      }
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    const userData = {
      email: email,
      password: password
    };
    this.props.loginUser(userData);
  };

  render() {
    const { email, password } = this.state;
    const { errors } = this.state;

    return (
      <div>
        <h1>
          <Translate id='login.title' />
        </h1>
        <p>
          <Translate id='login.heading' />
        </p>
        <p>
          <Translate id='login.hint' />
        </p>
        <div className='form-container'>
          <form className='mt-5 mb-5'>
            <TextField
              name='email'
              placeholder='Email Address'
              value={email}
              label={<Translate id='user.emailAddress' />}
              type='email'
              orientation='horizontal'
              onChange={this.onChange}
              error={errors.email ? errors.email : errors.message}
            />
            <TextField
              name='password'
              placeholder='Password'
              value={password}
              label={<Translate id='user.password' />}
              type='password'
              autoComplete='current-password'
              orientation='horizontal'
              onChange={this.onChange}
              error={errors.password ? errors.password : errors.message}
            />

            <div className='d-flex justify-content-between align-items-center'>
              <Link to='/forgot-password' className='btn-link'>
                <Translate id='login.forgotPassword' />
              </Link>
              <Button
                text={<Translate id='button.login' />}
                onClick={this.handleSubmit}
                className='btn btn-primary'
                isLoading={this.props.auth.loading}
              />
            </div>
          </form>
          <div className='text-center'>
            <p>
              <Translate id='login.notRegistered' />
            </p>
            <Link to='/register' className='btn btn-primary'>
              <Translate id='button.register' />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
