import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNotes, clearNotes } from '../../redux/actions/schoolActions';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { isEmpty } from '../../utils';
import SchoolNote from '../notes/SchoolNote';
import Button from '../common/Button';
import { Modal } from 'react-bootstrap';
import CreateNote from './CreateNote';
import axios from 'axios';
import AsyncSelect from '../inputs/AsyncSelect';
import moment from 'moment';

export class SchoolNotes extends Component {
  state = {
    showAddModal: false,
    school: null,
    loadingExport: false,
    disableSelect: false,
    errors: {}
  };

  componentDidMount() {
    if (this.props.schoolId) {
      this.setState({
        disableSelect: true,
        school: { label: this.props.schoolName, value: this.props.schoolId }
      });
      this.props.getNotes(this.props.schoolId);
    }
  }

  componentWillUnmount = () => {
    this.props.clearNotes();
  };

  renderList = (loading, notes) => {
    if (loading) {
      return <Spinner />;
    } else if (!isEmpty(notes)) {
      const noteList = notes.map(note => {
        return (
          <SchoolNote
            userId={this.props.userId}
            key={note.id}
            note={note}
            schoolId={this.state.school.value}
          />
        );
      });
      return <ul className='list-group'>{noteList}</ul>;
    } else
      return (
        <div className='container'>
          {this.state.school ? <p>Looks like there's nothing here.</p> : null}
        </div>
      );
  };

  showAddModal = () => {
    this.setState({ showAddModal: true });
  };
  hideAddModal = () => {
    this.setState({ showAddModal: false });
  };
  handleSelectSchool = school => {
    this.setState({ school });
    if (school) {
      const id = school.value;
      this.props.getNotes(id);
    }
  };
  loadSchoolOptions = inputValue =>
    new Promise((resolve, reject) => {
      this.props.clearNotes();
      const payload = {
        name: inputValue
      };
      const url = '/api/schools/autocomplete';

      axios
        .get(url, { params: payload })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });

  handleExport = () => {
    const { school } = this.state;
    const url = `/api/schools/${school.value}/notes`;
    const payload = {
      csv: true
    };
    this.setState({ loadingExport: true });
    axios
      .get(url, { params: payload })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `notes-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        this.setState({ loadingExport: false });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loadingExport: false });
      });
  };

  render() {
    const { notes, loading } = this.props;
    const { showAddModal, school, disableSelect } = this.state;

    return (
      <div className='container mt-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Notes</h2>
          <div>
            <Button
              text='Export notes'
              className='btn btn-primary mr-3'
              onClick={this.handleExport}
              disabled={school ? false : true}
            />
            <Button
              text='Add note'
              className='btn btn-primary'
              onClick={this.showAddModal}
              disabled={school ? false : true}
            />
          </div>
        </div>

        <div className='row mt-2'>
          {this.state.disableSelect ? (
            <div className='col-4'>
              <h3>{this.state.school.label}</h3>
            </div>
          ) : (
            <div className='col-4'>
              <AsyncSelect
                name='school'
                label='Select a school to view notes'
                placeholder='Search for a School'
                value={school}
                orientation='vertical'
                loadOptions={this.loadSchoolOptions}
                onChange={this.handleSelectSchool}
                isClearable={false}
                disabled={disableSelect}
              />
            </div>
          )}
          <div className='col-8'>{this.renderList(loading, notes)}</div>
        </div>

        <Modal show={showAddModal} size='md' onHide={this.hideAddModal}>
          <Modal.Header>
            <Modal.Title>New note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateNote
              schoolId={school ? school.value : null}
              hideModal={this.hideAddModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

SchoolNotes.propTypes = {
  notes: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getNotes: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolName: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  notes: state.school.notes,
  loading: state.school.loading,
  userId: state.auth.user.id
});

const mapDispatchToProps = {
  getNotes,
  clearNotes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolNotes);
