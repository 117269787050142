import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  editSchool,
  getSchoolBoards,
  getSchoolById,
  clearErrors,
} from "../../redux/actions/schoolActions";
import { isEmpty } from "../../utils";
import TextField from "../inputs/TextField";
import Checkbox from "../inputs/Checkbox";
import Spinner from "../common/Spinner";
import DummyField from "../inputs/DummyField";
import Button from "../common/Button";
import Select from "../inputs/Select";
import NumberFormat from "react-number-format";
import { Translate } from "react-localize-redux";
import FormSeparator from "../inputs/FormSeparator";
import _ from "lodash";
import { Modal } from "react-bootstrap";

import {
  regionOptions,
  provinceOptions,
  schoolTypeOptions,
  languagesOptions,
  prefLanguageOptions,
  gradesOptions,
} from "./FormOptions";
import SchoolCoordinators from "./SchoolCoordinators";
import DistributorSelect from "../inputs/DistributorSelect";

export class SchoolProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      schoolBoard: null,
      street: "",
      street2: "",
      city: "",
      region: null,
      province: null,
      postalCode: "",
      telephone: "",
      schoolPhoneExtension: "",
      fax: "",
      website: "",
      principalName: "",
      principalEmail: "",
      principalTitle: "",
      principalPhone: "",
      schoolType: null,
      languages: null,
      prefLanguage: null,
      lowGrade: null,
      highGrade: null,
      gradeQualifier: "",
      numberStudents: "",
      milkCoordinator: "",
      milkCoordinatorEmail: "",
      milkCoordinatorPhone: "",
      coordinatorPosition: "",
      milkPrice: "",
      chocMilkPrice: "",
      milkCost: "",
      chocMilkCost: "",
      distributor: { label: "", value: "" },
      showModal: false,
      schoolCashOnline: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.props.getSchoolBoards();
    if (!isEmpty(this.props.school)) {
      this.updateState();
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }

    if (prevProps.boards !== this.props.boards) {
      this.setState({
        schoolBoard: _.find(this.props.boards, (item) => {
          return item.value === this.props.school.school_board_id;
        }),
      });
    }

    if (!_.isEqual(this.props.school, prevProps.school)) {
      this.updateState();
    }
  }

  updateState = () => {
    this.setState({
      id: this.props.school.id,
      name: this.props.school.name,
      schoolBoard: _.find(this.props.boards, (item) => {
        return item.value === this.props.school.school_board_id;
      }),
      street: this.props.school.address1,
      street2: this.props.school.address2,
      city: this.props.school.city,
      region: _.find(regionOptions, (item) => {
        return item.value === this.props.school.region;
      }),
      province: _.find(provinceOptions, (item) => {
        return item.value === this.props.school.province;
      }),
      postalCode: this.props.school.postal_code,
      telephone: this.props.school.phone,
      schoolPhoneExtension: this.props.school.phone_ext,
      fax: this.props.school.fax,
      website: this.props.school.website,
      principalName: this.props.school.principal_name,
      principalEmail: this.props.school.principal_email,
      principalTitle: this.props.school.principal_title,
      principalPhone: this.props.school.principal_phone,
      schoolType: _.find(schoolTypeOptions, (item) => {
        return item.value === this.props.school.school_type;
      }),
      languages: _.find(languagesOptions, (item) => {
        return item.value === this.props.school.language;
      }),
      prefLanguage: _.find(prefLanguageOptions, (item) => {
        return item.value === this.props.school.preferred_language;
      }),
      lowGrade: _.find(gradesOptions, (item) => {
        return item.value === this.props.school.low_grade;
      }),
      highGrade: _.find(gradesOptions, (item) => {
        return item.value === this.props.school.high_grade;
      }),
      gradeQualifier: this.props.school.grade_qualifier,
      numberStudents: this.props.school.num_students,
      milkCoordinator: this.props.school.coordinator_name,
      milkCoordinatorEmail: this.props.school.coordinator_email,
      milkCoordinatorPhone: this.props.school.coordinator_phone,
      coordinatorPosition: this.props.school.coordinator_role,
      milkPrice: this.props.school.white_milk_price, // New Field
      chocMilkPrice: this.props.school.chocolate_milk_price, // New Field
      milkCost: this.props.school.white_milk_cost,
      chocMilkCost: this.props.school.chocolate_milk_cost,
      distributor: this.props.school.dairy_id
        ? this.props.school.distributor
        : { label: "", value: "" },
      coordinators: this.props.school.coordinators,
      schoolCashOnline: this.props.school.uses_SCO,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };
  handleSelectSchoolBoard = (schoolBoard) => {
    this.setState({ schoolBoard });
  };
  handleSelectRegion = (region) => {
    this.setState({ region });
  };
  handleSelectProvince = (province) => {
    this.setState({ province });
  };
  handleSelectSchoolType = (schoolType) => {
    this.setState({ schoolType });
  };
  handleSelectLanguages = (languages) => {
    this.setState({ languages });
  };
  handleSelectPrefLanguage = (prefLanguage) => {
    this.setState({ prefLanguage });
  };
  handleSelectLowGrades = (lowGrade) => {
    this.setState({ lowGrade });
  };
  handleSelectHighGrades = (highGrade) => {
    this.setState({ highGrade });
  };
  handleSCO = () => {
    const val = this.state.schoolCashOnline;
    this.setState({ schoolCashOnline: !val });
  };

  // Figures out whether it needs to register or edit a school
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      schoolBoard,
      street,
      street2,
      city,
      region,
      province,
      postalCode,
      telephone,
      schoolPhoneExtension,
      fax,
      website,
      principalName,
      principalEmail,
      principalTitle,
      principalPhone,
      schoolType,
      languages,
      prefLanguage,
      lowGrade,
      highGrade,
      gradeQualifier,
      numberStudents,
      milkCoordinator,
      milkCoordinatorEmail,
      milkCoordinatorPhone,
      coordinatorPosition,
      milkPrice,
      chocMilkPrice,
      milkCost,
      chocMilkCost,
      distributor,
      id,
      schoolCashOnline,
    } = this.state;

    const payload = {
      name: name,
      school_board_id: isEmpty(schoolBoard) ? null : schoolBoard.value,
      address1: street,
      address2: street2,
      city: city,
      region: isEmpty(region) ? null : region.value,
      province: isEmpty(province) ? null : province.value,
      postal_code: postalCode,
      phone: telephone,
      phone_ext: schoolPhoneExtension,
      fax: fax,
      website: website,
      principal_name: principalName,
      principal_email: principalEmail,
      principal_title: principalTitle,
      principal_phone: principalPhone,
      school_type: isEmpty(schoolType) ? null : schoolType.value,
      language: isEmpty(languages) ? null : languages.value,
      preferred_language: isEmpty(prefLanguage) ? null : prefLanguage.value,
      low_grade: isEmpty(lowGrade) ? null : lowGrade.value,
      high_grade: isEmpty(highGrade) ? null : highGrade.value,
      grade_qualifier: gradeQualifier,
      num_students: numberStudents,
      coordinator_name: milkCoordinator,
      coordinator_email: milkCoordinatorEmail,
      coordinator_phone: milkCoordinatorPhone,
      coordinator_role: coordinatorPosition,
      white_milk_price: isEmpty(milkPrice) ? undefined : milkPrice, // New field
      chocolate_milk_price: isEmpty(chocMilkPrice) ? undefined : chocMilkPrice, // New Field
      white_milk_cost: isEmpty(milkCost) ? undefined : milkCost,
      chocolate_milk_cost: isEmpty(chocMilkCost) ? undefined : chocMilkCost,
      dairy_id: distributor.value === "" ? null : distributor.value,
      uses_SCO: schoolCashOnline,
    };

    this.props.editSchool(payload, id, this.props.history);
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };
  saveDistributor = (distributor) => {
    this.setState({ distributor });
  };

  render() {
    const {
      name,
      schoolBoard,
      street,
      street2,
      city,
      region,
      province,
      postalCode,
      telephone,
      schoolPhoneExtension,
      fax,
      website,
      principalName,
      principalEmail,
      principalTitle,
      principalPhone,
      schoolType,
      languages,
      prefLanguage,
      lowGrade,
      highGrade,
      gradeQualifier,
      numberStudents,
      milkCoordinator,
      milkCoordinatorEmail,
      milkCoordinatorPhone,
      coordinatorPosition,
      milkPrice,
      chocMilkPrice,
      milkCost,
      chocMilkCost,
      distributor,
      coordinators,
      showModal,
      schoolCashOnline,
      errors,
    } = this.state;

    const isAdmin = this.props.user === "COORDINATOR" ? true : false;

    if (errors.message) {
      return (
        <div>
          <span className="text-danger">{errors.message}</span>
        </div>
      );
    } else if (!isEmpty(this.props.school)) {
      return (
        <div className="container mt-5">
          <h2>School profile details</h2>
          <p>Here are your school profile details</p>
          <FormSeparator text="School information" />
          {!isAdmin ? (
            <Checkbox
              label="School Cash Online"
              name="schoolCashOnline"
              value={schoolCashOnline}
              onChange={this.handleSCO}
              error={errors.uses_SCO ? errors.usesSCO : errors.message}
            />
          ) : null}
          <DummyField
            value={this.props.school.code}
            label={<Translate id="schoolForm.schoolCode" />}
            orientation="horizontal"
          />
          <TextField
            name="name"
            placeholder="School Name"
            value={name}
            maxLength={100}
            label={<Translate id="schoolForm.schoolName" />}
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.name ? errors.name : errors.message}
          />
          <Select
            name="schoolBoard"
            value={schoolBoard}
            options={this.props.boards}
            label={<Translate id="schoolForm.schoolBoard" />}
            orientation="horizontal"
            onChange={this.handleSelectSchoolBoard}
            error={errors.schoolBoard ? errors.schoolBoard : errors.message}
          />
          <TextField
            name="street"
            placeholder="Street"
            value={street}
            maxLength={50}
            label={<Translate id="schoolForm.street" />}
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.address1 ? errors.address1 : errors.message}
          />
          <TextField
            name="street2"
            placeholder="Street 2"
            value={street2}
            maxLength={50}
            label={<Translate id="schoolForm.street2" />}
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.address2 ? errors.address2 : errors.message}
          />
          <TextField
            name="city"
            placeholder="City"
            value={city}
            maxLength={40}
            label={<Translate id="schoolForm.city" />}
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.city ? errors.city : errors.message}
          />
          <Select
            name="region"
            placeholder="Region"
            value={region}
            label={<Translate id="schoolForm.region" />}
            orientation="horizontal"
            options={regionOptions}
            onChange={this.handleSelectRegion}
            error={errors.region ? errors.region : errors.message}
          />
          <Select
            name="province"
            value={province}
            options={provinceOptions}
            label={<Translate id="schoolForm.province" />}
            orientation="horizontal"
            onChange={this.handleSelectProvince}
            error={errors.province ? errors.province : errors.message}
          />
          <TextField
            name="postalCode"
            label={<Translate id="schoolForm.postalCode" />}
            value={postalCode}
            orientation="horizontal"
            maxLength={6}
            onChange={this.handleChange}
            error={errors.postal_code ? errors.postal_code : errors.message}
          />
          <NumberFormat
            name="telephone"
            label={<Translate id="schoolForm.telephone" />}
            value={telephone}
            customInput={TextField}
            format="###-###-####"
            placeholder="555-555-5555"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.phone ? errors.phone : errors.message}
          />
          <TextField
            name="schoolPhoneExtension"
            placeholder="Phone Extension"
            value={schoolPhoneExtension}
            maxLength={5}
            label={<Translate id="schoolForm.phoneExtension" />}
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.phone_ext ? errors.phone_ext : errors.message}
          />
          <NumberFormat
            name="fax"
            label={<Translate id="schoolForm.fax" />}
            value={fax}
            customInput={TextField}
            format="###-###-####"
            placeholder="555-555-5555"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.fax ? errors.fax : errors.message}
          />
          <TextField
            type="website"
            name="website"
            value={website}
            maxLength={50}
            label={<Translate id="schoolForm.website" />}
            placeholder="Website"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.website ? errors.website : errors.message}
          />
          <Select
            name="schoolType"
            value={schoolType}
            options={schoolTypeOptions}
            label={<Translate id="schoolForm.schoolType" />}
            placeholder="Separate, Public, Private..."
            orientation="horizontal"
            onChange={this.handleSelectSchoolType}
            error={errors.school_type ? errors.school_type : errors.message}
          />
          <Select
            name="languages"
            value={languages}
            options={languagesOptions}
            label={<Translate id="schoolForm.languages" />}
            placeholder="English, French or Both"
            orientation="horizontal"
            onChange={this.handleSelectLanguages}
            error={errors.language ? errors.language : errors.message}
          />
          <Select
            name="prefLanguage"
            value={prefLanguage}
            options={prefLanguageOptions}
            label={<Translate id="schoolForm.prefLanguage" />}
            placeholder="English or French"
            orientation="horizontal"
            onChange={this.handleSelectPrefLanguage}
            error={
              errors.preferred_language
                ? errors.preferred_language
                : errors.message
            }
          />
          <Select
            name="lowGrade"
            value={lowGrade}
            options={gradesOptions}
            label={<Translate id="schoolForm.lowGrade" />}
            orientation="horizontal"
            onChange={this.handleSelectLowGrades}
            error={errors.low_grade ? errors.low_grade : errors.message}
          />
          <Select
            name="highGrade"
            value={highGrade}
            options={gradesOptions}
            label={<Translate id="schoolForm.highGrade" />}
            orientation="horizontal"
            onChange={this.handleSelectHighGrades}
            error={errors.high_grade ? errors.high_grade : errors.message}
          />
          <TextField
            name="gradeQualifier"
            value={gradeQualifier}
            maxLength={50}
            label={<Translate id="schoolForm.gradeQualifier" />}
            placeholder="Grade Qualifier"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.grade_qualifier ? errors.grade_qualifier : errors.message
            }
          />
          <NumberFormat
            name="numberStudents"
            label={<Translate id="schoolForm.numberStudents" />}
            value={numberStudents}
            customInput={TextField}
            thousandSeparator
            placeholder="Number of students"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.num_students ? errors.num_students : errors.message}
          />
          <DistributorSelect
            label="Distributor"
            value={distributor}
            isAdmin={isAdmin}
            save={this.saveDistributor}
          />
          <NumberFormat
            name="milkPrice"
            value={milkPrice}
            customInput={TextField}
            thousandSeparator
            decimalScale={2}
            label={<Translate id="schoolForm.milkPrice" />}
            placeholder="White Milk Price"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.milkPrice ? errors.milkPrice : errors.message}
          />
          <NumberFormat
            name="chocMilkPrice"
            value={chocMilkPrice}
            customInput={TextField}
            thousandSeparator
            decimalScale={2}
            label={<Translate id="schoolForm.chocMilkPrice" />}
            placeholder="Chocolate Milk Price"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.chocMilkPrice ? errors.chocMilkPrice : errors.message}
          />
          <NumberFormat
            name="milkCost"
            value={milkCost}
            customInput={TextField}
            thousandSeparator
            decimalScale={2}
            label={<Translate id="schoolForm.milkCost" />}
            placeholder="White Milk Price"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.milkCost ? errors.milkCost : errors.message}
          />
          <NumberFormat
            name="chocMilkCost"
            value={chocMilkCost}
            customInput={TextField}
            thousandSeparator
            decimalScale={2}
            label={<Translate id="schoolForm.chocMilkCost" />}
            placeholder="Chocolate Milk Price"
            orientation="horizontal"
            onChange={this.handleChange}
            error={errors.chocMilkCost ? errors.chocMilkCost : errors.message}
          />

          <FormSeparator text="Principal information" />

          <TextField
            name="principalName"
            value={principalName}
            maxLength={50}
            label={<Translate id="schoolForm.principalName" />}
            placeholder="Principal Name"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.principal_name ? errors.principal_name : errors.message
            }
          />
          <TextField
            type="email"
            name="principalEmail"
            value={principalEmail}
            maxLength={50}
            label={<Translate id="schoolForm.principalEmail" />}
            placeholder="Principal Email"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.principal_email ? errors.principal_email : errors.message
            }
          />
          <TextField
            name="principalTitle"
            value={principalTitle}
            maxLength={45}
            label={<Translate id="schoolForm.principalTitle" />}
            placeholder="Principal Title"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.principal_title ? errors.principal_title : errors.message
            }
          />
          <NumberFormat
            name="principalPhone"
            label={<Translate id="schoolForm.principalPhone" />}
            value={principalPhone}
            customInput={TextField}
            format="###-###-####"
            placeholder="555-555-5555"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.principal_phone ? errors.principal_phone : errors.message
            }
          />

          <FormSeparator text="Coordinator information" />

          <TextField
            name="milkCoordinator"
            value={milkCoordinator}
            maxLength={50}
            label={<Translate id="schoolForm.milkCoordinator" />}
            placeholder="Milk Coordinator"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.coordinator_name ? errors.coordinator_name : errors.message
            }
          />
          <TextField
            name="milkCoordinatorEmail"
            value={milkCoordinatorEmail}
            maxLength={50}
            label={<Translate id="schoolForm.milkCoordinatorEmail" />}
            placeholder="Milk Coordinator Email Address"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.coordinator_email
                ? errors.coordinator_email
                : errors.message
            }
          />
          <NumberFormat
            name="milkCoordinatorPhone"
            label={<Translate id="schoolForm.milkCoordinatorPhone" />}
            value={milkCoordinatorPhone}
            customInput={TextField}
            format="###-###-####"
            placeholder="555-555-5555"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.coordinator_phone
                ? errors.coordinator_phone
                : errors.message
            }
          />

          <TextField
            name="coordinatorPosition"
            value={coordinatorPosition}
            label={<Translate id="schoolForm.coordinatorPosition" />}
            placeholder="Coordinator Position"
            orientation="horizontal"
            onChange={this.handleChange}
            error={
              errors.coordinatorPosition
                ? errors.coordinatorPosition
                : errors.message
            }
          />
          <div className="d-flex justify-content-end">
            <Button
              text="View all coordinators"
              className="btn btn-link"
              onClick={this.showModal}
            />
          </div>

          <div>
            <Button
              text={<Translate id="button.save" />}
              isLoading={this.props.loading}
              className="btn btn-primary"
              onClick={this.handleSubmit}
            />
          </div>

          <Modal centered size="lg" show={showModal} onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>School Coordinators</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SchoolCoordinators
                user={this.props.user}
                coordinators={coordinators}
              />
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }
}

SchoolProfile.propTypes = {
  user: PropTypes.string.isRequired,
  school: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  boards: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user.role,
  school: state.school.profile,
  loading: state.school.loading,
  boards: state.school.boards,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  editSchool,
  getSchoolBoards,
  getSchoolById,
  clearErrors,
})(withRouter(SchoolProfile));
