import React from 'react';
import PropTypes from 'prop-types';
import OrdersHeader from '../school/OrdersHeader';
import Spinner from '../common/Spinner';
import Order from '../school/Order';
import { isEmpty } from '../../utils';

const ParentOrderList = ({ orders, loading }) => {
  let orderList;
  if (!isEmpty(orders)) {
    orderList = orders.map(order => {
      return <Order key={order.id} order={order} />;
    });
  } else {
    orderList = (
      <p className='m-3'>No orders - click 'Create Order' to get started</p>
    );
  }

  return (
    <div className='order-list-container'>
      <OrdersHeader />
      {loading ? <Spinner /> : <ul className='list-group'>{orderList}</ul>}
    </div>
  );
};

ParentOrderList.propTypes = {
  orders: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired
};

export default ParentOrderList;
