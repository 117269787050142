import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import TextField from '../inputs/TextField';
import NumberFormat from 'react-number-format';
import Button from '../common/Button';
import Select from '../inputs/Select';
import { Modal } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

export class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      email: '',
      loading: false,
      success: '',
      role: { label: '', value: '' },
      entity: null,
      triggered: false,
      is_active: false,
      showModal: false,
      schools: [],
      errors: {}
    };
  }

  componentDidMount() {
    document.title = 'Edit User - Dairy Farmers of Ontario ESMP Portal';
    let url;
    if (this.props.id) {
      url = '/api/users/' + this.props.id;
    } else url = '/api/users/' + this.props.match.params.userId;
    // Get user profile data and set as state
    axios
      .get(url)
      .then(res => {
        this.setState({
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          title: res.data.title,
          phone: res.data.phone,
          email: res.data.email,
          role: { value: res.data.role, label: res.data.role },
          entity: res.data.entity, //Entity the user is associated with eg. School, Distributor
          loading: false,
          is_active: res.data.is_active,
          schools: res.data.schools ? res.data.schools : []
        });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  // Submit user data
  handleSubmitProfileData = e => {
    e.preventDefault();
    this.setState({ loading: true, errors: {} });
    const payload = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      title: this.state.title,
      phone: this.state.phone,
      email: this.state.email,
      role: this.state.role.value
    };

    let url;
    if (this.props.id) {
      url = '/api/users/' + this.props.id;
    } else url = '/api/users/' + this.props.match.params.userId;

    axios
      .put(url, payload)
      .then(res => {
        this.setState({
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          title: res.data.title,
          phone: res.data.phone,
          email: res.data.email,
          role: { value: res.data.role, label: res.data.role },
          success: 'Saved',
          loading: false
        });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelect = role => {
    this.setState({ role });
  };

  triggerPasswordChange = () => {
    const email = {
      email: this.state.email
    };
    this.setState({ triggered: true });
    axios
      .post('/api/users/forgotPassword', email)
      .then(res => {
        this.setState({ loading: false, emailSent: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  handleDeactivate = () => {
    const url = `/api/users/${this.props.match.params.userId}/status`;
    this.setState({ loading: true });
    const payload = {
      is_active: !this.state.is_active
    };
    axios
      .put(url, payload)
      .then(res => {
        this.setState({ is_active: res.data.is_active, loading: false });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      firstName,
      lastName,
      title,
      phone,
      email,
      loading,
      success,
      triggered,
      role,
      entity,
      is_active,
      showModal,
      schools,
      errors
    } = this.state;

    const roleOptions = [
      {
        value: 'COORDINATOR',
        label: 'COORDINATOR'
      },
      { value: 'PARENT', label: 'PARENT' },
      { value: 'DISTRIBUTOR', label: 'DISTRIBUTOR' },
      { value: 'ADMIN', label: 'ADMIN' },
      { value: 'SUPER', label: 'SUPER' }
    ];

    let disableRole = false;
    if (this.props.auth.user.role !== 'SUPER' || !is_active) disableRole = true;

    const displayEntity =
      role !== 'DISTRIBUTOR' ? (
        <div>
          <Translate id='user.entities' />{' '}
          <Button
            text='view'
            className='btn btn-link mb-1 p-0'
            onClick={this.showModal}
          />
        </div>
      ) : (
        `${entity}`
      );

    const asignedTo = entity ? (
      displayEntity
    ) : (
      <Translate id='user.notAssociated' />
    );

    return (
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <h1>
            <Translate id='editUser.title' />
          </h1>

          {this.props.auth.user.role === 'SUPER' ? (
            <Button
              text={
                is_active ? (
                  <Translate id='button.deactivate' />
                ) : (
                  <Translate id='button.activate' />
                )
              }
              className={!is_active ? 'btn btn-primary' : 'btn btn-danger'}
              onClick={this.handleDeactivate}
              isLoading={loading}
            />
          ) : null}
        </div>
        <h3>{role.value === 'PARENT' ? null : asignedTo}</h3>
        {errors.message ? (
          <small className='text-danger'>{errors.message}</small>
        ) : (
          <small className='text-success'>{success}</small>
        )}

        <div className='row'>
          <div className='col-sm-6'>
            <form className='form-container mb-3 mt-3'>
              <TextField
                name='firstName'
                value={firstName}
                label={<Translate id='user.firstName' />}
                type='text'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.first_name}
                disabled={!is_active}
              />
              <TextField
                name='lastName'
                value={lastName}
                label={<Translate id='user.lastName' />}
                type='text'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.last_name}
                disabled={!is_active}
              />
              <TextField
                name='title'
                value={title}
                label={<Translate id='user.title' />}
                type='text'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.title}
                disabled={!is_active}
              />
              <TextField
                name='email'
                value={email}
                label={<Translate id='user.emailAddress' />}
                type='email'
                orientation='horizontal'
                onChange={this.onChange}
                disabled
                error={errors.email}
              />
              <NumberFormat
                name='phone'
                label={<Translate id='user.phone' />}
                value={phone}
                customInput={TextField}
                format='###-###-####'
                placeholder='+1'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.phone}
                disabled={!is_active}
              />
              <Select
                name='role'
                label={<Translate id='user.role' />}
                value={role}
                orientation='horizontal'
                options={roleOptions}
                onChange={this.handleSelect}
                error={errors.role}
                disabled={disableRole}
              />

              <Button
                text={<Translate id='button.update' />}
                className='btn btn-primary mt-3'
                onClick={this.handleSubmitProfileData}
                isLoading={loading}
                disabled={!is_active}
              />
            </form>
          </div>
          <div className='col-sm-6'>
            {triggered ? (
              <Translate id='editUser.resetPassword' />
            ) : (
              <Button
                text={<Translate id='button.resetPassword' />}
                className='btn btn-primary mt-3'
                onClick={this.triggerPasswordChange}
                isLoading={loading}
                disabled={!is_active}
              />
            )}
          </div>
        </div>

        <Modal size='lg' show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Coordinator Schools</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row table-header'>
              <div className='col-2 text-truncate'>
                <Translate id='schoolList.id' />
              </div>
              <div className='col-10 text-truncate'>
                <Translate id='schoolList.schoolName' />
              </div>
            </div>
            <ul className='list-group'>
              {schools.map(sch => {
                return (
                  <li key={sch.id} className='list-group-item'>
                    <div className='row'>
                      <div className='col-2'>{`${sch.id}`}</div>
                      <div className='col-10 text-truncate'>{`${sch.name}`}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

EditUser.propTypes = {
  id: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(EditUser);
