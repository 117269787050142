import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import Distributor from './Distributor';
import DistributorHeader from './DistributorHeader';

const DistributorList = ({ distributors, loading, page }) => {
  let distributorList;
  if (distributors) {
    distributorList = distributors.map(distributor => {
      return (
        <Distributor
          key={distributor.id}
          distributor={distributor}
          page={page}
        />
      );
    });
  } else {
    distributorList = <p className='m-3'>Looks like there's nothing here.</p>;
  }

  return (
    <div className='order-list-container'>
      <DistributorHeader />
      {loading ? (
        <Spinner />
      ) : (
        <ul className='list-group'>{distributorList}</ul>
      )}
    </div>
  );
};

DistributorList.propTypes = {
  distributors: PropTypes.array,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DistributorList;
