import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const Button = ({ text, isLoading, className, disabled, onClick }) => {
  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <button onClick={onClick} disabled={disabled} className={className}>
        {text}
      </button>
    );
  }
};

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

Button.defaultProps = {
  isLoading: false,
  disabled: false
};

export default Button;
