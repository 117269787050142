import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Button from "../common/Button";
import { Translate } from "react-localize-redux";

export class SchoolSearchForm extends Component {
  render() {
    return (
      <form className="mb-3 mt-3">
        <div className="form-row">
          <div className="col-lg-3 col-12 mb-1">
            <input
              type="text"
              name="schoolName"
              className="form-control form-control-sm"
              placeholder="School Name"
              onChange={this.props.handleChange}
            />
          </div>
          <div className="col-lg-2 col-12 mb-1">
            <input
              type="text"
              name="postalCode"
              className="form-control form-control-sm"
              placeholder="Postal Code"
              onChange={this.props.handleChange}
            />
          </div>
          <div className="col-lg-2 col-12 mb-1">
            <NumberFormat
              name="phoneNumber"
              className="form-control form-control-sm"
              format="###-###-####"
              placeholder="Phone Number"
              orientation="horizontal"
              onChange={this.props.handleChange}
            />
          </div>
          <div className="col-lg-2 col-12 mb-1">
            <select
              name="display"
              className="form-control form-control-sm"
              value={this.props.display}
              onChange={this.props.handleChange}
            >
              <option value="">Registered & Unregistered</option>
              <option value={1}>Registered</option>
              <option value={0}>Not Registered</option>
            </select>
          </div>
          <div className="col-lg-2 col-12 mb-1">
            <select
              name="schoolBoard"
              className="form-control form-control-sm"
              value={this.props.schoolBoard}
              onChange={this.props.handleChange}
            >
              <option value="">Select board</option>
              {this.props.boards.map((el) => {
                return <option value={el.value}>{el.label}</option>;
              })}
            </select>
          </div>
          <div className="col-lg-1 col-12 text-right mb-1">
            <Button
              text={<Translate id="button.search" />}
              isLoading={this.props.loading}
              className="btn btn-sm btn-primary"
              onClick={this.props.handleSearch}
            />
          </div>
        </div>
      </form>
    );
  }
}

SchoolSearchForm.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  display: PropTypes.string.isRequired,
  schoolBoard: PropTypes.number.isRequired,
  boards: PropTypes.array.isRequired,
};

export default SchoolSearchForm;
