import React from 'react';
import PropTypes from 'prop-types';
import PresentationScheduleItem from './PresentationScheduleItem';

const PresentationSchedule = props => {
  const schedule = props.presentationSchedule.map((item, index) => {
    return (
      <PresentationScheduleItem
        key={item.presentationTime}
        index={index}
        item={item}
        removeScheduleItem={props.removeScheduleItem}
        saveScheduleItem={props.saveScheduleItem}
      />
    );
  });
  return schedule;
};

PresentationSchedule.propTypes = {
  presentationSchedule: PropTypes.array.isRequired,
  saveScheduleItem: PropTypes.func.isRequired,
  removeScheduleItem: PropTypes.func.isRequired
};

export default PresentationSchedule;
