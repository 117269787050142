import React from 'react';
import PropTypes from 'prop-types';
import DummyField from '../inputs/DummyField';
import { Translate } from 'react-localize-redux';
import moment from 'moment';

const ConfirmRegistration = props => {
  return (
    <div className='p-3'>
      <DummyField
        value={moment().format('MM-DD-YYYY')}
        label={<Translate id='schoolForm.registrationDate' />}
        orientation='vertical'
      />
      <DummyField
        value={
          props.registrationMethod ? props.registrationMethod.label : 'Online'
        }
        label={<Translate id='schoolForm.registrationMethod' />}
        orientation='vertical'
      />
      <DummyField
        value={moment(props.membershipStartDate).format('MM-DD-YYYY')}
        label={<Translate id='schoolForm.membershipStartDate' />}
        orientation='vertical'
      />
      <DummyField
        value={props.coordinatorSignature}
        label={<Translate id='schoolForm.coordinatorSignature' />}
        orientation='vertical'
      />
      <DummyField
        value={moment().format('MM-DD-YYYY')}
        label={<Translate id='schoolForm.signatureDate' />}
        orientation='vertical'
      />
    </div>
  );
};

ConfirmRegistration.propTypes = {
  registrationDate: PropTypes.object,
  registrationMethod: PropTypes.object,
  membershipStartDate: PropTypes.object,
  regDeclinedDate: PropTypes.object,
  regDeclineReason: PropTypes.object,
  coordinatorSignature: PropTypes.string,
  signatureDate: PropTypes.object
};

export default ConfirmRegistration;
