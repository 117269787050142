import React from 'react';
import { Translate } from 'react-localize-redux';

export default function UserListHeader() {
  return (
    <div className='row table-header'>
      <div className='col-sm-3 col-10 text-truncate'>
        <Translate id='user.emailAddress' />
      </div>
      <div className='col-sm-3 text-truncate d-none d-md-block'>
        <Translate id='user.firstName' />
      </div>
      <div className='col-sm-3 text-truncate d-none d-md-block'>
        <Translate id='user.lastName' />
      </div>
      <div className='col-sm-2 text-truncate d-none d-md-block'>
        <Translate id='user.role' />
      </div>
      <div className='col-sm-1 col-2'>
        {/* <Translate id='user.actions' /> */}
      </div>
    </div>
  );
}
