import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import TextField from "../inputs/TextField";
import Button from "../common/Button";

import { registerNewAdmin } from "../../redux/actions/adminActions";
import { Translate } from "react-localize-redux";

export class AddAdmin extends Component {
  constructor(props) {
    super(props);

    if (this.props.userRole !== "SUPER") {
      window.location.replace("/admin/dashboard");
    }

    this.state = {
      firstName: "",
      lastName: "",
      title: "",
      phone: "",
      email: "",
      password: "",
      password2: "",
      superAdmin: false,
      errors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const payload = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      title: this.state.title,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      role: this.state.superAdmin ? "SUPER" : "ADMIN"
    };

    this.props.registerNewAdmin(payload, this.props.history);
  };

  onChange = e => {
    this.setState({
      // Handle both regular inputs and checkbox
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value
    });
  };

  render() {
    const {
      firstName,
      lastName,
      title,
      phone,
      email,
      password,
      password2,
      superAdmin,
      errors
    } = this.state;

    return (
      <div className="form-container">
        <h1>
          <Translate id="addAdmin.title" />
        </h1>
        <p>
          <Translate id="addAdmin.hint" />
        </p>
        <form className="mt-5 mb-5">
          <TextField
            name="firstName"
            value={firstName}
            label={<Translate id="user.firstName" />}
            type="text"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.first_name}
          />
          <TextField
            name="lastName"
            value={lastName}
            label={<Translate id="user.lastName" />}
            type="text"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.last_name}
          />
          <TextField
            name="title"
            value={title}
            label={<Translate id="user.title" />}
            type="text"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.title}
          />
          <TextField
            name="email"
            value={email}
            label={<Translate id="user.emailAddress" />}
            type="email"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.email}
          />
          <NumberFormat
            name="phone"
            label={<Translate id="user.phone" />}
            value={phone}
            customInput={TextField}
            format="+1 ### ### ####"
            placeholder="+1"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.phone}
          />
          <div className="mt-3 mb-3">
            <small>
              <Translate id="user.passwordHint" />
            </small>
          </div>
          <TextField
            name="password"
            value={password}
            label={<Translate id="user.password" />}
            type="password"
            autoComplete="new-password"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.password}
          />
          <TextField
            name="password2"
            value={password2}
            label={<Translate id="user.confirmPassword" />}
            type="password"
            autoComplete="new-password"
            orientation="horizontal"
            onChange={this.onChange}
            error={errors.password2}
          />

          <div className="form-group form-check">
            <input
              name="superAdmin"
              id="superAdmin"
              type="checkbox"
              className="form-check-input"
              value={superAdmin}
              onChange={this.onChange}
            />
            <label className="form-check-label" htmlFor="superAdmin">
              Super Admin
            </label>
            <div>
              <small>
                <Translate id="addAdmin.superAdminHint" />
              </small>
            </div>
          </div>

          <Button
            text={<Translate id="button.register" />}
            onClick={this.handleSubmit}
            className="btn btn-primary mt-3"
            isLoading={this.props.loading}
            disabled={this.props.userRole !== "SUPER"}
          />
        </form>
      </div>
    );
  }
}

AddAdmin.propTypes = {
  userRole: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  userRole: state.auth.user.role,
  loading: state.admin.loading,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerNewAdmin }
)(AddAdmin);
