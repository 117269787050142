import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from '../common/Spinner';

export class VerifyAccount extends Component {
  constructor() {
    super();
    this.state = {
      isVerified: false,
      error: null,
      linkResent: false
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    } else {
      const token = this.props.match.params.token;
      if (token) {
        const url = '/api/users/verify/' + token;
        axios
          .get(url)
          .then(res => {
            this.setState({ isVerified: true });
          })
          .catch(err => {
            this.setState({
              error: err.response.data
            });
          });
      }
    }
  }

  // Include user email in the token instead of asking the user to type in an email address?
  resendToken = () => {
    const email = {
      email: new URLSearchParams(this.props.location.search).get('email')
    };
    const initialState = {
      isVerified: false,
      error: null
    };
    axios
      .post('/api/users/resend-token', email)
      .then(res => {
        this.setState(initialState);
        this.setState({ linkResent: true });
        setTimeout(() => {
          this.setState({ linkResent: false });
        }, 5000);
      })
      .catch(err => {
        this.setState({
          error: err.response.data
        });
      });
  };

  render() {
    const { isVerified, error, linkResent } = this.state;

    if (this.props.match.params.token) {
      return (
        <div>
          <h1>Account verification</h1>
          {isVerified ? (
            <div>
              <p>You're all set!</p>
              <Link to='/login' className='btn-link'>
                Proceed to login
              </Link>
            </div>
          ) : (
            <div>
              {error ? (
                <p>
                  There has been an error, please try again later or{' '}
                  <span onClick={this.resendToken} className='btn-link'>
                    resend the email
                  </span>
                </p>
              ) : (
                <div>
                  <p>Hold on while we verify your account...</p>
                  <Spinner />
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h1>Account verification</h1>
          <p>Thank you for registering!</p>
          <p>
            You should see a link in your inbox shortly asking you to verify
            your account.
          </p>
          <p>
            If you didn't get an email from us, please use the link below to
            resend the verification email.
          </p>

          {linkResent ? (
            <Spinner />
          ) : (
            <span onClick={this.resendToken} className='btn-link'>
              Resend the verification link
            </span>
          )}
        </div>
      );
    }
  }
}

VerifyAccount.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(VerifyAccount);
