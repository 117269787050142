import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../redux/actions/authActions';
import NumberFormat from 'react-number-format';
import TextField from '../inputs/TextField';
import Button from '../common/Button';
import FormSeparator from '../inputs/FormSeparator';
import { Translate } from 'react-localize-redux';

export class CoordinatorRegister extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      email: '',
      password: '',
      password2: '',
      hasAgreed: false,
      errors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      // Check for errors
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = e => {
    this.setState({
      // Handle both regular inputs and checkbox
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      title,
      phone,
      email,
      password,
      password2
    } = this.state;

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      title: title,
      phone: phone,
      password: password,
      password2: password2,
      role: this.props.role
    };

    this.props.registerUser(userData, this.props.history);
  };

  render() {
    const {
      firstName,
      lastName,
      title,
      phone,
      email,
      password,
      password2,
      hasAgreed,
      errors
    } = this.state;

    return (
      <form className='mb-5'>
        <FormSeparator text='Registration details' />
        <TextField
          name='firstName'
          value={firstName}
          label={<Translate id='user.firstName' />}
          type='text'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.first_name}
        />
        <TextField
          name='lastName'
          value={lastName}
          label={<Translate id='user.lastName' />}
          type='text'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.last_name}
        />
        <TextField
          name='title'
          value={title}
          label={<Translate id='user.title' />}
          type='text'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.title}
        />
        <TextField
          name='email'
          value={email}
          label={<Translate id='user.emailAddress' />}
          type='email'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.email}
        />
        <NumberFormat
          name='phone'
          label={<Translate id='user.phone' />}
          value={phone}
          customInput={TextField}
          format='###-###-####'
          placeholder='555-555-5555'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.phone}
        />
        <div className='mt-3 mb-3'>
          <small>{<Translate id='user.passwordHint' />}</small>
        </div>
        <TextField
          name='password'
          value={password}
          label={<Translate id='user.password' />}
          type='password'
          autoComplete='new-password'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.password}
        />
        <TextField
          name='password2'
          value={password2}
          label={<Translate id='user.confirmPassword' />}
          type='password'
          autoComplete='new-password'
          orientation='horizontal'
          onChange={this.onChange}
          error={errors.password2}
        />

        <div className='form-group form-check'>
          <input
            name='hasAgreed'
            id='hasAgreed'
            type='checkbox'
            className='form-check-input'
            value={hasAgreed}
            onChange={this.onChange}
          />
          <label className='form-check-label' htmlFor='hasAgreed'>
            {<Translate id='register.agreed' />}
          </label>
        </div>

        <Button
          text={<Translate id='button.register' />}
          onClick={this.handleSubmit}
          className='btn btn-primary mt-3'
          disabled={!hasAgreed}
          isLoading={this.props.loading}
        />
      </form>
    );
  }
}

CoordinatorRegister.propTypes = {
  registerUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  errors: state.errors
});

const mapDispatchToProps = {
  registerUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoordinatorRegister);
