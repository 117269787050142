import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { isEmpty } from '../../utils';
import moment from 'moment';
import TextField from '../inputs/TextField';
import NumberFormat from 'react-number-format';
import DatePicker from '../inputs/DatePicker';
import Select from '../inputs/Select';
import FormSeparator from '../inputs/FormSeparator';
import PresentationSchedule from './PresentationSchedule';
import PresentationScheduleHeader from './PresentationScheduleHeader';
import Button from '../common/Button';
import AddPresentationItem from './AddPresentationItem';
import { Modal } from 'react-bootstrap';
class DairyEducatorForm extends Component {
  constructor() {
    super();
    this.state = {
      school: null,
      schoolName: '',
      schoolAddress: '',
      schoolPostalCode: '',
      region: '',
      contactPerson: '',
      telephone: '',
      email: '',
      schoolStartTime: null, // time of day(hours)
      morningRecess: null, // time of day(hours)
      afternonRecess: null, // time of day(hours)
      lunchTime: null, // time of day(hours)
      dismissalTime: null, // time of day(hours)
      date1: null,
      date2: null,
      date3: null,
      presentationLocation: {
        value: '',
        label: ''
      }, // Can be libary, classrooms, other(specify)
      presentationSchedule: [],
      showModal: false,
      success: false,
      schools: [],
      errors: {}
    };
  }

  componentDidMount() {
    // Get school data
    axios
      .get('/api/schools/mySchools')
      .then(res => {
        const schools = res.data.results.map(s => {
          return {
            name: s.name,
            address: s.address1,
            postalCode: s.postal_code,
            label: s.name,
            value: s.name
          };
        });
        this.setState({
          schools: schools
        });
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  }

  handleSubmit = () => {
    const {
      schoolName,
      schoolAddress,
      schoolPostalCode,
      region,
      contactPerson,
      telephone,
      email,
      schoolStartTime,
      morningRecess,
      afternonRecess,
      lunchTime,
      dismissalTime,
      date1,
      date2,
      date3,
      presentationLocation,
      presentationSchedule
    } = this.state;

    const payload = {
      name: schoolName,
      address: schoolAddress,
      postalCode: schoolPostalCode,
      contactPerson: contactPerson,
      region: region,
      phone: telephone,
      email: email,
      schoolStartTime: moment(schoolStartTime).format('HH:mm'),
      morningRecess: moment(morningRecess).format('HH:mm'),
      lunchTime: moment(lunchTime).format('HH:mm'),
      afternoonRecess: moment(afternonRecess).format('HH:mm'),
      dismissalTime: moment(dismissalTime).format('HH:mm'),
      proposedDates: [
        moment(date1).format('MMM DD YYYY'),
        moment(date2).format('MMM DD YYYY'),
        moment(date3).format('MMM DD YYYY')
      ],
      presentationLocation: presentationLocation,
      presentationSchedule: presentationSchedule
    };
    const url = '/api/schools/requestEducator';
    axios
      .post(url, payload)
      .then(res => {
        this.setState({ success: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleschoolStartTime = time => {
    this.setState({ schoolStartTime: time });
  };
  handlemorningRecess = time => {
    this.setState({ morningRecess: time });
  };
  handleafternonRecess = time => {
    this.setState({ afternonRecess: time });
  };
  handlelunchTime = time => {
    this.setState({ lunchTime: time });
  };
  handledismissalTime = time => {
    this.setState({ dismissalTime: time });
  };

  handleDate1Change = date => {
    this.setState({ date1: date });
  };
  handleDate2Change = date => {
    this.setState({ date2: date });
  };
  handleDate3Change = date => {
    this.setState({ date3: date });
  };
  handleSelectLocation = presentationLocation => {
    this.setState({ presentationLocation });
  };
  handleSelectSchool = school => {
    this.setState({
      school,
      schoolName: school.name,
      schoolAddress: school.address,
      schoolPostalCode: school.postalCode
    });
  };
  handleChangeOther = e => {
    this.setState({
      presentationLocation: { label: 'Other', value: e.target.value }
    });
  };

  addScheduleItem = scheduleItem => {
    const schedule = this.state.presentationSchedule;
    schedule.push(scheduleItem);
    this.setState({ presentationSchedule: schedule });
    this.hideModal();
  };
  removeScheduleItem = index => {
    const schedule = this.state.presentationSchedule;
    schedule.splice(index, 1);
    this.setState({ presentationSchedule: schedule });
  };
  saveScheduleItem = (scheduleItem, index) => {
    const schedule = this.state.presentationSchedule;
    schedule.splice(index, 1, scheduleItem);
    this.setState({ presentationSchedule: schedule });
  };

  render() {
    const {
      school,
      schools,
      schoolName,
      schoolAddress,
      schoolPostalCode,
      contactPerson,
      telephone,
      email,
      schoolStartTime,
      morningRecess,
      afternonRecess,
      lunchTime,
      dismissalTime,
      date1,
      date2,
      date3,
      presentationLocation,
      presentationSchedule,
      errors
    } = this.state;

    const options = [
      { value: 'Library', label: 'Library' },
      { value: 'Classrooms', label: 'Classrooms' },
      { value: '', label: 'Other' }
    ];

    let submitDisabled = true;
    if (
      !isEmpty(schoolName) &&
      !isEmpty(schoolAddress) &&
      !isEmpty(schoolPostalCode) &&
      !isEmpty(contactPerson) &&
      !isEmpty(telephone) &&
      !isEmpty(email) &&
      schoolStartTime !== null &&
      morningRecess !== null &&
      afternonRecess !== null &&
      lunchTime !== null &&
      dismissalTime !== null &&
      date1 !== null &&
      date2 !== null &&
      date3 !== null &&
      !isEmpty(presentationLocation) &&
      presentationSchedule.length >= 1 &&
      isEmpty(errors)
    ) {
      submitDisabled = false;
    }
    return (
      <div>
        <h1>
          <Translate id='dairyEducatorForm.title' />
        </h1>

        <p className='mt-3 mb-3'>
          The dairy industry serves as an interesting and unique learning
          vehicle for students to expand their knowledge of the world around
          them, while developing skills that will help them become well-informed
          decision makers and life-long learners. Based on the latest Ontario
          Curriculum, the program is 45 minutes in length, features interactive
          activities, and is presented by an Ontario Dairy Educator.
        </p>
        <p>
          Access to a SMART Board is strongly recommended. Due to the level of
          interactive activities within the presentation, combining classes is
          not permitted. A single setup location is best for the educator.
          Otherwise, please allow 15 minutes between presentations if the
          educator needs to change rooms.
        </p>
        <p className='text-danger'>{errors.message}</p>

        <div className='row'>
          <div className='col'>
            <FormSeparator text='School information' />
            <Select
              name='school'
              label={<Translate id='dairyEducatorForm.schoolName' />}
              orientation='horizontal'
              value={school}
              onChange={this.handleSelectSchool}
              options={schools}
            />
            {/* <TextField
              name='schoolName'
              placeholder='School Name'
              value={schoolName}
              maxLength={100}
              label={<Translate id='dairyEducatorForm.schoolName' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.school_name ? errors.school_name : errors.message}
              disabled={true}
            /> */}
            <TextField
              name='schoolAddress'
              placeholder='School Address'
              value={schoolAddress}
              maxLength={100}
              label={<Translate id='dairyEducatorForm.schoolAddress' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.school_address ? errors.school_address : errors.message
              }
              disabled={true}
            />
            <TextField
              name='schoolPostalCode'
              label={<Translate id='dairyEducatorForm.schoolPostalCode' />}
              placeholder='Postal Code'
              value={schoolPostalCode}
              orientation='horizontal'
              maxLength={6}
              onChange={this.handleChange}
              error={errors.postal_code ? errors.postal_code : errors.message}
              disabled={true}
            />

            <DatePicker
              name='schoolStartTime'
              value={schoolStartTime}
              label={<Translate id='dairyEducatorForm.schoolStartTime' />}
              orientation='horizontal'
              required
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={15}
              dateFormat='h:mm aa'
              popperClassName='popper-custom'
              onChange={this.handleschoolStartTime}
              error={
                errors.schoolStartTime ? errors.schoolStartTime : errors.message
              }
            />
            <DatePicker
              name='morningRecess'
              value={morningRecess}
              label={<Translate id='dairyEducatorForm.morningRecess' />}
              orientation='horizontal'
              required
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={15}
              dateFormat='h:mm aa'
              onChange={this.handlemorningRecess}
              error={
                errors.morningRecess ? errors.morningRecess : errors.message
              }
            />
            <DatePicker
              name='afternonRecess'
              value={afternonRecess}
              label={<Translate id='dairyEducatorForm.afternonRecess' />}
              orientation='horizontal'
              required
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={15}
              dateFormat='h:mm aa'
              onChange={this.handleafternonRecess}
              error={
                errors.afternonRecess ? errors.afternonRecess : errors.message
              }
            />
            <DatePicker
              name='lunchTime'
              value={lunchTime}
              label={<Translate id='dairyEducatorForm.lunchTime' />}
              orientation='horizontal'
              required
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={15}
              dateFormat='h:mm aa'
              onChange={this.handlelunchTime}
              error={errors.lunchTime ? errors.lunchTime : errors.message}
            />
            <DatePicker
              name='dismissalTime'
              value={dismissalTime}
              label={<Translate id='dairyEducatorForm.dismissalTime' />}
              orientation='horizontal'
              required
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={15}
              dateFormat='h:mm aa'
              onChange={this.handledismissalTime}
              error={
                errors.dismissalTime ? errors.dismissalTime : errors.message
              }
            />
          </div>
          <div className='col-lg'>
            <FormSeparator text='Contact information' />
            <TextField
              name='contactPerson'
              placeholder='Contact Person'
              value={contactPerson}
              maxLength={100}
              label={<Translate id='dairyEducatorForm.contactPerson' />}
              orientation='horizontal'
              onChange={this.handleChange}
              required
              error={
                errors.contact_person ? errors.contact_person : errors.message
              }
            />
            <NumberFormat
              name='telephone'
              label={<Translate id='schoolForm.telephone' />}
              value={telephone}
              customInput={TextField}
              format='###-###-####'
              placeholder='555-555-5555'
              orientation='horizontal'
              onChange={this.handleChange}
              required
              error={errors.phone ? errors.phone : errors.message}
            />
            <TextField
              type='email'
              name='email'
              value={email}
              maxLength={50}
              label={<Translate id='schoolForm.email' />}
              placeholder='Email'
              orientation='horizontal'
              onChange={this.handleChange}
              required
              error={errors.email ? errors.email : errors.message}
            />
            <FormSeparator text='Presentation details' />
            <DatePicker
              name='date1'
              value={date1}
              label='Preferred date 1'
              orientation='horizontal'
              required
              onChange={this.handleDate1Change}
            />
            <DatePicker
              name='date2'
              value={date2}
              label='Preferred date 2'
              orientation='horizontal'
              required
              onChange={this.handleDate2Change}
            />
            <DatePicker
              name='date3'
              value={date3}
              label='Preferred date 3'
              orientation='horizontal'
              required
              onChange={this.handleDate3Change}
            />
            <Select
              name='presentationLocation'
              value={presentationLocation}
              options={options}
              label={<Translate id='dairyEducatorForm.presentationLocation' />}
              orientation='horizontal'
              onChange={this.handleSelectLocation}
              isClearable={false}
              required
              error={
                errors.presentationLocation
                  ? errors.presentationLocation
                  : errors.message
              }
            />
            {presentationLocation.label === 'Other' ? (
              <TextField
                name='specifyLocation'
                value={presentationLocation.value}
                maxLength={50}
                label={<Translate id='dairyEducatorForm.specifyLocation' />}
                placeholder='Please specify'
                orientation='horizontal'
                onChange={this.handleChangeOther}
                required
                error={errors.email ? errors.email : errors.message}
              />
            ) : null}
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <FormSeparator text='Presentation schedule' />
            <PresentationScheduleHeader />
            <PresentationSchedule
              presentationSchedule={presentationSchedule}
              saveScheduleItem={this.saveScheduleItem}
              removeScheduleItem={this.removeScheduleItem}
            />
            <Button
              text={<Translate id='button.add' />}
              className='btn btn-primary'
              onClick={this.showModal}
            />
          </div>
        </div>

        <div className='text-right'>
          <Button
            text={<Translate id='button.submit' />}
            onClick={this.handleSubmit}
            className='btn btn-primary'
            disabled={submitDisabled}
          />
        </div>

        <Modal size='lg' show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add presentation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPresentationItem
              addScheduleItem={this.addScheduleItem}
              hideModal={this.hideModal}
            />
          </Modal.Body>
        </Modal>

        <Modal show={this.state.success}>
          <Modal.Header>
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your request was successfuly sent, a dairy representative will
            contact you using the contact information you provided.
          </Modal.Body>
          <Modal.Footer>
            <Link to='/dashboard' className='btn btn-primary'>
              Back to dashboard
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DairyEducatorForm;
