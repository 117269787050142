import React from 'react';
import CheckSchoolRegistration from '../school/CheckSchoolRegistration';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

const Sidebar = () => {
  return (
    <div>
      <h3>Need help?</h3>
      <p>
        Call <a href='tel:+1-888-730-6455'>1-888-730-6455</a> <br /> or email
        <a href='mailto:schoolmilk@milk.org'> schoolmilk@milk.org</a>
      </p>

      <div className='mt-4 mb-4'>
        <CheckSchoolRegistration />
      </div>

      <p>
        Already have an account?{' '}
        <Link to='/login' className='btn btn-sm btn-primary ml-2'>
          <Translate id='button.login' />
        </Link>
      </p>
    </div>
  );
};

export default Sidebar;
