import React, { Component } from "react";
import axios from "axios";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import { LocalizeProvider } from "react-localize-redux";

import "./App.css"; //Styles

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import ScoMigration from "./components/layout/ScoMigration";

// Auth components
import PrivateRoute from "./components/auth/PrivateRoute";
import AdminPrivateRoute from "./components/auth/AdminPrivateRoute";
import ParentPrivateRoute from "./components/auth/ParentPrivateRoute";
import DistPrivateRoute from "./components/auth/DistPrivateRoute";
import Register from "./components/auth/Register";
import VerifyAccount from "./components/auth/VerifyAccount";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";

// Coordinator Components
import Dashboard from "./components/dashboard/Dashboard";
import ProfileSettings from "./components/dashboard/ProfileSettings";
import ClaimSchool from "./components/school/ClaimSchool";
import DairyEducatorForm from "./components/DairyEducator/DairyEducatorForm";
import School from "./components/school/School";
import MySchools from "./components/coordinator/MySchools";

// Admin Components
import AdminDashboard from "./components/admin/AdminDashboard";
import ManageUsers from "./components/admin/ManageUsers";
import AddAdmin from "./components/admin/AddAdmin";
import SchoolSearch from "./components/admin/SchoolSearch";
import EditUser from "./components/admin/EditUser";
import AddSchool from "./components/school/AddSchool";
import DistributorSearch from "./components/admin/DistributorSearch";
import EditDistributorContent from "./components/admin/EditDistributorContent";
import AddDistributor from "./components/admin/AddDistributor";
import SchoolNotes from "./components/notes/SchoolNotes";

// Parent Components
import ParentDashboard from "./components/parent/ParentDashboard";

// Distributor Components
import DistDashboard from "./components/distributor/DistDashboard";
import DistClaimProfile from "./components/distributor/DistClaimProfile";

// 404
import NotFound from "./components/common/NotFound";
import Breadcrumbs from "./components/layout/Breadcrumbs";

// Save user data to local storage
var retrieveUserData = localStorage.getItem("userData");
var retrievedUserData = JSON.parse(retrieveUserData);
if (localStorage.userData) {
  store.dispatch(setCurrentUser(retrievedUserData));
}

// Intercept 401(Unathorized) and force-logout
axios.interceptors.response.use(null, function (err) {
  if (err.response.status === 401) {
    store.dispatch(logoutUser());
  }
  return Promise.reject(err);
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <LocalizeProvider>
          <Router>
            <div className="App">
              <Navbar />

              <div className="container main-content p-4 ">
                <Breadcrumbs />
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route
                    exact
                    path="/verify-account/:token?"
                    component={VerifyAccount}
                  />
                  <Route
                    exact
                    path="/forgot-password/:token?"
                    component={ForgotPassword}
                  />

                  <Route exact path="/sco" component={ScoMigration} />

                  {/* COORDINATOR ROUTES */}

                  <PrivateRoute
                    exact
                    path="/dashboard/claim-school/"
                    component={ClaimSchool}
                  />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/dashboard/profile"
                    component={ProfileSettings}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/my-schools/:page?"
                    component={MySchools}
                  />
                  <PrivateRoute
                    exact // Route will only render if type is either register | edit
                    path="/dashboard/school/:school/:type(registration|profile|orders)?/:page?"
                    component={School}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/request-dairy-educator"
                    component={DairyEducatorForm}
                  />

                  {/* PARENT ROUTES */}

                  <ParentPrivateRoute
                    exact
                    path="/parent/dashboard/"
                    component={ParentDashboard}
                  />
                  <ParentPrivateRoute
                    exact
                    path="/parent/dashboard/profile"
                    component={ProfileSettings}
                  />

                  {/* DISTRIBUTOR ROUTES */}

                  <DistPrivateRoute
                    exact
                    path="/distributor/dashboard/:tab(orders|profile|home)?"
                    component={DistDashboard}
                  />
                  <DistPrivateRoute
                    exact
                    path="/distributor/claim"
                    component={DistClaimProfile}
                  />
                  <DistPrivateRoute
                    exact
                    path="/distributor/dashboard/settings"
                    component={ProfileSettings}
                  />

                  {/* ADMIN ROUTES */}

                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/"
                    component={AdminDashboard}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/profile"
                    component={ProfileSettings}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/manage-users/:page?"
                    component={ManageUsers}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/manage-users/edit/:userId"
                    component={EditUser}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/manage-users/add/new-admin"
                    component={AddAdmin}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/schools/:page?"
                    component={SchoolSearch}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/school/:school/:type(registration|profile|orders|notes)?"
                    component={School}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/addschool/"
                    component={AddSchool}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/distributors/:distributor?"
                    component={DistributorSearch}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/distributor-content"
                    component={EditDistributorContent}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/add-distributor"
                    component={AddDistributor}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/admin/dashboard/school-notes"
                    component={SchoolNotes}
                  />
                  <Route component={NotFound} />
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </LocalizeProvider>
      </Provider>
    );
  }
}

export default App;
