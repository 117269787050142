import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import TextField from '../inputs/TextField';
import Button from '../common/Button';
import Select from '../inputs/Select';
import NumberFormat from 'react-number-format';
import FormSeparator from '../inputs/FormSeparator';
import { provinceOptions } from '../school/FormOptions';
import axios from 'axios';
import { isEmpty } from '../../utils';

export class AddDistributor extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      name: '',
      phone: '',
      phone_ext: '',
      email: '',
      address1: '',
      address2: '',
      fax: '',
      city: '',
      province: {},
      postal_code: '',
      website: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      contact_phone_ext: '',
      code: '',
      user_id: '',
      errors: {}
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSelectProvince = province => {
    this.setState({ province });
  };

  handleSubmit = () => {
    const payload = {
      name: this.state.name,
      phone: this.state.phone,
      phone_ext: this.state.phone_ext,
      email: this.state.email,
      address1: this.state.address1,
      address2: this.state.address2,
      fax: this.state.fax,
      city: this.state.city,
      province: !isEmpty(this.state.province)
        ? this.state.province.value
        : null,
      postal_code: this.state.postal_code,
      website: this.state.website,
      contact_name: this.state.contact_name,
      contact_email: this.state.contact_email,
      contact_phone: this.state.contact_phone,
      contact_phone_ext: this.state.contact_phone_ext
    };

    const url = '/api/distributors/';
    axios
      .post(url, payload)
      .then(res => {
        this.props.history.push('/admin/dashboard');
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  };

  render() {
    const {
      name,
      phone,
      phone_ext,
      email,
      address1,
      address2,
      fax,
      city,
      province,
      postal_code,
      website,
      contact_name,
      contact_email,
      contact_phone,
      contact_phone_ext,
      errors
    } = this.state;

    return (
      <div className='container mt-5'>
        <h2>Add distributor</h2>
        <p>Use this form to create a new distributor profile</p>
        <p className='text-danger'>{errors.message}</p>
        <FormSeparator text='Distributor information' />
        <TextField
          name='name'
          placeholder='Distributor name'
          value={name}
          maxLength={100}
          label={<Translate id='dist.name' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.name}
        />
        <NumberFormat
          name='phone'
          label={<Translate id='dist.phone' />}
          value={phone}
          customInput={TextField}
          format='###-###-####'
          placeholder='555-555-5555'
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.phone}
        />
        <TextField
          name='phone_ext'
          placeholder='Phone Extension'
          value={phone_ext}
          maxLength={5}
          label={<Translate id='dist.phoneExt' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.phone_ext}
        />
        <TextField
          name='email'
          placeholder='Distributor email'
          value={email}
          label={<Translate id='dist.email' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.email}
        />
        <TextField
          name='address1'
          value={address1}
          label={<Translate id='dist.address1' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.address1}
        />
        <TextField
          name='address2'
          value={address2}
          label={<Translate id='dist.address2' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.address2}
        />
        <NumberFormat
          name='fax'
          label={<Translate id='dist.fax' />}
          value={fax}
          customInput={TextField}
          format='###-###-####'
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.fax}
        />
        <TextField
          name='city'
          value={city}
          label={<Translate id='dist.city' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.city}
        />
        <Select
          name='province'
          value={province}
          options={provinceOptions}
          label={<Translate id='dist.province' />}
          orientation='horizontal'
          onChange={this.handleSelectProvince}
          error={errors.province}
        />
        <TextField
          name='postal_code'
          label={<Translate id='dist.postal_code' />}
          value={postal_code}
          orientation='horizontal'
          maxLength={6}
          onChange={this.handleChange}
          error={errors.postal_code}
        />
        <TextField
          type='website'
          name='website'
          value={website}
          maxLength={50}
          label={<Translate id='dist.website' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.website}
        />
        <FormSeparator text='Contact information' />
        <TextField
          type='contact_name'
          name='contact_name'
          value={contact_name}
          maxLength={50}
          label={<Translate id='dist.contact_name' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.contact_name}
        />
        <TextField
          type='contact_email'
          name='contact_email'
          value={contact_email}
          label={<Translate id='dist.contact_email' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.contact_email}
        />
        <NumberFormat
          name='contact_phone'
          label={<Translate id='dist.contact_phone' />}
          value={contact_phone}
          customInput={TextField}
          format='###-###-####'
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.contact_phone}
        />
        <TextField
          name='contact_phone_ext'
          value={contact_phone_ext}
          maxLength={5}
          label={<Translate id='dist.contact_phone_ext' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.contact_phone_ext}
        />
        <Button
          text={<Translate id='button.save' />}
          isLoading={this.props.loading}
          className='btn btn-primary'
          onClick={this.handleSubmit}
        />
      </div>
    );
  }
}

AddDistributor.propTypes = {
  history: PropTypes.object.isRequired
};

export default AddDistributor;
