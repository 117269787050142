import React from "react";
import UserAvatar from "./UserAvatar";
import SmallLogo from "../../assets/img-logo-milk-sm@3x.png";
import submenuicon from "../../assets/white-right-nav.svg";

const HeaderTop = () => {
  return (
    <div className="top-nav-container">
      <div className="container nav-custom-container">
        <div className="left-side">
          <img
            style={{ height: "50px", marginRight: "10px" }}
            id="p_lt_ctl00_EditableImage1_ucEditableImage_imgImage"
            src={SmallLogo}
            alt="small-logo"
          />
          <a href="http://new.milk.org/">Back to Milk.org</a>
        </div>

        <UserAvatar />
      </div>
    </div>
  );
};

export default HeaderTop;
