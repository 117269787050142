import axios from 'axios';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_LOADING,
  CLEAR_ERRORS
} from './types';

// Register user
export const registerUser = (userData, history) => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const email = '?email=' + userData.email;
  axios
    .post('/api/users/register', userData)
    .then(res => {
      dispatch(setLoading(false));
      history.push('/verify-account' + email);
    })
    .catch(err => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setLoading(false));
    });
};

// Login user
export const loginUser = userData => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  axios
    .post('/api/users/login', userData)
    .then(res => {
      // Set current user
      dispatch(setCurrentUser(res.data));
      localStorage.setItem('userData', JSON.stringify(res.data));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(setLoading(false));
    });
};

export const getCurrentUser = () => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  axios
    .get('/api/users/getProfile')
    .then(res => {
      dispatch(setCurrentUser(res.data));
      localStorage.setItem('userData', JSON.stringify(res.data));
      dispatch(setLoading(false));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(setLoading(false));
    });
};

// Set current user
export const setCurrentUser = userData => {
  return {
    type: SET_CURRENT_USER,
    payload: userData
  };
};

// Logout
export const logoutUser = () => dispatch => {
  //Remove userData from local storage
  localStorage.removeItem('userData');
  dispatch(setCurrentUser({}));
  axios.get('/api/users/logout');
};

// Clear errors
export const clearErrors = () => {
  return { type: CLEAR_ERRORS };
};

// Set loading and clear errors
export const setLoading = isLoading => {
  return {
    type: SET_LOADING,
    payload: isLoading
  };
};
