import React from 'react';

const PresentationScheduleHeader = () => {
  return (
    <div
      style={{ fontWeight: '600', textAlign: 'center' }}
      className='row pl-3 pr-3'
    >
      <div className='col'>
        <p>Presentation time</p>
      </div>
      <div className='col'>
        <p>Curriculum connection</p>
      </div>
      <div className='col'>
        <p>Grade level</p>
      </div>
      <div className='col'>
        <p>Number of students</p>
      </div>
      <div className='col'>
        <p>Teacher</p>
      </div>
      <div className='col'>
        <p>Room Number</p>
      </div>
      <div className='col'>
        <p>Smart Board available</p>
      </div>
      <div className='col'>
        <p>Language</p>
      </div>
    </div>
  );
};

export default PresentationScheduleHeader;
