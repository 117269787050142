import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSchoolById, clearErrors } from '../../redux/actions/schoolActions';
import { Translate } from 'react-localize-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
// inputs
import DatePicker from '../inputs/DatePicker';
import TextField from '../inputs/TextField';
import FormSeparator from '../inputs/FormSeparator';
import Select from '../inputs/Select';
import Button from '../common/Button';

import {
  regDeclineOptions,
  registrationMethodOptions,
  completedByOptions
} from './FormOptions';
import { isEmpty } from '../../utils';
import _ from 'lodash';
import Spinner from '../common/Spinner';
import ConfirmRegistration from './ConfirmRegistration';
import DummyField from '../inputs/DummyField';

export class SchoolRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolId: '',
      isRegistered: false,
      completedBy: null,
      registrationDate: new Date(),
      registrationMethod: { label: '', value: '' },
      membershipStartDate: new Date(),
      regDeclinedDate: null,
      regDeclineReason: { label: '', value: '' },
      coordinatorSignature: '',
      signatureDate: new Date(),
      hasAgreed: false,
      showModal: false,
      showDeclineModal: false,
      accepts_orders: true,
      loading: false,
      errors: {}
    };
  }

  componentDidMount() {
    if (!isEmpty(this.props.school)) {
      this.updateState();
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }

    if (!_.isEqual(this.props.school, prevProps.school)) {
      this.updateState();
    }
  }

  updateState = () => {
    this.setState({
      schoolId: this.props.school.id,
      isRegistered: this.props.school.registration.is_registered,
      completedBy: {
        label: this.props.school.registration.filled_by,
        value: this.props.school.registration.filled_by
      },
      registrationMethod: _.find(registrationMethodOptions, item => {
        return (
          item.value === this.props.school.registration.registration_method
        );
      }),
      regDeclinedDate: this.props.school.registration.registration_decline_date
        ? new Date(this.props.school.registration.registration_decline_date)
        : null,
      regDeclineReason: {
        value: this.props.school.registration.registration_decline_reason,
        label: this.props.school.registration.registration_decline_reason
      },
      coordinatorSignature: this.props.school.registration.coordinator_signature
        ? this.props.school.registration.coordinator_signature
        : '',
      membershipStartDate: this.props.school.registration.membership_start_date
        ? new Date(this.props.school.registration.membership_start_date)
        : new Date(),
      registrationDate: this.props.school.registration.registration_date
        ? new Date(this.props.school.registration.registration_date)
        : new Date(),
      signatureDate: this.props.school.registration.coordinator_signature_date
        ? new Date(this.props.school.registration.coordinator_signature_date)
        : new Date(),
      accepts_orders: this.props.school.accepts_orders
    });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };
  handleShowDeclineModal = () => {
    this.setState({ showDeclineModal: true });
  };
  hideDeclineModal = () => {
    this.setState({
      showDeclineModal: false,
      regDeclinedDate: null,
      regDeclineReason: { label: '', value: '' }
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };
  handleRegistrationDate = registrationDate => {
    this.setState({ registrationDate });
  };
  handleSelectRegistrationMethod = registrationMethod => {
    this.setState({ registrationMethod });
  };
  handleSelectRegDeclineReason = regDeclineReason => {
    this.setState({ regDeclineReason });
  };
  handleDateRegistrationDeclined = regDeclinedDate => {
    this.setState({ regDeclinedDate });
  };
  handleDateMembershipStart = membershipStartDate => {
    this.setState({ membershipStartDate });
  };
  handleCompletedBy = completedBy => {
    this.setState({ completedBy });
  };

  handleSubmit = () => {
    const {
      schoolId,
      membershipStartDate,
      coordinatorSignature,
      registrationMethod,
      accepts_orders
    } = this.state;

    const payload = {
      registration_method: registrationMethod ? registrationMethod.value : '',
      membership_start_date: membershipStartDate,
      coordinator_signature: coordinatorSignature,
      registration_decline_date: null,
      registration_decline_reason: null,
      accepts_orders: accepts_orders
    };

    this.setState({ loading: true });

    // Submit registration
    const url = `/api/schools/${schoolId}`;
    axios
      .post(url, payload)
      .then(res => {
        this.setState({ loading: false });
        this.hideModal();
        this.props.getSchoolById(schoolId);
      })
      .catch(err => {
        this.setState({ errors: err.response, loading: false });
      });
  };

  handleDeclineRegistration = () => {
    const payload = {
      registration_decline_date: this.state.regDeclinedDate,
      registration_decline_reason: this.state.regDeclineReason.value,
      is_registered: false
    };

    this.setState({ loading: true });

    const { schoolId } = this.state;
    const url = `/api/schools/${schoolId}/registration`;
    axios
      .put(url, payload)
      .then(res => {
        this.setState({ loading: false });
        this.hideDeclineModal();
        this.props.getSchoolById(schoolId);
      })
      .catch(err => {
        this.setState({ errors: err.response, loading: false });
      });
  };

  renderDeclineReason = () => {
    const { regDeclineReason, regDeclinedDate } = this.state;
    if (regDeclinedDate) {
      return (
        <React.Fragment>
          <p>
            <strong>Declined date: </strong>{' '}
            <strong className='text-danger'>
              {moment(regDeclinedDate).format('MMMM-DD-YYYY')}
            </strong>{' '}
          </p>
          <p>
            <strong>Declined Reason:</strong>{' '}
            <span className='text-danger'>
              <strong>{regDeclineReason ? regDeclineReason.label : ''}</strong>
            </span>
          </p>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  handleEdit = () => {
    const {
      schoolId,
      membershipStartDate,
      coordinatorSignature,
      registrationMethod,
      registrationDate,
      completedBy,
      accepts_orders
    } = this.state;

    const payload = {
      membership_start_date: membershipStartDate,
      coordinator_signature: coordinatorSignature,
      registration_method: registrationMethod.value,
      registration_date: registrationDate,
      filled_by: completedBy.value,
      accepts_orders: accepts_orders
    };

    this.setState({ loading: true });

    const url = `/api/schools/${schoolId}/registration`;
    axios
      .put(url, payload)
      .then(res => {
        this.setState({ loading: false });
        this.props.getSchoolById(schoolId);
      })
      .catch(err => {
        this.setState({ errors: err.response, loading: false });
      });
  };

  render() {
    const {
      completedBy,
      isRegistered,
      registrationDate,
      registrationMethod,
      membershipStartDate,
      regDeclinedDate,
      regDeclineReason,
      coordinatorSignature,
      signatureDate,
      hasAgreed,
      loading,
      errors,
      showModal,
      showDeclineModal,
      accepts_orders
    } = this.state;

    const isCoordinator = this.props.user === 'COORDINATOR' ? true : false;
    const registrationStatus = isRegistered ? 'Registered' : 'Not Registered';
    let enableAdminEdit;
    let showRegister;
    if (!isCoordinator) {
      enableAdminEdit = false;
      showRegister = isRegistered ? false : true;
    } else {
      enableAdminEdit = isRegistered ? true : false;
      if (!isRegistered) {
        showRegister = regDeclinedDate ? false : true;
      } else {
        showRegister = false;
      }
    }

    if (!isEmpty(this.props.school)) {
      return (
        <div className='container mt-5'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <h2>Registration details</h2>
              <p>Here are your school registration details</p>
              <span className='text-danger'>{errors.message}</span>
              {this.renderDeclineReason()}
            </div>
            {isCoordinator ? null : (
              <Button
                text={<Translate id='button.declineRegistration' />}
                onClick={this.handleShowDeclineModal}
                className='btn btn-danger'
              />
            )}
          </div>

          <FormSeparator text='Registration information' />
          <DummyField
            value={registrationStatus}
            label={<Translate id='schoolForm.isRegistered' />}
            orientation='horizontal'
          />

          {isRegistered ? (
            <Select
              name='completedBy'
              value={completedBy}
              options={completedByOptions}
              label={<Translate id='schoolForm.completedBy' />}
              orientation='horizontal'
              onChange={this.handleCompletedBy}
              disabled={enableAdminEdit}
              error={errors.filled_by ? errors.filled_by : errors.message}
            />
          ) : null}

          <DatePicker
            name='registrationDate'
            label={<Translate id='schoolForm.registrationDate' />}
            value={registrationDate}
            orientation='horizontal'
            onChange={this.handleRegistrationDate}
            disabled={isCoordinator}
          />

          <Select
            name='registrationMethod'
            value={registrationMethod}
            options={registrationMethodOptions}
            label={<Translate id='schoolForm.registrationMethod' />}
            orientation='horizontal'
            onChange={this.handleSelectRegistrationMethod}
            disabled={isCoordinator}
            error={
              errors.registration_method
                ? errors.registration_method
                : errors.message
            }
          />

          <DatePicker
            name='membershipStartDate'
            label={<Translate id='schoolForm.membershipStartDate' />}
            value={membershipStartDate}
            placeholder='Membership Start Date'
            orientation='horizontal'
            onChange={this.handleDateMembershipStart}
            disabled={enableAdminEdit}
          />

          <div className='form-group form-check'>
            <input
              name='accepts_orders'
              id='accepts_orders'
              type='checkbox'
              className='form-check-input'
              value={accepts_orders}
              onChange={this.handleChange}
              checked={accepts_orders}
            />
            <label className='form-check-label' htmlFor='accepts_orders'>
              {<Translate id='schoolOrders.acceptsOrders' />}
            </label>
          </div>

          <FormSeparator text='Sign and agree' />
          <TextField
            name='coordinatorSignature'
            value={coordinatorSignature}
            maxLength={50}
            label={<Translate id='schoolForm.coordinatorSignature' />}
            placeholder='Coordinator Signature'
            orientation='horizontal'
            onChange={this.handleChange}
            error={
              errors.coordinator_signature
                ? errors.coordinator_signature
                : errors.message
            }
            disabled={enableAdminEdit}
          />

          <DatePicker
            name='signatureDate'
            label={<Translate id='schoolForm.signatureDate' />}
            value={signatureDate}
            placeholder='Signature date'
            orientation='horizontal'
            disabled
          />

          {showRegister ? (
            <React.Fragment>
              <div className='form-group form-check'>
                <input
                  name='hasAgreed'
                  id='hasAgreed'
                  type='checkbox'
                  className='form-check-input'
                  value={hasAgreed}
                  onChange={this.handleChange}
                />
                <label className='form-check-label' htmlFor='hasAgreed'>
                  {<Translate id='schoolForm.agreed' />}
                </label>
              </div>

              <Button
                text={<Translate id='button.register' />}
                isLoading={this.props.loading}
                className='btn btn-primary mr-3'
                onClick={this.handleShowModal}
                disabled={!hasAgreed}
              />
            </React.Fragment>
          ) : null}

          {!isCoordinator ? (
            <Button
              text={<Translate id='button.update' />}
              className='btn btn-primary'
              onClick={this.handleEdit}
              disabled={!isRegistered}
              isLoading={loading}
            />
          ) : null}

          <Modal size='md' show={showModal} onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Registration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ConfirmRegistration
                registrationDate={registrationDate}
                registrationMethod={registrationMethod}
                membershipStartDate={membershipStartDate}
                regDeclinedDate={regDeclinedDate}
                regDeclineReason={regDeclineReason}
                coordinatorSignature={coordinatorSignature}
                signatureDate={signatureDate}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                text={<Translate id='button.cancel' />}
                className='btn btn-secondary'
                onClick={this.hideModal}
                disabled={loading}
              />
              <Button
                text={<Translate id='button.continue' />}
                className='btn btn-primary'
                onClick={this.handleSubmit}
                isLoading={loading}
              />
            </Modal.Footer>
          </Modal>

          <Modal
            size='lg'
            show={showDeclineModal}
            onHide={this.hideDeclineModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Decline Registration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <React.Fragment>
                <DatePicker
                  name='regDeclinedDate'
                  value={regDeclinedDate}
                  label={<Translate id='schoolForm.regDeclinedDate' />}
                  onChange={this.handleDateRegistrationDeclined}
                  orientation='horizontal'
                  disabled={isCoordinator}
                />
                <Select
                  name='regDeclineReason'
                  value={regDeclineReason}
                  options={regDeclineOptions}
                  label={<Translate id='schoolForm.regDeclineReason' />}
                  placeholder='Registration Decline Reason'
                  orientation='horizontal'
                  onChange={this.handleSelectRegDeclineReason}
                  disabled={isCoordinator}
                  error={
                    errors.registration_decline_reason
                      ? errors.registration_decline_reason
                      : errors.message
                  }
                />
              </React.Fragment>
            </Modal.Body>
            <Modal.Footer>
              <Button
                text={<Translate id='button.cancel' />}
                className='btn btn-secondary'
                onClick={this.hideDeclineModal}
                disabled={loading}
              />
              <Button
                text={<Translate id='button.save' />}
                className='btn btn-primary'
                onClick={this.handleDeclineRegistration}
                isLoading={loading}
              />
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  componentWillUnmount() {
    this.props.clearErrors();
  }
}

SchoolRegistration.propTypes = {
  user: PropTypes.string.isRequired,
  school: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user.role,
  school: state.school.profile,
  loading: state.school.loading,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {
    getSchoolById,
    clearErrors
  }
)(withRouter(SchoolRegistration));
