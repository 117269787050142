import React from 'react';
import PropTypes from 'prop-types';

const FormSeparator = ({ text }) => {
  return (
    <div style={divStyle}>
      <small className='text-muted'>{text}</small>
      <hr style={hrStyle} />
    </div>
  );
};

const hrStyle = {
  margin: '5px',
  padding: '0px'
};

const divStyle = {
  margin: '20px 0px'
};

FormSeparator.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default FormSeparator;
