import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/authActions';
import { Translate } from 'react-localize-redux';

const UserAvatar = props => {
  const renderDropdown = () => {
    const role = props.auth.user.role;
    const entity = props.auth.user.entity;
    switch (role) {
      case 'COORDINATOR':
        return (
          <div
            className='dropdown-menu dropdown-menu-right dropdown-menu-lg-left'
            aria-labelledby='dropdownMenu2'
          >
            <Link to='/dashboard' className='dropdown-item'>
              <Translate id='navbar.dashboard' />
            </Link>
            <Link to='/dashboard/profile' className='dropdown-item'>
              <Translate id='navbar.profile' />
            </Link>
            {entity ? (
              <Link
                to='/dashboard/request-dairy-educator'
                className='dropdown-item'
              >
                <Translate id='navbar.dairyEducatorForm' />
              </Link>
            ) : null}

            <span onClick={props.logoutUser} className='dropdown-item cp'>
              <Translate id='navbar.logout' />
            </span>
          </div>
        );
      case 'ADMIN':
        return (
          <div
            className='dropdown-menu dropdown-menu-right dropdown-menu-lg-left'
            aria-labelledby='dropdownMenu2'
          >
            <Link to='/admin/dashboard' className='dropdown-item'>
              <Translate id='navbar.dashboard' />
            </Link>
            <Link to='/admin/dashboard/schools' className='dropdown-item'>
              <Translate id='navbar.manageSchools' />
            </Link>
            <Link
              to='/admin/dashboard/distributor-content'
              className='dropdown-item'
            >
              <Translate id='navbar.manageContent' />
            </Link>
            <Link to='/admin/dashboard/school-notes' className='dropdown-item'>
              <Translate id='navbar.notes' />
            </Link>
            <Link to='/admin/dashboard/profile' className='dropdown-item'>
              <Translate id='navbar.profile' />
            </Link>

            <span onClick={props.logoutUser} className='dropdown-item cp'>
              <Translate id='navbar.logout' />
            </span>
          </div>
        );
      case 'SUPER':
        return (
          <div
            className='dropdown-menu dropdown-menu-right dropdown-menu-lg-left'
            aria-labelledby='dropdownMenu2'
          >
            <Link to='/admin/dashboard' className='dropdown-item'>
              <Translate id='navbar.dashboard' />
            </Link>
            <Link to='/admin/dashboard/schools' className='dropdown-item'>
              <Translate id='navbar.manageSchools' />
            </Link>
            <Link to='/admin/dashboard/manage-users' className='dropdown-item'>
              <Translate id='navbar.manageUsers' />
            </Link>
            <Link to='/admin/dashboard/addschool' className='dropdown-item'>
              <Translate id='navbar.addSchool' />
            </Link>
            <Link to='/admin/dashboard/distributors' className='dropdown-item'>
              <Translate id='navbar.manageDistributors' />
            </Link>
            <Link
              to='/admin/dashboard/distributor-content'
              className='dropdown-item'
            >
              <Translate id='adminDashboard.manageContent' />
            </Link>
            <Link to='/admin/dashboard/school-notes' className='dropdown-item'>
              <Translate id='navbar.notes' />
            </Link>
            <Link to='/admin/dashboard/profile' className='dropdown-item'>
              <Translate id='navbar.profile' />
            </Link>

            <span onClick={props.logoutUser} className='dropdown-item cp'>
              <Translate id='navbar.logout' />
            </span>
          </div>
        );
      case 'PARENT':
        return (
          <div
            className='dropdown-menu dropdown-menu-right dropdown-menu-lg-left'
            aria-labelledby='dropdownMenu2'
          >
            <Link to='/parent/dashboard' className='dropdown-item'>
              <Translate id='navbar.dashboard' />
            </Link>
            <Link to='/parent/dashboard/profile' className='dropdown-item'>
              <Translate id='navbar.profile' />
            </Link>

            <span onClick={props.logoutUser} className='dropdown-item cp'>
              <Translate id='navbar.logout' />
            </span>
          </div>
        );
      case 'DISTRIBUTOR':
        return (
          <div
            className='dropdown-menu dropdown-menu-right dropdown-menu-lg-left'
            aria-labelledby='dropdownMenu2'
          >
            <Link to='/distributor/dashboard' className='dropdown-item'>
              <Translate id='navbar.dashboard' />
            </Link>
            <Link
              to='/distributor/dashboard/settings'
              className='dropdown-item'
            >
              <Translate id='navbar.profile' />
            </Link>

            <span onClick={props.logoutUser} className='dropdown-item cp'>
              <Translate id='navbar.logout' />
            </span>
          </div>
        );
      default:
        return (
          <div
            className='dropdown-menu dropdown-menu-right dropdown-menu-lg-left'
            aria-labelledby='dropdownMenu2'
          >
            <span onClick={props.logoutUser} className='dropdown-item cp'>
              <Translate id='navbar.logout' />
            </span>
          </div>
        );
    }
  };

  let firstName;
  let lastName;

  //Check if authenticated
  if (props.auth.isAuthenticated) {
    // Drill the data from app state
    firstName = props.auth.user.first_name;
    lastName = props.auth.user.last_name;

    return (
      <div className='dropdown'>
        <span
          className='dropdown-toggle cp'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
          data-display='static'
        >
          Hello, {firstName} {lastName}
        </span>
        {renderDropdown()}
      </div>
    );
  } else {
    return (
      <div className='right-side'>
        <div className='right-side-inner'>
          <Link className='fancy-link borderFromRight' to='/register'>
            Register
          </Link>
          <Link className='fancy-link borderFromRight' to='/login'>
            Log in to order
          </Link>
        </div>
      </div>
    );
  }
};

UserAvatar.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(UserAvatar);
