import React from 'react';
import logo from '../../assets/img-logo-esmp.svg';
import submenuicon from '../../assets/white-right-nav.svg';

const HeaderBottom = () => {
  return (
    <div className='header-bottom'>
      <div className='container nav-custom-container'>
        <div className='header-bottom-logo'>
          <a href='/dashboard'>
            <img src={logo} alt='dfo-logo' />
          </a>
        </div>

        <div className='header-bottom-right'>
          <div>
            <a
              className='nav-link'
              href='https://ontarioschools.milk.org/about/'
            >
              About
            </a>
          </div>
          <div className='dropdown'>
            <div className='d-flex'>
              <a
                className='nav-link dropdown'
                href='https://ontarioschools.milk.org/parent/'
              >
                Parent Info
              </a>
              <img className='submenu-button' src={submenuicon} alt='' />
            </div>
            <div style={{ width: '180px' }} className='dropdown-content'>
              <a
                href='https://ontarioschools.milk.org/parent/nutrition-info/'
                className='nav-link'
              >
                Nutrition Information
              </a>
              <a
                href='https://ontarioschools.milk.org/parent/volunteer/'
                className='nav-link'
              >
                Volunteer
              </a>
            </div>
          </div>

          <div className='dropdown'>
            <div className='d-flex'>
              <a
                className='nav-link'
                href='https://ontarioschools.milk.org/school/'
              >
                Coordinators Corner
              </a>
              <img className='submenu-button' src={submenuicon} alt='' />
            </div>

            <div style={{ width: '320px' }} className='dropdown-content'>
              <a
                href='https://ontarioschools.milk.org/school/rewards/'
                className='nav-link'
              >
                Rewards
              </a>
              <a
                href='https://ontarioschools.milk.org/school/tools-and-tips/'
                className='nav-link'
              >
                Tools & Tips
              </a>
              <a
                href='https://ontarioschools.milk.org/school/milk-delivery/'
                className='nav-link'
              >
                Distinguished Dairy Program
              </a>
            </div>
          </div>
          <div>
            <a
              className='nav-link'
              href='https://ontarioschools.milk.org/school-programs/'
            >
              School Programs
            </a>
          </div>
          <div>
            <a
              className='nav-link'
              href='https://ontarioschools.milk.org/contact-us/'
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
