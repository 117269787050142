import axios from 'axios';
import {
  GET_ERRORS,
  SET_DIST_LOADING,
  GET_DISTRIBUTOR,
  GET_DISTRIBUTOR_ORDERS,
  CLEAR_DISTRIBUTOR,
  CLEAR_ERRORS
} from './types';
import { getDistributors } from './adminActions';

export const getDistributorProfile = id => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const url = `/api/distributors/${id}`;
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: GET_DISTRIBUTOR,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(setLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const editDistributorProfile = payload => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const url = '/api/distributors/profile';
  axios
    .put(url, payload)
    .then(res => {
      dispatch({
        type: GET_DISTRIBUTOR,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(setLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const superEditDistributorProfile = (id, payload, page) => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const url = `/api/distributors/${id}`;
  axios
    .put(url, payload)
    .then(res => {
      dispatch({
        type: GET_DISTRIBUTOR,
        payload: res.data
      });
      dispatch(getDistributors(page));
    })
    .catch(err => {
      dispatch(setLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const getDistributorOrders = (
  id,
  page,
  orderId,
  child,
  status,
  school
) => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const url = `/api/distributors/${id}/orders`;
  const data = {
    id: orderId,
    childName: child,
    status: status,
    page: page,
    schoolId: school
  };
  axios
    .get(url, { params: data })
    .then(res => {
      dispatch({
        type: GET_DISTRIBUTOR_ORDERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(setLoading(false));
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Clear errors
export const clearErrors = () => {
  return { type: CLEAR_ERRORS };
};

// Set loading and clear errors
export const setLoading = isLoading => {
  return {
    type: SET_DIST_LOADING,
    payload: isLoading
  };
};

// Clear distributors
export const clearDistributorProfile = () => {
  return { type: CLEAR_DISTRIBUTOR };
};
