import React from 'react';
import PropTypes from 'prop-types';

const DummyField = ({
  value,
  label,
  helperText,
  orientation // horizontal || vertical(default)
}) => {
  if (orientation === 'horizontal') {
    return (
      <div className='form-group row'>
        <label className='col-sm-4 col-form-label'>{label}</label>
        <div className='col-sm-8'>
          <p className='col-form-label ml-2'>
            <strong>{value}</strong>
          </p>
          {helperText && (
            <small className='form-text text-muted'>{helperText}</small>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className='form-group'>
        <label>{label}:</label>
        <p>
          <strong>{value}</strong>
        </p>

        {helperText && (
          <small className='form-text text-muted'>{helperText}</small>
        )}
        <hr />
      </div>
    );
  }
};

DummyField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  helperText: PropTypes.string,
  orientation: PropTypes.string
};

DummyField.defaultProps = {
  orientation: 'vertical'
};

export default DummyField;
