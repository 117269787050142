import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import {
  getAllSchools,
  getSchoolBoards,
  clearSchools,
} from "../../redux/actions/schoolActions";
import Pagination from "../common/Pagination";
import SchoolList from "../school/SchoolList";
import { SchoolSearchForm } from "./SchoolSearchForm";
import Spinner from "../common/Spinner";
import Button from "../common/Button";
import { Translate } from "react-localize-redux";
import _ from "lodash";
import { isEmpty } from "../../utils";

export class SchoolSearch extends Component {
  constructor() {
    super();
    this.state = {
      schoolName: "",
      postalCode: "",
      phoneNumber: "",
      schoolBoards: "",
      display: "", // Can be null(all), 1(registered), 0(not registered)
      loadingExport: false,
      scoEdited: false,
      loadingSCO: false,
      selectedSchools: [], // selected for SCO
      errors: {},
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    document.title = "Schools - Dairy Farmers of Ontario ESMP Portal";
    let page = 1;
    if (this.props.match.params.page) {
      page = this.props.match.params.page;
    }
    this.props.getAllSchools(page);
    this.props.getSchoolBoards();
  }

  componentWillUnmount() {
    this.props.clearSchools();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (!_.isEqual(nextProps.schools.results, this.props.schools.results)) {
      console.log("PrevProps:", nextProps.schools.results);
      console.log("CurrentProps:", this.props.schools.results);
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch(e) {
    const {
      schoolName,
      postalCode,
      phoneNumber,
      display,
      schoolBoard,
    } = this.state;
    e.preventDefault();
    this.props.getAllSchools(
      1,
      schoolName,
      postalCode,
      phoneNumber,
      display,
      schoolBoard
    );
    this.props.history.push("/admin/dashboard/schools/" + 1);
  }

  loadPage = (page) => {
    const {
      schoolName,
      postalCode,
      phoneNumber,
      display,
      schoolBoard,
    } = this.state;
    this.props.getAllSchools(
      page,
      schoolName,
      postalCode,
      phoneNumber,
      display,
      schoolBoard
    );
    this.props.history.push("/admin/dashboard/schools/" + page);
  };

  handleExport = () => {
    const { schoolName, postalCode, phoneNumber, display } = this.state;
    const url = "/api/schools/";
    const payload = {
      page: 1,
      name: schoolName,
      postal_code: postalCode,
      phone: phoneNumber,
      isRegistered: display,
      csv: true,
    };
    this.setState({ loadingExport: true });
    axios
      .get(url, { params: payload })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `school-export-${moment().format("MM-DD-YYYY")}.csv`
        );
        document.body.appendChild(link);
        link.click();
        this.setState({ loadingExport: false });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, loadingExport: false });
      });
  };

  handleExportAll = () => {
    this.setState({ loadingExport: true });
    const url = "/api/orders/export";
    axios
      .get(url)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `orders-export-${moment().format("MM-DD-YYYY")}.csv`
        );
        document.body.appendChild(link);
        link.click();
        this.setState({ loadingExport: false });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, loadingExport: false });
      });
  };

  onChange = (id, uses_SCO) => {
    const newArr = this.state.selectedSchools.slice();
    const idArr = newArr.map((el) => {
      return el.id;
    });
    if (idArr.includes(id)) {
      newArr.forEach((el, index) => {
        if (id === el.id) el.uses_SCO = !el.uses_SCO;
      });
    } else newArr.push({ id: id, uses_SCO: !uses_SCO });

    this.setState({ scoEdited: true, selectedSchools: newArr });
  };

  handleSCO = async () => {
    const path = "/api/schools/sco";
    const payload = this.state.selectedSchools;

    try {
      const res = await axios.put(path, payload);
      let page = 1;
      if (this.props.match.params.page) {
        page = this.props.match.params.page;
      }
      this.props.getAllSchools(page);
      this.setState({ selectedSchools: [], scoEdited: false });
    } catch (error) {
      this.setState({ errors: error.response.data });
    }
  };

  render() {
    const {
      display,
      schoolBoard,
      loadingExport,
      errors,
      scoEdited,
      loadingSCO,
    } = this.state;
    const { loading } = this.props;

    let showPagination;

    if (this.props.schools.totalResults && isEmpty(errors)) {
      showPagination = true;
    } else showPagination = false;

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h1>
            <Translate id="schoolSearch.title" />
          </h1>
          <Button
            text={<Translate id="button.exportAll" />}
            isLoading={loadingExport}
            className="btn btn-sm btn-primary"
            onClick={this.handleExportAll}
          />
        </div>
        <span className="text-danger">{errors.message}</span>
        <SchoolSearchForm
          handleChange={this.handleChange}
          handleSearch={this.handleSearch}
          loading={this.props.loading}
          display={display}
          schoolBoard={schoolBoard}
          boards={this.props.boards}
        />

        <div>
          <Button
            text={<Translate id="button.export" />}
            isLoading={loadingExport}
            className="btn btn-sm btn-primary mb-3"
            onClick={this.handleExport}
          />
        </div>

        {this.props.loading ? (
          <Spinner />
        ) : (
          <SchoolList
            schools={this.props.schools.results}
            userRole="ADMIN"
            onChange={this.onChange}
            selectedSchools={this.state.selectedSchools}
            loading={loading}
            errors={errors}
          />
        )}
        <div className="d-flex justify-content-between align-items-center p-2">
          {showPagination ? (
            <Pagination
              totalRecords={this.props.schools.totalResults}
              page={this.props.match.params.page}
              history={this.props.history}
              onPageChange={this.loadPage}
            />
          ) : null}

          <div className="ml-auto">
            {scoEdited ? (
              <Button
                text={<Translate id="button.save" />}
                isLoading={loadingSCO}
                className="btn btn-sm btn-primary mb-3"
                onClick={this.handleSCO}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

SchoolSearch.propTypes = {
  schools: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  boards: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  schools: state.school.schools,
  boards: state.school.boards,
  loading: state.school.loading,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getAllSchools,
  clearSchools,
  getSchoolBoards,
})(SchoolSearch);
