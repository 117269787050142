import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Button from '../common/Button';

export class DistOrderSearch extends Component {
  render() {
    return (
      <form className='mb-3 mt-3'>
        <div className='form-row'>
          <div className='col-md-2 col-12 mb-1'>
            <input
              type='text'
              name='childName'
              className='form-control form-control-sm'
              placeholder='Child name'
              onChange={this.props.handleChange}
            />
          </div>
          <div className='col-md-2 col-12 mb-1'>
            <input
              type='number'
              name='orderId'
              className='form-control form-control-sm'
              placeholder='Order id'
              onChange={this.props.handleChange}
            />
          </div>
          <div className='col-md-3 col-12 mb-1'>
            <select
              name='status'
              className='form-control form-control-sm'
              value={this.props.status}
              onChange={this.props.handleChange}
            >
              <option value=''>All</option>
              <option value='ACTIVE'>Active</option>
              <option value='PAUSED'>Paused</option>
              <option value='CANCELLED'>Cancelled</option>
            </select>
          </div>
          <div className='col-md-3 col-12 mb-1'>
            <select
              name='school'
              className='form-control form-control-sm'
              value={this.props.school}
              onChange={this.props.handleChange}
            >
              <option value=''>All</option>
              {this.props.schools.map(val => {
                return (
                  <option key={val.value} value={val.value}>
                    {val.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='col-md-2 col-12 mb-1'>
            <Button
              text={<Translate id='button.search' />}
              isLoading={this.props.loading}
              className='btn btn-sm btn-primary'
              onClick={this.props.handleSearch}
            />
          </div>
        </div>
      </form>
    );
  }
}

DistOrderSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired,
  schools: PropTypes.array.isRequired
};

export default DistOrderSearch;
