import React from 'react';
import Spinner from '../common/Spinner';
import UserListItem from './UserListItem';

const UserList = ({ users }) => {
  let userList;
  if (users) {
    userList = users.map(user => {
      return <UserListItem user={user} key={user.id} />;
    });
  } else {
    users = <Spinner />;
  }

  return <ul className='list-group'>{userList}</ul>;
};

export default UserList;
