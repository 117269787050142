import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SchoolListHeader from "./SchoolListHeader";
import Spinner from "../common/Spinner";
import { Translate } from "react-localize-redux";
import { isEmpty } from "../../utils";
import _ from "lodash";

const SchoolList = ({
  schools,
  userRole,
  onChange,
  selectedSchools,
  loading,
  errors,
}) => {
  let schoolList;
  const urlPrefix =
    userRole === "COORDINATOR"
      ? "/dashboard"
      : `/${userRole.toLowerCase()}/dashboard`;

  if (errors.message === "No schools found") return null;

  if (!isEmpty(schools) && !loading) {
    schoolList = schools.map((school) => {
      let value = school.uses_SCO;
      selectedSchools.forEach((el) => {
        if (el.id === school.id) {
          console.log("Found id", school.id);
          value = el.uses_SCO;
          console.log(value);
        }
      });

      return (
        <li key={school.id} className="list-group-item">
          <div className="row">
            <div className="col-md-2 col-10 text-truncate">
              <Link to={`${urlPrefix}/school/${school.id}`}>{school.name}</Link>
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.registration.schoolYear.name}
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.city}
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.phone}
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.registration.is_registered ? (
                <Translate id="schoolList.registered" />
              ) : (
                <Translate id="schoolList.notRegistered" />
              )}
            </div>
            <div className="col-sm-1 text-truncate d-none d-md-block">
              <input
                style={{ width: "15px", height: "15px" }}
                type="checkbox"
                name="sco"
                value={value}
                checked={value}
                onChange={() => onChange(school.id, school.uses_SCO)}
              />
            </div>
            <div className="col-sm-1 col-2 text-right">
              <Link to={`${urlPrefix}/school/${school.id}`}>
                <i className="fas fa-ellipsis-v" />
              </Link>
            </div>
          </div>
        </li>
      );
    });
  } else {
    schoolList = <Spinner />;
  }

  return (
    <div>
      <SchoolListHeader />
      <ul className="list-group">{schoolList}</ul>
    </div>
  );
};

SchoolList.propTypes = {
  schools: PropTypes.array,
  userRole: PropTypes.string.isRequired,
};

export default SchoolList;
