import axios from 'axios';

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_USERS,
  SET_ADMIN_LOADING,
  GET_DISTRIBUTORS
} from '../actions/types';

// Get all users admins and users
export const getUsers = (pageNum, roles) => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const data = {
    page: pageNum,
    role: roles
  };
  axios
    .get('/api/users', { params: data })
    .then(res => {
      dispatch({
        type: GET_USERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(setLoading(false));
    });
};

// Register a new admin
export const registerNewAdmin = (payload, history) => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  axios
    .post('/api/users/admin', payload)
    .then(res => {
      dispatch(getUsers());
      history.push('/admin/dashboard/manage-users');
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(setLoading(false));
    });
};

// Get distributors
export const getDistributors = (page, name) => dispatch => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  axios
    .get('/api/distributors/', { params: { page: page, name: name } })
    .then(res => {
      dispatch({
        type: GET_DISTRIBUTORS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(setLoading(false));
    });
};

// Clear errors
export const clearErrors = () => {
  return { type: CLEAR_ERRORS };
};

// Set loading and clear errors
export const setLoading = isLoading => {
  return {
    type: SET_ADMIN_LOADING,
    payload: isLoading
  };
};
