import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DashboardNavThumb = ({ text, link }) => {
  return (
    <Link className="dashboardNavThumb" to={link}>
      {text}
    </Link>
  );
};

DashboardNavThumb.propTypes = {
  text: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired
};

DashboardNavThumb.defaultProps = {
  text: "Text goes here",
  link: "/"
};

export default DashboardNavThumb;
