import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

const SchoolHeader = props => {
  const admin = props.user === 'COORDINATOR' ? '' : '/admin';
  const currentpath = `${admin}/dashboard/school/${props.match.params.school}`;
  const width =
    props.user === 'COORDINATOR' ? { width: '30%' } : { width: '24%' };
  return (
    <div className='d-flex justify-content-around align-items-center flex-wrap'>
      <NavLink
        style={width}
        className='schoolHeader'
        activeClassName='schoolHeaderActive'
        to={`${currentpath}/profile`}
      >
        Profile
      </NavLink>

      <NavLink
        style={width}
        className='schoolHeader'
        activeClassName='schoolHeaderActive'
        to={`${currentpath}/registration`}
      >
        Registration
      </NavLink>

      <NavLink
        style={width}
        className='schoolHeader'
        activeClassName='schoolHeaderActive'
        to={`${currentpath}/orders`}
      >
        Orders
      </NavLink>

      {props.user === 'COORDINATOR' ? null : (
        <NavLink
          style={width}
          className='schoolHeader'
          activeClassName='schoolHeaderActive'
          to={`${currentpath}/notes`}
        >
          Notes
        </NavLink>
      )}
    </div>
  );
};

SchoolHeader.propTypes = {
  user: PropTypes.string.isRequired
};

export default withRouter(SchoolHeader);
