import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

const SchoolCoordinators = ({ coordinators, user }) => {
  let coordinatorList = null;
  if (coordinators) {
    coordinatorList = coordinators.map(c => {
      const cName =
        user !== 'COORDINATOR' ? (
          <Link
            to={`/admin/dashboard/manage-users/edit/${c.id}`}
          >{`${c.first_name} ${c.last_name}`}</Link>
        ) : (
          `${c.first_name} ${c.last_name}`
        );
      return (
        <li className='list-group-item'>
          <div className='row'>
            <div className='col-2'>{c.id}</div>
            <div className='col-10'>{cName}</div>
          </div>
        </li>
      );
    });
  }

  return (
    <div>
      <div className='row table-header'>
        <div className='col-2 text-truncate'>
          <Translate id='user.id' />
        </div>
        <div className='col-10 text-truncate'>
          <Translate id='user.name' />
        </div>
      </div>
      <ul className='list-group'>{coordinatorList}</ul>
    </div>
  );
};

SchoolCoordinators.propTypes = {
  coordinators: PropTypes.array,
  user: PropTypes.string.isRequired
};

export default SchoolCoordinators;
