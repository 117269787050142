import {
  GET_SCHOOL,
  GET_SCHOOLS,
  SET_SCHOOL_LOADING,
  GET_BOARDS,
  GET_ORDERS,
  GET_NOTES,
  CLEAR_NOTES
} from '../actions/types';

const initialState = {
  profile: {},
  orders: {},
  schools: {},
  boards: [],
  notes: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SCHOOL:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    case GET_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
        loading: false
      };
    case SET_SCHOOL_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case GET_BOARDS:
      return {
        ...state,
        boards: action.payload
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload,
        loading: false
      };
    case CLEAR_NOTES:
      return {
        ...state,
        notes: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
