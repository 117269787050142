import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from '../inputs/AsyncSelect';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Button from '../common/Button';
import { isEmpty } from '../../utils';

export class DistributorSelect extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showPostalSearch: false,
      distributor: {}
    };
  }

  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    save: PropTypes.func.isRequired
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false, distributor: {} });
  };
  handleSelect = distributor => {
    this.setState({ distributor });
  };

  loadOptions = inputValue =>
    new Promise((resolve, reject) => {
      const payload = {
        name: inputValue
      };
      const url = '/api/distributors/autocomplete';
      if (inputValue.length >= 3)
        axios
          .get(url, { params: payload })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
    });

  loadSecondOptions = inputValue =>
    new Promise((resolve, reject) => {
      const payload = {
        postal: inputValue
      };
      const url = '/api/distributors/geosearch';
      if (inputValue.length >= 2)
        axios
          .get(url, { params: payload })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
    });

  handleSave = distributor => {
    this.props.save(distributor);
    this.hideModal();
  };
  changeSearch = () => {
    const { showPostalSearch } = this.state;
    this.setState({ showPostalSearch: !showPostalSearch, distributor: {} });
  };

  renderSearch = () => {
    const { isAdmin } = this.props;
    const { distributor, showPostalSearch } = this.state;
    if (showPostalSearch) {
      return (
        <div>
          <AsyncSelect
            name='distributor'
            label='Postal Code'
            placeholder=''
            value={distributor}
            orientation='horizontal'
            loadOptions={this.loadSecondOptions}
            onChange={this.handleSelect}
            isClearable={false}
            disabled={isAdmin}
          />
          <div className='text-right'>
            <Button
              className='btn btn-link'
              text='Search by distributor name'
              onClick={this.changeSearch}
            />
          </div>
        </div>
      );
    } else
      return (
        <div>
          <AsyncSelect
            name='distributor'
            label='Distributor'
            placeholder='Distributor name'
            value={distributor}
            orientation='horizontal'
            loadOptions={this.loadOptions}
            onChange={this.handleSelect}
            isClearable={false}
            disabled={isAdmin}
          />
          <div className='text-right'>
            <Button
              className='btn btn-link'
              text='Search by postal code'
              onClick={this.changeSearch}
            />
          </div>
        </div>
      );
  };

  render() {
    const { showModal, distributor } = this.state;
    const { label, value, helperText } = this.props;
    return (
      <div>
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label'>{label}</label>
          <div className='col-sm-8'>
            <p className='col-form-label ml-2'>
              <strong>{value.label}</strong>
              <span
                style={{ marginLeft: '10px', color: 'blue', cursor: 'pointer' }}
                onClick={this.showModal}
              >
                Change
              </span>
            </p>
            {helperText && (
              <small className='form-text text-muted'>{helperText}</small>
            )}
          </div>
        </div>

        <Modal centered size='md' show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Choose a distributor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.renderSearch()}
              <Button
                className='btn btn-primary'
                text='Select'
                onClick={() => this.handleSave(distributor)}
                disabled={isEmpty(distributor)}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DistributorSelect;
