import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMySchools, clearSchools } from "../../redux/actions/schoolActions";
import UserSchoolList from "../school/UserSchoolList";
import Pagination from "../common/Pagination";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { isEmpty } from "../../utils";
import Spinner from "../common/Spinner";

export class MySchools extends Component {
  componentDidMount() {
    let page = 1;
    if (isEmpty(this.props.schools)) {
      if (this.props.match.params.page) {
        page = this.props.match.params.page;
      }
      this.props.getMySchools(page);
    }
  }

  componentWillUnmount() {
    this.props.clearSchools();
  }

  loadPage = (page) => {
    this.props.getMySchools(page);
    this.props.history.push("/dashboard/my-schools/" + page);
  };

  render() {
    const { errors } = this.props;
    return (
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1>
            <Translate id="dashboard.mySchools" />
          </h1>
          <Link className="btn btn-sm btn-primary" to="/dashboard/claim-school">
            <Translate id="dashboard.claimSchool" />
          </Link>
        </div>
        <span className="text-danger">{errors.message}</span>
        {this.props.loading ? (
          <Spinner />
        ) : (
          <UserSchoolList
            schools={this.props.schools.results}
            userRole={this.props.user.role}
          />
        )}
        {this.props.schools.totalResults ? (
          <Pagination
            totalRecords={this.props.schools.totalResults}
            page={this.props.match.params.page}
            history={this.props.history}
            onPageChange={this.loadPage}
          />
        ) : null}
      </div>
    );
  }
}

MySchools.propTypes = {
  user: PropTypes.object.isRequired,
  schools: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  schools: state.school.schools,
  loading: state.school.loading,
  errors: state.errors,
});

const mapDispatchToProps = {
  getMySchools,
  clearSchools,
};

export default connect(mapStateToProps, mapDispatchToProps)(MySchools);
