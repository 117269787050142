import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DummyField from './DummyField';

const TextField = ({
  name,
  placeholder,
  value,
  label,
  error,
  type,
  onChange,
  helperText,
  autoComplete,
  orientation, // horizontal || vertical(default)
  maxLength,
  required,
  disabled
}) => {
  if (disabled) {
    return <DummyField value={value} label={label} orientation={orientation} />;
  } else {
    if (orientation === 'horizontal') {
      return (
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label' htmlFor={name}>
            {label}
            {required ? '*' : null}
          </label>
          <div className='col-sm-8'>
            <input
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              className={classnames('form-control', {
                'is-invalid': error
              })}
              autoComplete={autoComplete}
              maxLength={maxLength}
            />
            {helperText && (
              <small className='form-text text-muted'>{helperText}</small>
            )}
            {error && <div className='invalid-feedback'>{error}</div>}
          </div>
        </div>
      );
    } else {
      return (
        <div className='form-group'>
          <label htmlFor={name}>
            {label}
            {required ? '*' : null}
          </label>

          <input
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={classnames('form-control', {
              'is-invalid': error
            })}
            autoComplete={autoComplete}
            maxLength={maxLength}
          />
          {helperText && (
            <small className='form-text text-muted'>{helperText}</small>
          )}
          {error && <div className='invalid-feedback'>{error}</div>}
        </div>
      );
    }
  }
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool
};

TextField.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  orientation: 'vertical',
  required: false
};

export default TextField;
