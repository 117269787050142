import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getSchoolOrders,
  getParentOrders
} from '../../redux/actions/schoolActions';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Translate } from 'react-localize-redux';
import TextField from '../inputs/TextField';
import DatePicker from '../inputs/DatePicker';
import FormSeparator from '../inputs/FormSeparator';
import NumberFormat from 'react-number-format';
import Button from '../common/Button';
import Select from '../inputs/Select';
import AsyncSelect from '../inputs/AsyncSelect';
import DummyField from '../inputs/DummyField';
import OrderPrice from './OrderPrice';

export class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      childName: '',
      parentName: '',
      createdAt: null,
      lastUpdated: null,
      status: { value: '', label: '' },
      pausedUntil: null,
      disableEdit: false,
      schoolId: '',
      school: { label: '', value: '', accepts_offers: '' },

      whiteMilk_monday: 0,
      whiteMilk_tuesday: 0,
      whiteMilk_wednesday: 0,
      whiteMilk_thursday: 0,
      whiteMilk_friday: 0,

      chocolateMilk_monday: 0,
      chocolateMilk_tuesday: 0,
      chocolateMilk_wednesday: 0,
      chocolateMilk_thursday: 0,
      chocolateMilk_friday: 0,
      loading: false,
      errors: {}
    };
  }

  componentDidMount() {
    const { order } = this.props;
    const fallbackDate = new Date();
    fallbackDate.setDate(fallbackDate.getDate() + 7);
    if (order) {
      this.setState({
        id: order.id,
        childName: order.child_name,
        parentName: order.parentName,
        createdAt: order.createdAt,
        lastUpdated: order.updatedAt,
        status: { value: order.status, label: order.status },
        pausedUntil: order.paused_until
          ? new Date(order.paused_until)
          : fallbackDate,
        schoolId: order.school_id,
        school: order.school,

        whiteMilk_monday: order.whiteMilkSchedule.monday,
        whiteMilk_tuesday: order.whiteMilkSchedule.tuesday,
        whiteMilk_wednesday: order.whiteMilkSchedule.wednesday,
        whiteMilk_thursday: order.whiteMilkSchedule.thursday,
        whiteMilk_friday: order.whiteMilkSchedule.friday,

        chocolateMilk_monday: order.chocolateMilkSchedule.monday,
        chocolateMilk_tuesday: order.chocolateMilkSchedule.tuesday,
        chocolateMilk_wednesday: order.chocolateMilkSchedule.wednesday,
        chocolateMilk_thursday: order.chocolateMilkSchedule.thursday,
        chocolateMilk_friday: order.chocolateMilkSchedule.friday
      });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleCreatedAt = createdAt => {
    this.setState({ createdAt });
  };
  handleLastUpdated = lastUpdated => {
    this.setState({ lastUpdated });
  };
  handlePausedUntil = pausedUntil => {
    this.setState({ pausedUntil });
  };
  handleStatusSelect = status => {
    this.setState({ status });
  };

  handleSubmit = e => {
    const {
      id,
      whiteMilk_monday,
      whiteMilk_tuesday,
      whiteMilk_wednesday,
      whiteMilk_thursday,
      whiteMilk_friday,
      chocolateMilk_monday,
      chocolateMilk_tuesday,
      chocolateMilk_wednesday,
      chocolateMilk_thursday,
      chocolateMilk_friday,
      childName,
      status,
      pausedUntil,
      schoolId,
      school
    } = this.state;

    const url = `/api/orders/${id}`;
    let payload = {
      child_name: childName,
      status: status.value,
      paused_until: status.value === 'PAUSED' ? pausedUntil : null,
      school_id: school.value,
      whiteMilkSchedule: {
        monday: whiteMilk_monday,
        tuesday: whiteMilk_tuesday,
        wednesday: whiteMilk_wednesday,
        thursday: whiteMilk_thursday,
        friday: whiteMilk_friday
      },
      chocolateMilkSchedule: {
        monday: chocolateMilk_monday,
        tuesday: chocolateMilk_tuesday,
        wednesday: chocolateMilk_wednesday,
        thursday: chocolateMilk_thursday,
        friday: chocolateMilk_friday
      }
    };

    this.setState({ loading: true });
    if (this.props.auth.user.role !== 'PARENT') {
      axios
        .put(url, payload)
        .then(res => {
          this.props.getSchoolOrders(schoolId, 1);
        })
        .catch(err => {
          this.setState({ errors: err.response.data, loading: false });
        });
    } else {
      axios
        .put(url, payload)
        .then(res => {
          this.props.getParentOrders();
          this.props.closeModal();
        })
        .catch(err => {
          this.setState({ errors: err.response.data, loading: false });
        });
    }
  };

  renderMilkSchedule = status => {
    const {
      errors,
      whiteMilk_monday,
      whiteMilk_tuesday,
      whiteMilk_wednesday,
      whiteMilk_thursday,
      whiteMilk_friday,
      chocolateMilk_monday,
      chocolateMilk_tuesday,
      chocolateMilk_wednesday,
      chocolateMilk_thursday,
      chocolateMilk_friday,
      pausedUntil,
      school
    } = this.state;

    if (school.accepts_orders) {
      switch (status) {
        case 'ACTIVE':
          return (
            <div className='row'>
              <div className='col'>
                <FormSeparator text='White milk schedule' />
                <NumberFormat
                  name='whiteMilk_monday'
                  type='text'
                  value={whiteMilk_monday}
                  customInput={TextField}
                  label='Monday'
                  format='#'
                  orientation='vertical'
                  onChange={this.handleChange}
                  allowNegative={false}
                  error={
                    errors.whiteMilkSchedule_monday
                      ? errors.whiteMilkSchedule_monday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='whiteMilk_tuesday'
                  value={whiteMilk_tuesday}
                  customInput={TextField}
                  label='Tuesday'
                  format='#'
                  orientation='vertical'
                  onChange={this.handleChange}
                  allowNegative={false}
                  error={
                    errors.whiteMilkSchedule_tuesday
                      ? errors.whiteMilkSchedule_tuesday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='whiteMilk_wednesday'
                  value={whiteMilk_wednesday}
                  customInput={TextField}
                  label='Wednesday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.whiteMilkSchedule_wednesday
                      ? errors.whiteMilkSchedule_wednesday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='whiteMilk_thursday'
                  value={whiteMilk_thursday}
                  customInput={TextField}
                  label='Thursday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.whiteMilkSchedule_thursday
                      ? errors.whiteMilkSchedule_thursday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='whiteMilk_friday'
                  value={whiteMilk_friday}
                  customInput={TextField}
                  label='Friday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.whiteMilkSchedule_friday
                      ? errors.whiteMilkSchedule_friday
                      : errors.message
                  }
                />
              </div>

              <div className='col'>
                <FormSeparator text='Chocolate milk schedule' />
                <NumberFormat
                  name='chocolateMilk_monday'
                  value={chocolateMilk_monday}
                  customInput={TextField}
                  label='Monday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.chocolateMilkSchedule_monday
                      ? errors.chocolateMilkSchedule_monday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='chocolateMilk_tuesday'
                  value={chocolateMilk_tuesday}
                  customInput={TextField}
                  label='Tuesday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.chocolateMilkSchedule_tuesday
                      ? errors.chocolateMilkSchedule_tuesday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='chocolateMilk_wednesday'
                  value={chocolateMilk_wednesday}
                  customInput={TextField}
                  label='Wednesday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.chocolateMilkSchedule_wednesday
                      ? errors.chocolateMilkSchedule_wednesday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='chocolateMilk_thursday'
                  value={chocolateMilk_thursday}
                  customInput={TextField}
                  label='Thursday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.chocolateMilkSchedule_thursday
                      ? errors.chocolateMilkSchedule_thursday
                      : errors.message
                  }
                />
                <NumberFormat
                  name='chocolateMilk_friday'
                  value={chocolateMilk_friday}
                  customInput={TextField}
                  label='Friday'
                  format='#'
                  orientation='vertical'
                  allowNegative={false}
                  onChange={this.handleChange}
                  error={
                    errors.chocolateMilkSchedule_friday
                      ? errors.chocolateMilkSchedule_friday
                      : errors.message
                  }
                />
              </div>
            </div>
          );
        case 'CANCELLED':
          return null;
        case 'PAUSED':
          return (
            <DatePicker
              name='pausedUntil'
              label={<Translate id='orders.pausedUntil' />}
              value={new Date(pausedUntil)}
              orientation='horizontal'
              onChange={this.handlePausedUntil}
              minDate={new Date()}
            />
          );
        default:
          return null;
      }
    } else {
      return (
        <React.Fragment>
          <p className='text-danger'>
            <strong>
              This school does not accept online orders. Please contact the
              school coordinator for more info.
            </strong>
          </p>
          <DummyField
            label='Coordinator name'
            value={school.coordinator_name}
            orientation='horizontal'
          />
          <DummyField
            label='Coordinator email'
            value={school.coordinator_email}
            orientation='horizontal'
          />
          <DummyField
            label='Coordinator phone'
            value={school.coordinator_phone}
            orientation='horizontal'
          />
        </React.Fragment>
      );
    }
  };

  handleSelectSchool = school => {
    this.setState({ school });
  };

  loadSchoolOptions = inputValue =>
    new Promise((resolve, reject) => {
      const payload = {
        name: inputValue
      };
      const url = '/api/schools/autocomplete';
      if (inputValue.length >= 3)
        axios
          .get(url, { params: payload })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
    });

  render() {
    const {
      errors,
      disableEdit,
      loading,
      childName,
      createdAt,
      status,
      school,

      whiteMilk_monday,
      whiteMilk_tuesday,
      whiteMilk_wednesday,
      whiteMilk_thursday,
      whiteMilk_friday,
      chocolateMilk_monday,
      chocolateMilk_tuesday,
      chocolateMilk_wednesday,
      chocolateMilk_thursday,
      chocolateMilk_friday
    } = this.state;

    const statusOptions = [
      { label: 'ACTIVE', value: 'ACTIVE' },
      { label: 'PAUSED', value: 'PAUSED' },
      { label: 'CANCELLED', value: 'CANCELLED' }
    ];

    const { white_milk_cost, chocolate_milk_cost } = this.state.school;

    const isParent = this.props.auth.user.role === 'PARENT' ? true : false;

    const whiteMilkSchedule = {
      monday: whiteMilk_monday,
      tuesday: whiteMilk_tuesday,
      wednesday: whiteMilk_wednesday,
      thursday: whiteMilk_thursday,
      friday: whiteMilk_friday
    };

    const chocolateMilkSchedule = {
      monday: chocolateMilk_monday,
      tuesday: chocolateMilk_tuesday,
      wednesday: chocolateMilk_wednesday,
      thursday: chocolateMilk_thursday,
      friday: chocolateMilk_friday
    };

    let message = null;
    if (white_milk_cost === null || chocolate_milk_cost === null) {
      message = (
        <p className='text-secondary'>
          This school didn't set its milk prices so we cannot give you a price
          estimate.
        </p>
      );
    }
    return (
      <div className='p-3'>
        <TextField
          name='childName'
          placeholder='Child name'
          value={childName}
          maxLength={100}
          label={<Translate id='order.childName' />}
          orientation='horizontal'
          onChange={this.handleChange}
          error={errors.child_name ? errors.child_name : errors.message}
          disabled={disableEdit}
        />
        {isParent ? (
          <AsyncSelect
            name='school'
            label='School'
            placeholder='Search for a School'
            value={school}
            orientation='horizontal'
            loadOptions={this.loadSchoolOptions}
            onChange={this.handleSelectSchool}
            disabled={!isParent}
            isClearable={false}
          />
        ) : null}
        <DatePicker
          name='createdAt'
          label={<Translate id='orders.createdAt' />}
          value={new Date(createdAt)}
          placeholder='Created on'
          orientation='horizontal'
          onChange={this.handleCreatedAt}
          disabled={true}
        />
        <Select
          name='status'
          label={<Translate id='orders.status' />}
          value={status}
          orientation='horizontal'
          disabled={disableEdit}
          options={statusOptions}
          onChange={this.handleStatusSelect}
          error={errors.status ? errors.status : errors.message}
        />

        {this.renderMilkSchedule(status.value)}

        {message}
        <OrderPrice
          text='Estimated cost:'
          whiteMilkPrice={parseFloat(this.props.order.school.white_milk_price)}
          chocolateMilkPrice={parseFloat(
            this.props.order.school.chocolate_milk_price
          )}
          whiteMilkSchedule={whiteMilkSchedule}
          chocolateMilkSchedule={chocolateMilkSchedule}
        />

        <div className='d-flex justify-content-between align-items-center'>
          <Button
            text={<Translate id='button.cancel' />}
            className='btn btn-secondary'
            onClick={this.props.closeModal}
          />
          <Button
            text={<Translate id='button.save' />}
            className='btn btn-primary'
            onClick={this.handleSubmit}
            isLoading={loading}
            disabled={!school.accepts_orders}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  getSchoolOrders: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  { getSchoolOrders, getParentOrders }
)(withRouter(OrderDetails));
