import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import { Translate } from 'react-localize-redux';

export class DistributorSearchForm extends Component {
  render() {
    return (
      <form className='mb-3 mt-3'>
        <div className='form-row'>
          <div className='col-lg-5 col-12 mb-1'>
            <input
              type='text'
              name='name'
              className='form-control form-control-sm'
              placeholder='Name'
              onChange={this.props.handleChange}
            />
          </div>
          <div className='col-lg-1 col-12 text-right mb-1'>
            <Button
              text={<Translate id='button.search' />}
              isLoading={this.props.loading}
              className='btn btn-sm btn-primary'
              onClick={this.props.handleSearch}
            />
          </div>
        </div>
      </form>
    );
  }
}

DistributorSearchForm.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DistributorSearchForm;
