import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { routes } from './routes';
import { isEmpty } from '../../utils';

export class Breadcrumbs extends Component {
  constructor() {
    super();
    this.state = {
      prevPath: {},
      currPath: {},
      crumbs: []
    };
  }

  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location.pathname.replace(/[/0-9]/g, '');
    const currLocation = this.props.location.pathname.replace(/[/0-9]/g, '');

    if (prevLocation !== currLocation) {
      routes.map(val => {
        if (prevLocation === val.path.replace(/[/0-9]/g, '')) {
          this.setState({ prevPath: { path: val.path, name: val.name } });
        }
        if (currLocation === val.path.replace(/[/0-9]/g, '')) {
          this.setState({ currPath: { path: val.path, name: val.name } });
        }
        return null;
      });
    }
  }

  render() {
    const { isAuthenticated, role } = this.props;
    const { prevPath } = this.state;
    if (
      !isEmpty(prevPath) &&
      prevPath.path !== this.props.location.pathname &&
      isAuthenticated &&
      (role === 'ADMIN' || role === 'SUPER')
    ) {
      return (
        <Link className='btn btn-link' to={prevPath.path}>
          Back to {prevPath.name}
        </Link>
      );
    } else return null;
  }
}

Breadcrumbs.propTypes = {
  location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.user.role
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Breadcrumbs)
);
