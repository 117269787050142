import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getParentOrders,
  clearOrders
} from '../../redux/actions/schoolActions';
import { Translate } from 'react-localize-redux';
import Button from '../common/Button';
import ParentOrderList from './ParentOrderList';
import { Modal } from 'react-bootstrap';
import CreateOrder from './CreateOrder';

export class ParentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
      showModal: false
    };
  }

  componentDidMount() {
    document.title = 'Parent Dashboard - Dairy Farmers of Ontario ESMP Portal';
    this.props.getParentOrders();
  }
  componentWillUnmount() {
    this.props.clearOrders();
  }

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
    this.props.getParentOrders();
  };

  render() {
    const { orders, loading } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <h1>
            <Translate id='dashboard.title' />
          </h1>
          <Button
            text={<Translate id='button.createOrder' />}
            className='btn btn-primary'
            onClick={this.showModal}
          />
        </div>
        <p>
          <Translate id='dashboard.welcome' />
        </p>

        <ParentOrderList orders={orders} loading={loading} />

        <Modal centered size='lg' show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create a new order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateOrder closeModal={this.hideModal} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

ParentDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  orders: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  orders: state.school.orders,
  errors: state.errors,
  loading: state.school.loading
});

export default connect(
  mapStateToProps,
  { getParentOrders, clearOrders }
)(ParentDashboard);
