import React from "react";

function ScoMigration() {
  return (
    <div className="container">
      <h2>Migration to SchoolCashOnline</h2>

      <p className="mt-3 mb-3">Welcome to the DFO ESMP Portal!</p>

      <p>
        We are migrating to a new system called SchoolCashOnline for milk
        orders. Click the button below to be taken there.
      </p>

      <div className="text-center p-3">
        <a href="https://www.schoolcashonline.com/" className="btn btn-primary">
          Take me to SchoolCashOnline
        </a>
      </div>
    </div>
  );
}

ScoMigration.propTypes = {};

export default ScoMigration;
