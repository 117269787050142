export const regDeclineOptions = [
  { value: 'Allergies', label: 'Allergies' },
  {
    value: 'Been approached too many times',
    label: 'Been approached too many times'
  },
  {
    value: 'Breakfast Program only/Not offered in school hours',
    label: 'Breakfast Program only/Not offered in school hours'
  },
  { value: 'Cafeteria', label: 'Cafeteria' },
  {
    value: 'Issues with Dairy in the past',
    label: 'Issues with Dairy in the past'
  },
  { value: 'Lost money in the past', label: 'Lost money in the past' },
  { value: 'Low Income', label: 'Low Income' },
  {
    value: 'Milk Infrequent/Not Eligible',
    label: 'Milk Infrequent/Not Eligible'
  },
  {
    value: 'Milk Not Provided Separately',
    label: 'Milk Not Provided Separately'
  },
  { value: 'Milk to go Vending', label: 'Milk to go Vending' },
  {
    value: 'Need more info/help to run program',
    label: 'Need more info/help to run program'
  },
  { value: 'No Fridge', label: 'No Fridge' },
  {
    value: 'No one to run the program/Too Much Work',
    label: 'No one to run the program/Too Much Work'
  },
  {
    value: 'No room for fridge/school too small',
    label: 'No room for fridge/school too small'
  },
  { value: 'Not at all interested', label: 'Not at all interested' },
  { value: 'Not enough demand for milk', label: 'Not enough demand for milk' },
  { value: 'Not selling beverages', label: 'Not selling beverages' },
  {
    value: 'Not selling milk, selling other',
    label: 'Not selling milk, selling other'
  },
  { value: 'Other', label: 'Other' },
  { value: 'Pop & Juice Vending', label: 'Pop & Juice Vending' },
  { value: 'Population too small', label: 'Population too small' },
  { value: 'Schools Closed', label: 'Schools Closed' }
];

export const regionOptions = [
  { value: 'Algoma', label: 'Algoma' },
  { value: 'Brant', label: 'Brant' },
  { value: 'Bruce', label: 'Bruce' },
  { value: 'Carleton', label: 'Carleton' },
  { value: 'City of Kawartha Lakes', label: 'City of Kawartha Lakes' },
  { value: 'Cochrane', label: 'Cochrane' },
  { value: 'Dufferin/Wellington', label: 'Dufferin/Wellington' },
  { value: 'Dundas', label: 'Dundas' },
  { value: 'Durham', label: 'Durham' },
  {
    value: 'East Nipissing Parry Sound',
    label: 'East Nipissing Parry Sound'
  },
  {
    value: 'East Sudbury West Nipissing',
    label: 'East Sudbury West Nipissing'
  },
  { value: 'Elgin Middlesex', label: 'Elgin Middlesex' },
  { value: 'Essex', label: 'Essex' },
  { value: 'Frontenac', label: 'Frontenac' },
  { value: 'Glengarry', label: 'Glengarry' },
  { value: 'Grenville', label: 'Grenville' },
  { value: 'Grey', label: 'Grey' },
  { value: 'Haldimand', label: 'Haldimand' },
  { value: 'Haliburton/Muskoka', label: 'Haliburton/Muskoka' },
  { value: 'Halton', label: 'Halton' },
  { value: 'Hastings', label: 'Hastings' },
  { value: 'Huron', label: 'Huron' },
  { value: 'Kent', label: 'Kent' },
  { value: 'Lambton', label: 'Lambton' },
  { value: 'Lanark', label: 'Lanark' },
  { value: 'Leeds', label: 'Leeds' },
  { value: 'Lennox & Addington', label: 'Lennox & Addington' },
  { value: 'Manitoulin West Sudbury', label: 'Manitoulin West Sudbury' },
  { value: 'Niagara', label: 'Niagara' },
  { value: 'Norfolk', label: 'Norfolk' },
  { value: 'Northumberland', label: 'Northumberland' },
  { value: 'Oxford', label: 'Oxford' },
  { value: 'Peel', label: 'Peel' },
  { value: 'Perth', label: 'Perth' },
  { value: 'Peterborough', label: 'Peterborough' },
  { value: 'Prescott', label: 'Prescott' },
  { value: 'Prince Edward', label: 'Prince Edward' },
  { value: 'Rainy River', label: 'Rainy River' },
  { value: 'Renfrew', label: 'Renfrew' },
  { value: 'Russell', label: 'Russell' },
  { value: 'Simcoe', label: 'Simcoe' },
  { value: 'Stormont', label: 'Stormont' },
  { value: 'Temiskaming', label: 'Temiskaming' },
  { value: 'Thunder Bay', label: 'Thunder Bay' },
  { value: 'Toronto', label: 'Toronto' },
  { value: 'Waterloo', label: 'Waterloo' },
  { value: 'Wentworth', label: 'Wentworth' },
  { value: 'York', label: 'York' }
];

export const provinceOptions = [
  { value: 'AB', label: 'Alberta (AB)' },
  { value: 'BC', label: 'British Columbia (BC)' },
  { value: 'MB', label: 'Manitoba (MB)' },
  { value: 'NB', label: 'New Brunswick (NB)' },
  { value: 'NL', label: 'Newfoundland and Labrador (NL)' },
  { value: 'NT', label: 'Northwest Territories (NT)' },
  { value: 'NS', label: 'Nova Scotia (NS)' },
  { value: 'NU', label: 'Nunavut (NU)' },
  { value: 'ON', label: 'Ontario (ON)' },
  { value: 'PE', label: 'Prince Edward Island (PE)' },
  { value: 'QC', label: 'Quebec (QC)' },
  { value: 'SK', label: 'Saskatchewan (SK)' },
  { value: 'YT', label: 'Yukon (YT)' }
];
export const schoolTypeOptions = [
  { value: 'SEP', label: 'Separate' },
  { value: 'PUB', label: 'Public' },
  { value: 'PRV', label: 'Private' },
  { value: 'ADT', label: 'Adult' },
  { value: 'SPC', label: 'Specialized' }
];
export const languagesOptions = [
  { value: 'E', label: 'English' },
  { value: 'F', label: 'French' },
  { value: 'B', label: 'Both' }
];
export const prefLanguageOptions = [
  { value: 'E', label: 'English' },
  { value: 'F', label: 'French' }
];
export const gradesOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: 'K', label: 'K' },
  { value: 'JK', label: 'JK' },
  { value: 'M', label: 'M' },
  { value: 'P', label: 'P' },
  { value: 'PK', label: 'PK' },
  { value: 'SK', label: 'SK' }
];
export const registrationMethodOptions = [
  { value: 'Online', label: 'Online' },
  { value: 'Fax', label: 'Fax' },
  { value: 'Phone', label: 'Phone' }
];

export const completedByOptions = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Self Registered', label: 'Self Registered' }
];
