import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TextField from "../inputs/TextField";
import AsyncSelect from "../inputs/AsyncSelect";
import Button from "../common/Button";
import NumberFormat from "react-number-format";
import { Translate } from "react-localize-redux";
import FormSeparator from "../inputs/FormSeparator";
import DummyField from "../inputs/DummyField";
import OrderPrice from "../school/OrderPrice";

export class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childName: "",
      school: {
        label: "",
        value: "",
        accepts_offers: "",
        white_milk_price: 0,
        chocolate_milk_price: 0,
      },

      whiteMilk_monday: 0,
      whiteMilk_tuesday: 0,
      whiteMilk_wednesday: 0,
      whiteMilk_thursday: 0,
      whiteMilk_friday: 0,

      chocolateMilk_monday: 0,
      chocolateMilk_tuesday: 0,
      chocolateMilk_wednesday: 0,
      chocolateMilk_thursday: 0,
      chocolateMilk_friday: 0,
      loading: false,
      errors: {},
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectSchool = (school) => {
    this.setState({ school });
  };
  loadSchoolOptions = (inputValue) =>
    new Promise((resolve, reject) => {
      const payload = {
        name: inputValue,
      };
      const url = "/api/schools/autocomplete";
      if (inputValue.length >= 3)
        axios
          .get(url, { params: payload })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
    });

  renderMilkSchedule = () => {
    const {
      whiteMilk_monday,
      whiteMilk_tuesday,
      whiteMilk_wednesday,
      whiteMilk_thursday,
      whiteMilk_friday,
      chocolateMilk_monday,
      chocolateMilk_tuesday,
      chocolateMilk_wednesday,
      chocolateMilk_thursday,
      chocolateMilk_friday,
      school,
      errors,
    } = this.state;

    if (school.value !== "") {
      if (school.accepts_orders) {
        return (
          <div className="row">
            <div className="col">
              <FormSeparator text="White milk schedule" />
              <NumberFormat
                name="whiteMilk_monday"
                value={whiteMilk_monday}
                customInput={TextField}
                thousandSeparator
                label="Monday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.whiteMilkSchedule_monday
                    ? errors.whiteMilkSchedule_monday
                    : errors.message
                }
              />
              <NumberFormat
                name="whiteMilk_tuesday"
                value={whiteMilk_tuesday}
                customInput={TextField}
                thousandSeparator
                label="Tuesday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.whiteMilkSchedule_tuesday
                    ? errors.whiteMilkSchedule_tuesday
                    : errors.message
                }
              />
              <NumberFormat
                name="whiteMilk_wednesday"
                value={whiteMilk_wednesday}
                customInput={TextField}
                thousandSeparator
                label="Wednesday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.whiteMilkSchedule_wednesday
                    ? errors.whiteMilkSchedule_wednesday
                    : errors.message
                }
              />
              <NumberFormat
                name="whiteMilk_thursday"
                value={whiteMilk_thursday}
                customInput={TextField}
                thousandSeparator
                label="Thursday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.whiteMilkSchedule_thursday
                    ? errors.whiteMilkSchedule_thursday
                    : errors.message
                }
              />
              <NumberFormat
                name="whiteMilk_friday"
                value={whiteMilk_friday}
                customInput={TextField}
                thousandSeparator
                label="Friday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.whiteMilkSchedule_friday
                    ? errors.whiteMilkSchedule_friday
                    : errors.message
                }
              />
            </div>

            <div className="col">
              <FormSeparator text="Chocolate milk schedule" />
              <NumberFormat
                name="chocolateMilk_monday"
                value={chocolateMilk_monday}
                customInput={TextField}
                thousandSeparator
                label="Monday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.chocolateMilkSchedule_monday
                    ? errors.chocolateMilkSchedule_monday
                    : errors.message
                }
              />
              <NumberFormat
                name="chocolateMilk_tuesday"
                value={chocolateMilk_tuesday}
                customInput={TextField}
                thousandSeparator
                label="Tuesday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.chocolateMilkSchedule_tuesday
                    ? errors.chocolateMilkSchedule_tuesday
                    : errors.message
                }
              />
              <NumberFormat
                name="chocolateMilk_wednesday"
                value={chocolateMilk_wednesday}
                customInput={TextField}
                thousandSeparator
                label="Wednesday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.chocolateMilkSchedule_wednesday
                    ? errors.chocolateMilkSchedule_wednesday
                    : errors.message
                }
              />
              <NumberFormat
                name="chocolateMilk_thursday"
                value={chocolateMilk_thursday}
                customInput={TextField}
                thousandSeparator
                label="Thursday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.chocolateMilkSchedule_thursday
                    ? errors.chocolateMilkSchedule_thursday
                    : errors.message
                }
              />
              <NumberFormat
                name="chocolateMilk_friday"
                value={chocolateMilk_friday}
                customInput={TextField}
                thousandSeparator
                label="Friday"
                format="#"
                orientation="vertical"
                onChange={this.handleChange}
                error={
                  errors.chocolateMilkSchedule_friday
                    ? errors.chocolateMilkSchedule_friday
                    : errors.message
                }
              />
            </div>
          </div>
        );
      }
      if (!school.accepts_offers) {
        return (
          <React.Fragment>
            <p className="text-danger">
              <strong>
                This school does not accept online orders. Please contact the
                school coordinator for more info.
              </strong>
            </p>
            <DummyField
              label="Coordinator name"
              value={school.coordinator_name}
              orientation="horizontal"
            />
            <DummyField
              label="Coordinator email"
              value={school.coordinator_email}
              orientation="horizontal"
            />
            <DummyField
              label="Coordinator phone"
              value={school.coordinator_phone}
              orientation="horizontal"
            />
          </React.Fragment>
        );
      }
    } else return null;
  };

  handleSubmit = () => {
    const {
      whiteMilk_monday,
      whiteMilk_tuesday,
      whiteMilk_wednesday,
      whiteMilk_thursday,
      whiteMilk_friday,
      chocolateMilk_monday,
      chocolateMilk_tuesday,
      chocolateMilk_wednesday,
      chocolateMilk_thursday,
      chocolateMilk_friday,
      childName,
      school,
    } = this.state;

    this.setState({ loading: true });

    const url = `/api/orders/`;

    let payload = {
      child_name: childName,
      school_id: school.value,
      whiteMilkSchedule: {
        monday: whiteMilk_monday,
        tuesday: whiteMilk_tuesday,
        wednesday: whiteMilk_wednesday,
        thursday: whiteMilk_thursday,
        friday: whiteMilk_friday,
      },
      chocolateMilkSchedule: {
        monday: chocolateMilk_monday,
        tuesday: chocolateMilk_tuesday,
        wednesday: chocolateMilk_wednesday,
        thursday: chocolateMilk_thursday,
        friday: chocolateMilk_friday,
      },
    };

    axios
      .post(url, payload)
      .then((res) => {
        this.props.closeModal();
      })
      .catch((err) => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  render() {
    const {
      school,
      childName,
      errors,
      whiteMilk_monday,
      whiteMilk_tuesday,
      whiteMilk_wednesday,
      whiteMilk_thursday,
      whiteMilk_friday,
      chocolateMilk_monday,
      chocolateMilk_tuesday,
      chocolateMilk_wednesday,
      chocolateMilk_thursday,
      chocolateMilk_friday,
    } = this.state;

    const { white_milk_price, chocolate_milk_price } = this.state.school;

    const { loading } = this.props;

    const whiteMilkSchedule = {
      monday: whiteMilk_monday,
      tuesday: whiteMilk_tuesday,
      wednesday: whiteMilk_wednesday,
      thursday: whiteMilk_thursday,
      friday: whiteMilk_friday,
    };

    const chocolateMilkSchedule = {
      monday: chocolateMilk_monday,
      tuesday: chocolateMilk_tuesday,
      wednesday: chocolateMilk_wednesday,
      thursday: chocolateMilk_thursday,
      friday: chocolateMilk_friday,
    };

    let message = null;
    if (white_milk_price === null || chocolate_milk_price === null) {
      message = (
        <p className="text-secondary">
          This school didn't set its milk prices so we cannot give you a price
          estimate.
        </p>
      );
    }

    if (school.uses_SCO) {
      return (
        <React.Fragment>
          <h2>Migration to SchoolCashOnline</h2>

          <p className="mt-3 mb-3">Welcome to the DFO ESMP Portal!</p>

          <p>
            We are migrating to a new system called SchoolCashOnline for milk
            orders. Click the button below to be taken there.
          </p>

          <div className="text-center p-3">
            <a
              href="https://www.schoolcashonline.com/"
              className="btn btn-primary"
            >
              Take me to SchoolCashOnline
            </a>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="p-3">
        <TextField
          name="childName"
          placeholder="Child name"
          value={childName}
          maxLength={100}
          label={<Translate id="order.childName" />}
          orientation="horizontal"
          onChange={this.handleChange}
          error={errors.child_name ? errors.child_name : errors.message}
        />
        <AsyncSelect
          name="school"
          label="School"
          placeholder="Search for a School"
          value={school}
          orientation="horizontal"
          loadOptions={this.loadSchoolOptions}
          onChange={this.handleSelectSchool}
          isClearable={false}
        />

        {this.renderMilkSchedule()}

        {message}

        <OrderPrice
          text="Estimated cost:"
          whiteMilkPrice={parseFloat(this.state.school.white_milk_price)}
          chocolateMilkPrice={parseFloat(
            this.state.school.chocolate_milk_price
          )}
          whiteMilkSchedule={whiteMilkSchedule}
          chocolateMilkSchedule={chocolateMilkSchedule}
        />

        <div className="d-flex justify-content-between align-items-center mt-3">
          <Button
            text={<Translate id="button.cancel" />}
            className="btn btn-secondary"
            onClick={this.props.closeModal}
          />
          <Button
            text={<Translate id="button.save" />}
            className="btn btn-primary"
            onClick={this.handleSubmit}
            isLoading={loading}
            disabled={!school.accepts_orders}
          />
        </div>
      </div>
    );
  }
}

CreateOrder.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CreateOrder;
