import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../redux/actions/authActions';
import { Translate } from 'react-localize-redux';
import TextField from '../inputs/TextField';
import Button from '../common/Button';
import { isEmpty } from '../../utils';

class DistClaimProfile extends Component {
  constructor() {
    super();
    this.state = {
      distCode: '',
      loading: false,
      errors: {}
    };
  }

  componentDidMount() {
    if (!isEmpty(this.props.auth.user.distributor))
      this.props.history.push('/distributor/dashboard');
  }

  componentDidUpdate() {
    if (!isEmpty(this.props.auth.user.distributor))
      this.props.history.push('/distributor/dashboard');
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { distCode } = this.state;
    const url = `/api/distributors/claim/${distCode}`;

    axios
      .post(url)
      .then(res => {
        this.props.getCurrentUser();
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  render() {
    const { distCode, loading, errors } = this.state;
    return (
      <div>
        <h1>
          <Translate id='dist.claimh1' />
        </h1>
        <div className='form-container'>
          <TextField
            name='distCode'
            value={distCode.toUpperCase()}
            label={<Translate id='dist.distCode' />}
            orientation='horizontal'
            maxLength={11}
            onChange={this.handleChange}
            error={errors.message}
          />
          <div className='text-right'>
            <Button
              text={<Translate id='button.continue' />}
              onClick={this.handleSubmit}
              className='btn btn-primary'
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

DistClaimProfile.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentUser }
)(DistClaimProfile);
