import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DashboardNavThumb from "../layout/DashboardNavThumb";
import { Translate } from "react-localize-redux";
import { isEmpty } from "../../utils";

export class Dashboard extends Component {
  componentDidMount() {
    document.title = "User Dashboard - Dairy Farmers of Ontario ESMP Portal";
  }

  render() {
    if (!isEmpty(this.props.auth.user.entity)) {
      return (
        <div>
          <h1>
            <Translate id="dashboard.title" />
          </h1>
          <p>
            <Translate id="dashboard.welcome" />
          </p>
          <p>
            <Translate id="dashboard.hint" />
          </p>
          <div className="dashboradNavThumb-container">
            <DashboardNavThumb
              text={<Translate id="dashboard.mySchools" />}
              link={`/dashboard/my-schools`}
            />
            <DashboardNavThumb
              text={<Translate id="dashboard.profile" />}
              link="/dashboard/profile"
            />
            <DashboardNavThumb
              text={<Translate id="dashboard.dairyEducatorForm" />}
              link="/dashboard/request-dairy-educator"
            />
          </div>
        </div>
      );
    }
    if (isEmpty(this.props.auth.user.entity)) {
      return <Redirect to="/dashboard/claim-school" />;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(Dashboard);
