import React, { Component } from 'react';
import HeaderTop from './HeaderTop';
import HeaderBottom from './HeaderBottom';

export class Navbar extends Component {
  render() {
    return (
      <div id='navbar'>
        <HeaderTop />
        <HeaderBottom />
      </div>
    );
  }
}

export default Navbar;
