import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import DummyField from './DummyField';

const Select = ({
  name,
  placeholder,
  value,
  options,
  label,
  error,
  type,
  onChange,
  helperText,
  isClearable,
  orientation, // horizontal || vertical(default)
  required,
  disabled
}) => {
  if (disabled) {
    const val = value ? value.label : '';
    return <DummyField value={val} label={label} orientation={orientation} />;
  } else {
    if (orientation === 'horizontal') {
      return (
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label' htmlFor={name}>
            {label}
            {required ? '*' : null}
          </label>
          <div className='col-sm-8'>
            <ReactSelect
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              options={options}
              isDisabled={disabled}
              onChange={onChange}
              isClearable={isClearable}
            />
            {helperText && (
              <small className='form-text text-muted'>{helperText}</small>
            )}
            {error && <small className='text-danger'>{error}</small>}
          </div>
        </div>
      );
    } else {
      return (
        <div className='form-group'>
          <label htmlFor={name}>
            {label}
            {required ? '*' : null}
          </label>

          <ReactSelect
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            options={options}
            isDisabled={disabled}
            onChange={onChange}
            isClearable={isClearable}
          />
          {helperText && (
            <small className='form-text text-muted'>{helperText}</small>
          )}
          {error && <small className='text-danger'>{error}</small>}
        </div>
      );
    }
  }
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired
};

Select.defaultProps = {
  type: 'text',
  orientation: 'vertical',
  isClearable: false,
  required: false
};

export default Select;
