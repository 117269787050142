import React from "react";
import { Translate } from "react-localize-redux";

export default function UserSchoolListHeader() {
  return (
    <div className="row table-header">
      <div className="col-md-3 col-10 text-truncate">
        <Translate id="schoolList.schoolName" />
      </div>
      <div className="col-sm-2 text-truncate d-none d-md-block">
        <Translate id="schoolList.year" />
      </div>
      <div className="col-sm-2 text-truncate d-none d-md-block">
        <Translate id="schoolList.city" />
      </div>
      <div className="col-sm-2 text-truncate d-none d-md-block">
        <Translate id="schoolList.phone" />
      </div>
      <div className="col-sm-2 text-truncate d-none d-md-block">
        <Translate id="schoolList.isRegistered" />
      </div>
      <div className="col-sm-1 col-2 text-right">
        {/* <Translate id='schoolList.isRegistered' /> */}
      </div>
    </div>
  );
}
