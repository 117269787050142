import {
  SET_DIST_LOADING,
  GET_DISTRIBUTOR,
  GET_DISTRIBUTOR_ORDERS,
  CLEAR_DISTRIBUTOR
} from '../actions/types';

const initialState = {
  profile: {},
  orders: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DISTRIBUTOR:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    case GET_DISTRIBUTOR_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case CLEAR_DISTRIBUTOR:
      return {
        ...state,
        profile: {}
      };
    case SET_DIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
