import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import DistOrderDetails from './DistOrderDetails';
import OrderPrice from '../school/OrderPrice';

export class DistOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { order } = this.props;
    const { showModal } = this.state;

    let orderStatus;
    switch (order.status) {
      case 'ACTIVE':
        orderStatus = <span className='text-success'>{order.status}</span>;
        break;
      case 'PAUSED':
        orderStatus = <span className='text-secondary'>{order.status}</span>;
        break;
      case 'CANCELLED':
        orderStatus = <span className='text-danger'>{order.status}</span>;
        break;
      default:
        orderStatus = '';
    }

    return (
      <li className='list-group-item'>
        <div className='row align-items-center'>
          <div className='col-md-1 col-2 '>
            <span onClick={this.showModal} className='order-id cp'>
              {order.id}
            </span>
          </div>
          <div className='col-md-3 col-8 text-truncate'>
            <span onClick={this.showModal} className='order-child-name cp'>
              {order.child_name}
            </span>
          </div>
          <div className='col-md-1 d-none d-md-block'>
            <OrderPrice
              whiteMilkPrice={parseFloat(order.school.white_milk_price)}
              chocolateMilkPrice={parseFloat(order.school.chocolate_milk_price)}
              whiteMilkSchedule={order.whiteMilkSchedule}
              chocolateMilkSchedule={order.chocolateMilkSchedule}
            />
          </div>
          <div className='col-sm-4 text-truncate d-none d-md-block'>
            {order.school.label}
          </div>

          <div className='col-sm-2 d-none d-md-block'>{orderStatus}</div>
          <div className='col-sm-1 col-2 text-right'>
            <i onClick={this.showModal} className='fas fa-ellipsis-v cp' />
          </div>
        </div>

        <Modal centered size='lg' show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Order details{' '}
              <div className='orderDetails-subheader'>
                <p>Order id: {order.id}</p>
                <p>Parent: {order.parentName}</p>
                <p>Email: {order.parentEmail}</p>
                <p>
                  Updated on: {moment(order.updatedAt).format('MMM DD, YYYY')}
                </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DistOrderDetails
              order={this.props.order}
              closeModal={this.hideModal}
            />
          </Modal.Body>
        </Modal>
      </li>
    );
  }
}

DistOrder.propTypes = {
  order: PropTypes.object.isRequired
};

export default DistOrder;
