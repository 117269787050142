import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../redux/actions/authActions';
import Button from '../common/Button';
import TextField from '../inputs/TextField';
import { Translate } from 'react-localize-redux';
import axios from 'axios';

export class ClaimSchool extends Component {
  constructor() {
    super();
    this.state = {
      schoolCode: '',
      loading: false,
      errors: ''
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const { schoolCode } = this.state;
    this.setState({ loading: true });
    const url = `/api/schools/claim/${schoolCode}`;
    axios
      .post(url)
      .then(res => {
        this.props.getCurrentUser();
        this.props.history.push(`/dashboard/school/${res.data.id}`);
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  render() {
    const { schoolCode, loading, errors } = this.state;
    return (
      <div className='form-container'>
        <h1>
          <Translate id='claimSchool.title' />
        </h1>
        <p>
          <Translate id='registerSchool.hint' />
        </p>
        <TextField
          name='schoolCode'
          value={schoolCode.toUpperCase()}
          label={<Translate id='registerSchool.schoolCode' />}
          orientation='horizontal'
          maxLength={11}
          onChange={this.handleChange}
          error={errors.message}
        />
        <div className='text-right'>
          <Button
            text={<Translate id='button.continue' />}
            onClick={this.handleSubmit}
            className='btn btn-primary'
            isLoading={loading}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  school: state.auth.user.school
});

export default connect(
  mapStateToProps,
  { getCurrentUser }
)(ClaimSchool);
