import React from 'react';
import PropTypes from 'prop-types';
import OrdersHeader from './OrdersHeader';
import Spinner from '../common/Spinner';
import Order from './Order';

const OrderList = ({ orders, loading }) => {
  let orderList;
  if (orders) {
    orderList = orders.map(order => {
      return <Order key={order.id} order={order} />;
    });
  } else {
    orderList = <p className='m-3'>Looks like there's nothing here.</p>;
  }

  return (
    <div className='order-list-container'>
      <OrdersHeader />
      {loading ? <Spinner /> : <ul className='list-group'>{orderList}</ul>}
    </div>
  );
};

OrderList.propTypes = {
  orders: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

export default OrderList;
