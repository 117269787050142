import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import DistProfile from '../distributor/DistProfile';

export class Distributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { distributor } = this.props;
    const { showModal } = this.state;

    return (
      <li className='list-group-item'>
        <div className='row align-items-center'>
          <div className='col-md-2 col-2 '>
            <span onClick={this.showModal} className='order-id cp'>
              {distributor.id}
            </span>
          </div>
          <div className='col-md-4 col-8 text-truncate'>
            <span onClick={this.showModal} className='order-child-name cp'>
              {distributor.name}
            </span>
          </div>
          <div className='col-md-2 col-4 d-none d-md-block text-truncate'>
            {distributor.province}
          </div>
          <div className='col-md-3 col-4 d-none d-md-block text-truncate'>
            {distributor.phone}
          </div>
          <div className='col-sm-1 col-2 text-right'>
            <i onClick={this.showModal} className='fas fa-ellipsis-v cp' />
          </div>
        </div>

        <Modal centered size='lg' show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Distributor details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DistProfile
              distributorId={distributor.id}
              page={this.props.page}
            />
          </Modal.Body>
        </Modal>
      </li>
    );
  }
}

Distributor.propTypes = {
  distributor: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired
};

export default Distributor;
