import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDistributorOrders } from '../../redux/actions/distributorActions';
import PropTypes from 'prop-types';
import Pagination from '../common/Pagination';
import _ from 'lodash';
import DistOrderList from './DistOrderList';
import DistOrderSearch from './DistOrderSearch';
import Button from '../common/Button';
import { Translate } from 'react-localize-redux';
import axios from 'axios';
import moment from 'moment';

export class DistOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      childName: '',
      orderId: '',
      status: '', // Can be active, paused, cancelled and all
      school: '',
      loadingExport: false,
      schools: [],
      errors: {}
    };
  }

  componentDidMount() {
    this.props.getDistributorOrders(
      this.props.auth.user.distributor.id,
      this.state.page
    );

    // Get distributors schools
    const url = '/api/distributors/mySchools';
    axios
      .get(url)
      .then(res => {
        this.setState({ schools: res.data });
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  loadPage = page => {
    this.props.getDistributorOrders(
      this.props.auth.user.distributor.id,
      page,
      this.state.orderId,
      this.state.childName,
      this.state.status,
      this.state.school
    );
    this.setState({ page: page });
  };

  handleSearch = e => {
    e.preventDefault();
    this.props.getDistributorOrders(
      this.props.auth.user.distributor.id,
      1,
      this.state.orderId,
      this.state.childName,
      this.state.status,
      this.state.school
    );
  };

  handleExport = () => {
    const { childName, status, school } = this.state;
    const id = this.props.auth.user.distributor.id;
    const url = `/api/distributors/${id}/orders`;
    const payload = {
      page: 1,
      name: childName,
      status: status,
      schoolId: school,
      csv: true
    };
    this.setState({ loadingExport: true });
    axios
      .get(url, { params: payload })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `orders-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        this.setState({ loadingExport: false });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loadingExport: false });
      });
  };

  render() {
    const { errors, loadingExport } = this.state;
    const { orders } = this.props;
    return (
      <div className='container mt-5'>
        <div>
          <h2>
            <Translate id='schoolOrders.title' />
          </h2>
          <p>Total orders: {orders.totalResults}</p>
          <span className='text-danger'>{errors.message}</span>
        </div>

        <DistOrderSearch
          handleChange={this.handleChange}
          handleSearch={this.handleSearch}
          loading={this.props.loading}
          status={this.state.status}
          school={this.state.school}
          schools={this.state.schools}
        />

        <Button
          text={<Translate id='button.export' />}
          isLoading={loadingExport}
          className='btn btn-sm btn-primary'
          onClick={this.handleExport}
        />

        <DistOrderList
          orders={this.props.orders.results}
          loading={this.props.loading}
        />

        {this.props.orders.totalResults ? (
          <Pagination
            totalRecords={this.props.orders.totalResults}
            page={this.state.page.toString()}
            history={this.props.history}
            onPageChange={this.loadPage}
          />
        ) : null}
      </div>
    );
  }
}

DistOrders.propTypes = {
  auth: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  orders: state.distributor.orders,
  loading: state.distributor.loading,
  errors: state.errors
});

const mapDispatchToProps = {
  getDistributorOrders
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DistOrders));
