import React, { Component } from 'react';
import axios from 'axios';

import Button from '../common/Button';
import { Translate } from 'react-localize-redux';

import 'react-quill/dist/quill.snow.css';

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

export class EditDistributorContent extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '', message: null, loading: false, errors: {} };
  }

  componentDidMount() {
    axios
      .get('/api/distributors/content')
      .then(res => {
        this.setState({ text: res.data.html });
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  }

  handleChange = value => {
    this.setState({ text: value, message: null });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const payload = { html: this.state.text };
    axios
      .put('/api/distributors/content', payload)
      .then(res => {
        this.setState({ message: 'Saved', loading: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: true });
      });
  };

  render() {
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, false] }],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image'],
        [{ script: 'sub' }, { script: 'super' }]
      ],
      imageResize: {
        handleStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white'
        },
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      }
    };

    return (
      <div className='container'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h1>Edit distributor content</h1>
            <p>
              Note: some content might display differently on the actual page.
            </p>
          </div>
          {this.state.message ? (
            <p className='text-success'>Saved</p>
          ) : (
            <Button
              text={<Translate id='button.save' />}
              onClick={this.handleSubmit}
              loading={this.state.loading}
              className='btn btn-primary'
            />
          )}
        </div>
        <div className='editor'>
          <ReactQuill
            value={this.state.text}
            onChange={this.handleChange}
            modules={modules}
          />
        </div>
      </div>
    );
  }
}

export default EditDistributorContent;
