import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DummyField from './DummyField';

const DatePicker = ({
  name,
  placeholder,
  value,
  label,
  error,
  onChange,
  helperText,
  orientation, // horizontal || vertical(default)
  disabled,
  minDate,
  maxDate,
  required,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  dateFormat,
  timeCaption
}) => {
  if (disabled) {
    return (
      <DummyField
        value={moment(value).format('MMMM DD, YYYY')}
        label={label}
        orientation={orientation}
      />
    );
  } else {
    if (orientation === 'horizontal') {
      return (
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label' htmlFor={name}>
            {label}
            {required ? '*' : null}
          </label>
          <div className='col-sm-8'>
            <ReactDatePicker
              selected={value}
              onChange={onChange}
              dateFormat={dateFormat}
              className={classnames('form-control', {
                'is-invalid': error
              })}
              disabled={disabled}
              minDate={minDate}
              maxDate={maxDate}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              timeIntervals={timeIntervals}
              timeCaption={timeCaption}
            />
            {helperText && (
              <small className='form-text text-muted'>{helperText}</small>
            )}
            {error && <div className='invalid-feedback'>{error}</div>}
          </div>
        </div>
      );
    } else {
      return (
        <div className='form-group'>
          <label htmlFor={name}>
            {label}
            {required ? '*' : null}
          </label>
          <ReactDatePicker
            selected={value}
            onChange={onChange}
            dateFormat={dateFormat}
            className={classnames('form-control', {
              'is-invalid': error
            })}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
          />
          {helperText && (
            <small className='form-text text-muted'>{helperText}</small>
          )}
          {error && <div className='invalid-feedback'>{error}</div>}
        </div>
      );
    }
  }
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  required: PropTypes.bool,
  dateFormat: PropTypes.string
};

DatePicker.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  orientation: 'vertical',
  required: false,
  dateFormat: 'MMMM d, yyyy'
};

export default DatePicker;
