import axios from "axios";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  SET_SCHOOL_LOADING,
  GET_SCHOOL,
  GET_SCHOOLS,
  GET_BOARDS,
  GET_ORDERS,
  GET_NOTES,
  CLEAR_NOTES,
} from "./types";

// Get school by id
export const getSchoolById = (id) => (dispatch) => {
  dispatch(setLoading(true));
  const url = "/api/schools/" + id;
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Get School by schoolCode
export const getSchoolByCode = (schoolCode) => (dispatch) => {
  dispatch(setLoading(true));
  const url = "/api/schools/byCode/" + schoolCode;
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_SCHOOL,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Get users registered school
export const getMySchools = (page) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get("/api/schools/mySchools", { params: { page } })
    .then((res) => {
      dispatch({
        type: GET_SCHOOLS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Edit school profile
export const editSchool = (payload, schoolId) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const url = "/api/schools/" + schoolId;
  axios
    .put(url, payload)
    .then((res) => {
      dispatch(getSchoolById(schoolId));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Get all schools - ADMIN
export const getAllSchools = (
  pageNum,
  name,
  pcode,
  pnumber,
  display,
  schoolBoard
) => (dispatch) => {
  dispatch(setLoading(true));
  dispatch(clearErrors());
  const data = {
    page: pageNum,
    name: name,
    postal_code: pcode,
    phone: pnumber,
    isRegistered: display,
    board: schoolBoard,
  };
  axios
    .get("/api/schools/", { params: data })
    .then((res) => {
      dispatch({
        type: GET_SCHOOLS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Get School Boards
export const getSchoolBoards = () => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get("/api/schools/boards")
    .then((res) => {
      dispatch({
        type: GET_BOARDS,
        payload: res.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Add school
export const addSchool = () => (dispatch) => {
  dispatch(setLoading(true));
};

// Clear School
export const clearSchool = () => (dispatch) => {
  dispatch({
    type: GET_SCHOOL,
    payload: {},
  });
  dispatch(clearErrors());
};

export const clearSchools = () => (dispatch) => {
  dispatch({
    type: GET_SCHOOLS,
    payload: {},
  });
  dispatch(clearErrors());
};

// Clear errors
export const clearErrors = () => {
  return { type: CLEAR_ERRORS };
};

// Set loading and clear errors
export const setLoading = (isLoading) => {
  return {
    type: SET_SCHOOL_LOADING,
    payload: isLoading,
  };
};

// Get school orders
export const getSchoolOrders = (
  schoolId,
  pageNum,
  childName,
  orderId,
  status
) => (dispatch) => {
  dispatch(setLoading(true));

  const url = `/api/schools/${schoolId}/orders`;
  const data = {
    page: pageNum,
    childName: childName,
    id: orderId,
    status: status,
  };
  axios
    .get(url, { params: data })
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

// Clear orders
export const clearOrders = () => (dispatch) => {
  dispatch({
    type: GET_ORDERS,
    payload: {},
  });
  dispatch(clearErrors());
};

export const getParentOrders = () => (dispatch) => {
  dispatch(setLoading(true));

  const url = "/api/users/myOrders";
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

export const getNotes = (id) => (dispatch) => {
  dispatch(setLoading(true));
  const url = `/api/schools/${id}/notes`;
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: GET_NOTES,
        payload: res.data,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLoading(false));
    });
};

export const clearNotes = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTES,
    payload: [],
  });
  dispatch(clearErrors());
};
