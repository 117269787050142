import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const DistHeader = props => {
  const currentpath = `/distributor/dashboard`;
  return (
    <div className='d-flex align-items-center flex-wrap'>
      <NavLink
        className='schoolHeader'
        activeClassName='schoolHeaderActive'
        to={`${currentpath}/home`}
      >
        Home
      </NavLink>
      <NavLink
        className='schoolHeader'
        activeClassName='schoolHeaderActive'
        to={`${currentpath}/orders`}
      >
        Orders
      </NavLink>

      <NavLink
        className='schoolHeader'
        activeClassName='schoolHeaderActive'
        to={`${currentpath}/profile`}
      >
        Profile
      </NavLink>
    </div>
  );
};

export default withRouter(DistHeader);
