import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../common/Button';
import classnames from 'classnames';
import EditNote from './EditNote';
import { Modal } from 'react-bootstrap';

export class SchoolNote extends Component {
  state = {
    showMore: false,
    showModal: false
  };

  showMore = () => {
    this.setState({ showMore: true });
  };
  showLess = () => {
    this.setState({ showMore: false });
  };

  renderMore = (note, showMore, userId) => {
    let editButton = null;
    if (userId === note.creator.id) {
      editButton = (
        <Button text='Edit' className='btn btn-link' onClick={this.showModal} />
      );
    }
    if (showMore) {
      return (
        <div>
          <div className='note-text'>{note.text}</div>
          {editButton}
          <Button
            text='Show less'
            className='btn btn-link'
            onClick={this.showLess}
          />
        </div>
      );
    } else
      return (
        <div onClick={this.showMore} className='note-text text-truncate'>
          {note.text}
        </div>
      );
  };
  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { showMore, showModal } = this.state;
    const { note, userId, schoolId } = this.props;

    return (
      <div
        className={classnames('note-container', {
          'note-container-more': showMore
        })}
      >
        <div onClick={this.showMore} className='note-meta'>
          {`${moment(note.createdAt).format('MMM-DD-YYYY')}, ${
            note.creator.first_name
          } ${note.creator.last_name} `}
        </div>
        {this.renderMore(note, showMore, userId)}

        <Modal show={showModal} size='md' onHide={this.hideModal}>
          <Modal.Header>
            <Modal.Title>Edit note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditNote note={note} schoolId={schoolId} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

SchoolNote.propTypes = {
  note: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired
};

export default SchoolNote;
