import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import CoordinatorRegister from './CoordinatorRegister';
import ParentRegister from './ParentRegister';
import DistributorRegister from './DistributorRegister';
import Select from '../inputs/Select';
import FormSeparator from '../inputs/FormSeparator';
import Sidebar from '../layout/Sidebar';
import CheckSchoolRegistration from '../school/CheckSchoolRegistration';

export class Register extends Component {
  constructor() {
    super();
    this.state = {
      role: { label: 'School coordinator, Parent or Distributor', value: '' },
      displaySearch: false
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  renderForms() {
    const { role } = this.state;
    const { history } = this.props;
    switch (role.value) {
      case 'COORDINATOR':
        return <CoordinatorRegister history={history} role={role.value} />;
      case 'PARENT':
        return <ParentRegister history={history} role={role.value} />;
      case 'DISTRIBUTOR':
        return <DistributorRegister history={history} role={role.value} />;
      default:
        return null;
    }
  }

  handleRoleChange = role => {
    this.setState({ role });
  };

  showSearch = () => {
    this.setState({ displaySearch: true });
  };

  render() {
    const options = [
      { label: 'School coordinator', value: 'COORDINATOR' },
      { label: 'Parent', value: 'PARENT' },
      { label: 'Distributor', value: 'DISTRIBUTOR' }
    ];

    const { displaySearch } = this.state;

    return (
      <div>
        <h1>
          <Translate id='register.title' />
        </h1>

        <p className='mt-3 mb-3'>
          Register for the DFO portal{' '}
          <span
            aria-label='Check school registration status'
            onClick={this.showSearch}
            className='text-primary text-link d-md-inline d-lg-none'
          >
            or check if a school is registered.
          </span>
        </p>

        <div className='row'>
          <div className='col-lg-8 col-12'>
            <div className='d-md-block d-lg-none'>
              {displaySearch ? <CheckSchoolRegistration /> : null}
            </div>
            <div className='form-container'>
              <FormSeparator text={<Translate id='register.hint' />} />
              <Select
                name='role'
                label={<Translate id='register.role' />}
                value={this.state.role}
                onChange={this.handleRoleChange}
                options={options}
                orientation='horizontal'
                isClearable={false}
              />
              {this.renderForms()}
            </div>
          </div>
          <div className='col-lg-4 d-lg-block d-none sidebar'>
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {}
)(Register);
