import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getDistributorProfile,
  editDistributorProfile,
  superEditDistributorProfile,
  clearDistributorProfile
} from '../../redux/actions/distributorActions';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import TextField from '../inputs/TextField';
import DummyField from '../inputs/DummyField';
import Button from '../common/Button';
import Select from '../inputs/Select';
import NumberFormat from 'react-number-format';
import { isEmpty } from '../../utils';
import _ from 'lodash';
import FormSeparator from '../inputs/FormSeparator';
import { provinceOptions } from '../school/FormOptions';

export class DistProfile extends Component {
  constructor() {
    super();
    this.state = {
      id: '',
      name: '',
      phone: '',
      phone_ext: '',
      email: '',
      address1: '',
      address2: '',
      fax: '',
      city: '',
      province: {},
      postal_code: '',
      website: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      contact_phone_ext: '',
      code: '',
      user_id: '',
      errors: {}
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.distributor.profile)) {
      const id =
        this.props.auth.user.role === 'SUPER'
          ? this.props.distributorId
          : this.props.auth.user.distributor.id;
      this.props.getDistributorProfile(id);
    } else {
      this.updateState();
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }

    if (
      !_.isEqual(this.props.distributor.profile, prevProps.distributor.profile)
    ) {
      this.updateState();
    }
  }

  componentWillUnmount() {
    this.props.clearDistributorProfile();
  }

  updateState = () => {
    this.setState({
      id: this.props.distributor.profile.id,
      name: this.props.distributor.profile.name,
      phone: this.props.distributor.profile.phone,
      phone_ext: this.props.distributor.profile.phone_ext,
      email: this.props.distributor.profile.email,
      address1: this.props.distributor.profile.address1,
      address2: this.props.distributor.profile.address2,
      fax: this.props.distributor.profile.fax,
      city: this.props.distributor.profile.city,
      province: _.find(provinceOptions, item => {
        return item.value === this.props.distributor.profile.province;
      }),
      postal_code: this.props.distributor.profile.postal_code,
      website: this.props.distributor.profile.website,
      contact_name: this.props.distributor.profile.contact_name,
      contact_email: this.props.distributor.profile.contact_email,
      contact_phone: this.props.distributor.profile.contact_phone,
      contact_phone_ext: this.props.distributor.profile.contact_phone_ext,
      code: this.props.distributor.profile.code,
      user_id: this.props.distributor.profile.user_id
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSelectProvince = province => {
    this.setState({ province });
  };

  handleSubmit = () => {
    const payload = {
      name: this.state.name,
      phone: this.state.phone,
      phone_ext: this.state.phone_ext,
      email: this.state.email,
      address1: this.state.address1,
      address2: this.state.address2,
      fax: this.state.fax,
      city: this.state.city,
      province: !isEmpty(this.state.province)
        ? this.state.province.value
        : null,
      postal_code: this.state.postal_code,
      website: this.state.website,
      contact_name: this.state.contact_name,
      contact_email: this.state.contact_email,
      contact_phone: this.state.contact_phone,
      contact_phone_ext: this.state.contact_phone_ext
    };

    this.props.auth.user.role === 'SUPER'
      ? this.props.superEditDistributorProfile(
          this.props.distributorId,
          payload,
          this.props.page
        )
      : this.props.editDistributorProfile(payload);
  };

  render() {
    const {
      id,
      name,
      phone,
      phone_ext,
      email,
      address1,
      address2,
      fax,
      city,
      province,
      postal_code,
      website,
      contact_name,
      contact_email,
      contact_phone,
      contact_phone_ext,
      code,
      errors
    } = this.state;

    if (errors.message) {
      return <p className='text-danger'>{errors.message}</p>;
    } else {
      return (
        <div className='container mt-5'>
          <h2>Profile information</h2>
          <p>Here are your distributor profile details</p>
          <FormSeparator text='Distributor information' />
          <DummyField
            label={<Translate id='dist.id' />}
            value={id.toString()}
            orientation='horizontal'
          />
          <DummyField
            label={<Translate id='dist.code' />}
            value={code}
            orientation='horizontal'
          />
          <TextField
            name='name'
            placeholder='Distributor name'
            value={name}
            maxLength={100}
            label={<Translate id='dist.name' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.name}
          />
          <NumberFormat
            name='phone'
            label={<Translate id='dist.phone' />}
            value={phone}
            customInput={TextField}
            format='###-###-####'
            placeholder='555-555-5555'
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.phone}
          />
          <TextField
            name='phone_ext'
            placeholder='Phone Extension'
            value={phone_ext}
            maxLength={5}
            label={<Translate id='dist.phoneExt' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.phone_ext}
          />
          <TextField
            name='email'
            placeholder='Distributor email'
            value={email}
            label={<Translate id='dist.email' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.email}
          />
          <TextField
            name='address1'
            value={address1}
            label={<Translate id='dist.address1' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.address1}
          />
          <TextField
            name='address2'
            value={address2}
            label={<Translate id='dist.address2' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.address2}
          />
          <NumberFormat
            name='fax'
            label={<Translate id='dist.fax' />}
            value={fax}
            customInput={TextField}
            format='###-###-####'
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.fax}
          />
          <TextField
            name='city'
            value={city}
            label={<Translate id='dist.city' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.city}
          />
          <Select
            name='province'
            value={province}
            options={provinceOptions}
            label={<Translate id='dist.province' />}
            orientation='horizontal'
            onChange={this.handleSelectProvince}
            error={errors.province}
          />
          <TextField
            name='postal_code'
            label={<Translate id='dist.postal_code' />}
            value={postal_code}
            orientation='horizontal'
            maxLength={6}
            onChange={this.handleChange}
            error={errors.postal_code}
          />
          <TextField
            type='website'
            name='website'
            value={website}
            maxLength={50}
            label={<Translate id='dist.website' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.website}
          />
          <FormSeparator text='Contact information' />
          <TextField
            type='contact_name'
            name='contact_name'
            value={contact_name}
            maxLength={50}
            label={<Translate id='dist.contact_name' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.contact_name}
          />
          <TextField
            type='contact_email'
            name='contact_email'
            value={contact_email}
            label={<Translate id='dist.contact_email' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.contact_email}
          />
          <NumberFormat
            name='contact_phone'
            label={<Translate id='dist.contact_phone' />}
            value={contact_phone}
            customInput={TextField}
            format='###-###-####'
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.contact_phone}
          />
          <TextField
            name='contact_phone_ext'
            value={contact_phone_ext}
            maxLength={5}
            label={<Translate id='dist.contact_phone_ext' />}
            orientation='horizontal'
            onChange={this.handleChange}
            error={errors.contact_phone_ext}
          />

          <Button
            text={<Translate id='button.save' />}
            isLoading={this.props.loading}
            className='btn btn-primary'
            onClick={this.handleSubmit}
          />
        </div>
      );
    }
  }
}

DistProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  distributor: PropTypes.object.isRequired,
  distributorId: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  distributor: state.distributor,
  loading: state.distributor.loading,
  errors: state.errors
});

const mapDispatchToProps = {
  getDistributorProfile,
  editDistributorProfile,
  clearDistributorProfile,
  superEditDistributorProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistProfile);
