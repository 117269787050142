import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDistributors } from '../../redux/actions/adminActions';
import { Translate } from 'react-localize-redux';
import _ from 'lodash';
import DistributorSearchForm from './DistributorSearchForm';
import DistributorList from './DistributorList';
import Pagination from '../common/Pagination';
import { Redirect } from 'react-router-dom';

export class DistributorSearch extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      name: '',
      loadingExport: false,
      errors: {}
    };
  }

  componentDidMount() {
    this.props.getDistributors(1);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }
  }

  loadPage = page => {
    this.props.getDistributors(page, this.state.name);
    this.setState({ page: page });
  };

  handleSearch = e => {
    e.preventDefault();
    this.props.getDistributors(1, this.state.name);
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { distributors, loading } = this.props;
    const { errors } = this.state;
    if (this.props.userRole === 'SUPER') {
      return (
        <div>
          <h1>
            <Translate id='adminDashboard.manageDistributors' />
          </h1>
          <p>Total orders: {distributors.totalResults}</p>
          <span className='text-danger'>{errors.message}</span>
          <DistributorSearchForm
            handleChange={this.handleChange}
            handleSearch={this.handleSearch}
            loading={this.props.loading}
          />

          <DistributorList
            distributors={distributors.results}
            loading={loading}
            page={this.state.page}
          />

          {this.props.distributors.totalResults ? (
            <Pagination
              totalRecords={this.props.distributors.totalResults}
              page={this.state.page.toString()}
              history={this.props.history}
              onPageChange={this.loadPage}
            />
          ) : null}
        </div>
      );
    } else return <Redirect to='/dashboard' />;
  }
}

DistributorSearch.propTypes = {
  auth: PropTypes.object.isRequired,
  distributors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  userRole: state.auth.user.role,
  distributors: state.admin.distributors,
  loading: state.admin.loading,
  errors: state.errors
});

const mapDispatchToProps = {
  getDistributors
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorSearch);
