import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNotes } from '../../redux/actions/schoolActions';
import TextArea from '../inputs/TextArea';
import Button from '../common/Button';
import axios from 'axios';

export class CreateNote extends Component {
  state = {
    text: '',
    loading: false,
    errors: {}
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const id = this.props.schoolId;
    const payload = {
      text: this.state.text
    };
    //Create note
    const url = `/api/schools/${id}/notes`;
    axios
      .post(url, payload)
      .then(res => {
        //call hideModal on success
        this.props.getNotes(id);
        this.props.hideModal();
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  };

  render() {
    const { text, loading, errors } = this.state;
    return (
      <div className='container'>
        <p className='text-danger'>{errors.text}</p>
        <TextArea
          name='text'
          value={text}
          label='Note text'
          onChange={this.handleChange}
        />
        <Button
          className='btn btn-primary'
          text='Create note'
          onClick={this.handleSubmit} // Change to handleSubmit
          loading={loading}
        />
      </div>
    );
  }
}

CreateNote.propTypes = {
  hideModal: PropTypes.func.isRequired,
  schoolId: PropTypes.number,
  getNotes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getNotes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNote);
