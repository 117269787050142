import React from 'react';
import { Translate } from 'react-localize-redux';

export default function DistributorHeader() {
  return (
    <div className='row table-header'>
      <div className='col-md-2 col-2 text-truncate'>
        <Translate id='orderList.id' />
      </div>
      <div className='col-md-4 col-4 text-truncate'>
        <Translate id='adminDashboard.distributorName' />
      </div>
      <div className='col-md-2 col-4 d-none d-md-block text-truncate'>
        <Translate id='adminDashboard.province' />
      </div>
      <div className='col-md-3 col-4 d-none d-md-block text-truncate'>
        <Translate id='schoolList.phone' />
      </div>
      <div className='col-sm-1 col-2 text-right'>
        {/* <Translate id='schoolList.actions' /> */}
      </div>
    </div>
  );
}
