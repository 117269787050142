// THIS COMPONENT DOES NOT REQUIRE USER TO BE LOGGED IN
import React, { Component } from 'react';
import axios from 'axios';
import AsyncSelect from '../inputs/AsyncSelect';

export class CheckSchoolRegistration extends Component {
  constructor() {
    super();
    this.state = {
      school: { label: '', value: '', accepts_orders: '' },
      message: '',
      hint: '',
      errors: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { school } = this.state;
    let message = '';
    let acceptsOrders;
    let hint = '';
    if (prevState.school.label !== school.label) {
      if (school.value === true) {
        if (school.accepts_orders === true) {
          acceptsOrders = ' and accepts online orders.';
        } else {
          acceptsOrders = ', but it does NOT accepts online orders.';
          hint =
            'You can still register and submit your milk orders directly to the school coordinator.';
        }
        message = `School is registered${acceptsOrders}`;
      } else {
        message = 'School is not yet registered.';
      }
      this.setState({ message: message, hint: hint });
    }
  }

  handleSelectSchool = school => {
    this.setState({ school });
  };

  loadSchoolOptions = inputValue =>
    new Promise((resolve, reject) => {
      const payload = {
        name: inputValue
      };
      const url = '/api/schools/publicAutocomplete';
      if (inputValue.length >= 2)
        axios
          .get(url, { params: payload })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
    });

  render() {
    const { school, message, hint } = this.state;
    return (
      <div className='check-schoolreg-container'>
        <div className='form-container'>
          <AsyncSelect
            name='school'
            label='Check if your school is registered with DFO.'
            placeholder='Search for a School'
            value={school}
            orientation='vertical'
            loadOptions={this.loadSchoolOptions}
            onChange={this.handleSelectSchool}
            isClearable={false}
          />
        </div>
        <p>
          <strong>{message}</strong> {hint}
        </p>
      </div>
    );
  }
}

export default CheckSchoolRegistration;
