import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '../inputs/TextField';
import DatePicker from '../inputs/DatePicker';
import { Translate } from 'react-localize-redux';
import { Modal } from 'react-bootstrap';
import Button from '../common/Button';
import Select from '../inputs/Select';
import { isEmpty } from '../../utils';
import moment from 'moment';

class PresentationScheduleItem extends Component {
  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = {
      presentationTime: item.presentationTime,
      topic: { label: item.topic, value: item.topic },
      topicLink: item.topicLink,
      gradeLevel: item.gradeLevel,
      studentNum: item.studentNum,
      teacher: item.teacher,
      room: item.room,
      smartBoard: item.smartBoard,
      lang: item.lang,
      showModal: false,
      errors: {}
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleTimeChange = time => {
    this.setState({ presentationTime: time });
  };

  handleSelectBoard = smartBoard => {
    this.setState({ smartBoard });
  };
  handleSelectLang = lang => {
    this.setState({ lang });
  };
  handleSelectTopic = topic => {
    this.setState({ topic });
  };

  saveItem = () => {
    const item = {
      presentationTime: this.state.presentationTime,
      topic: this.state.topic.value,
      topicLink: this.state.topicLink,
      gradeLevel: this.state.gradeLevel,
      studentNum: this.state.studentNum,
      teacher: this.state.teacher,
      room: this.state.room,
      smartBoard: this.state.smartBoard,
      lang: this.state.lang
    };
    this.props.saveScheduleItem(item, this.props.index);
    this.hideModal();
  };

  deleteItem = () => {
    this.props.removeScheduleItem(this.props.index);
  };

  render() {
    const {
      presentationTime,
      topic,
      topicLink,
      gradeLevel,
      studentNum,
      teacher,
      room,
      smartBoard,
      lang,
      errors
    } = this.state;

    const options = [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ];
    const langOptions = [
      { label: 'English', value: 'English' },
      { label: 'French', value: 'French' },
      { label: 'Both', value: 'Both' }
    ];

    const topicOptions = [
      { label: 'Dairy Farming', value: 'Dairy Farming' },
      {
        label: 'Dairy Goodness - Making the most of milk',
        value: 'Dairy Goodness - Making the most of milk'
      },
      {
        label: 'Careers in the Dairy Industry',
        value: 'Careers in the Dairy Industry'
      },
      {
        label: 'Processing - From Moo to You',
        value: 'Processing - From Moo to You'
      },
      {
        label: 'Technology - modern farming',
        value: 'Technology - modern farming'
      }
    ];

    let disableSaveButton = true;
    if (
      presentationTime !== null &&
      !isEmpty(topic) &&
      !isEmpty(gradeLevel) &&
      !isEmpty(studentNum) &&
      !isEmpty(teacher) &&
      !isEmpty(room) &&
      !isEmpty(smartBoard) &&
      !isEmpty(lang)
    ) {
      disableSaveButton = false;
    }

    return (
      <div>
        <div className='row presentationScheduleItem' onClick={this.showModal}>
          <div className='col '>
            <p>{moment(presentationTime).format('HH:mm')}</p>
          </div>
          <div className='col'>
            <p>{topic.value}</p>
          </div>
          <div className='col'>
            <p>{gradeLevel}</p>
          </div>
          <div className='col'>
            <p>{studentNum}</p>
          </div>
          <div className='col'>
            <p>{teacher}</p>
          </div>
          <div className='col'>
            <p>{room}</p>
          </div>
          <div className='col'>
            <p>{smartBoard.label}</p>
          </div>
          <div className='col'>
            <p>{lang.label}</p>
          </div>
        </div>

        <Modal
          size='lg'
          centered
          show={this.state.showModal}
          onHide={this.hideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit presentation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              name='presentationTime'
              value={presentationTime}
              label={<Translate id='dairyEducatorForm.presentationTime' />}
              orientation='horizontal'
              required
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={15}
              dateFormat='h:mm aa'
              popperClassName='popper-custom'
              onChange={this.handleTimeChange}
              error={
                errors.presentationTime
                  ? errors.presentationTime
                  : errors.message
              }
            />

            <Select
              name='topic'
              value={topic}
              label={<Translate id='dairyEducatorForm.topic' />}
              orientation='horizontal'
              onChange={this.handleSelectTopic}
              isClearable={false}
              options={topicOptions}
              required
            />
            <TextField
              name='topicLink'
              value={topicLink}
              maxLength={100}
              label={<Translate id='dairyEducatorForm.topicLink' />}
              placeholder='Topic Link'
              orientation='horizontal'
              onChange={this.handleChange}
              required
            />
            <TextField
              name='gradeLevel'
              value={gradeLevel}
              maxLength={100}
              label={<Translate id='dairyEducatorForm.gradeLevel' />}
              placeholder='Grade Level'
              orientation='horizontal'
              onChange={this.handleChange}
              required
            />
            <TextField
              type='number'
              name='studentNum'
              value={studentNum}
              maxLength={10}
              label={<Translate id='dairyEducatorForm.studentNum' />}
              placeholder='Number of students'
              orientation='horizontal'
              onChange={this.handleChange}
              required
            />
            <TextField
              name='teacher'
              value={teacher}
              maxLength={100}
              label={<Translate id='dairyEducatorForm.teacher' />}
              placeholder='Teacher'
              orientation='horizontal'
              onChange={this.handleChange}
              required
            />
            <TextField
              name='room'
              value={room}
              maxLength={10}
              label={<Translate id='dairyEducatorForm.room' />}
              placeholder='Room number'
              orientation='horizontal'
              onChange={this.handleChange}
              required
            />
            <Select
              name='smartBoard'
              value={smartBoard}
              options={options}
              label={<Translate id='dairyEducatorForm.smartBoard' />}
              orientation='horizontal'
              onChange={this.handleSelectBoard}
              isClearable={false}
              required
            />
            <Select
              name='lang'
              value={lang}
              options={langOptions}
              label={<Translate id='dairyEducatorForm.lang' />}
              orientation='horizontal'
              onChange={this.handleSelectLang}
              isClearable={false}
              required
            />
            <Button
              text='Delete'
              className='btn btn-danger'
              onClick={this.deleteItem}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              text='Cancel'
              className='btn btn-secondary'
              onClick={this.hideModal}
            />
            <Button
              text='Save'
              className='btn btn-primary'
              onClick={this.saveItem}
              disabled={disableSaveButton}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

PresentationScheduleItem.propTypes = {
  saveScheduleItem: PropTypes.func.isRequired,
  removeScheduleItem: PropTypes.func.isRequired,
  item: PropTypes.object,
  index: PropTypes.number
};

export default PresentationScheduleItem;
