import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";
import ReactTooltip from "react-tooltip";
import flagEn from "../../assets/flag-en.png";
import flagFr from "../../assets/flag-fr.png";
import { Translate } from "react-localize-redux";

import translations from "../../translations/translation.json";
import { renderToStaticMarkup } from "react-dom/server";

export class LanguageToggle extends Component {
  constructor(props) {
    super(props);

    // Check if the locale cookie exists, the cookie is permanent
    const cookies = new Cookies();
    let lang;
    if (cookies.get("dfo-locale")) {
      lang = cookies.get("dfo-locale");
    } else {
      cookies.set("dfo-locale", "en", { path: "/" });
    }

    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "French", code: "fr" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, defaultLanguage: lang }
    });

    this.props.addTranslation(translations);
  }
  state = {
    showHint: false
  };

  changeLanguage = () => {
    let languageCode = this.props.activeLanguage.code === "en" ? "fr" : "en";
    const cookies = new Cookies();
    cookies.set("dfo-locale", languageCode, { path: "/" });
    this.props.setActiveLanguage(languageCode);
  };

  render() {
    if (this.props.activeLanguage) {
      let flag = this.props.activeLanguage.code === "en" ? flagEn : flagFr;
      let changeTo =
        this.props.activeLanguage.code === "en" ? "French" : "English";
      return (
        <div>
          <ReactTooltip place="right" type="dark1" effect="solid">
            <Translate id="navbar.changeTo" /> {changeTo}
          </ReactTooltip>
          <span>
            {/* <Translate id="navbar.language" />: */}
            <span
              data-tip="React-tooltip"
              onClick={this.changeLanguage}
              className="languageText cp"
            >
              {this.props.activeLanguage.name}
            </span>
            <img src={flag} alt="flag" />
          </span>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

LanguageToggle.propTypes = {
  setActiveLanguage: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  activeLanguage: PropTypes.object
};

export default withLocalize(LanguageToggle);
