import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addSchool, getSchoolBoards } from '../../redux/actions/schoolActions';
import { isEmpty } from '../../utils';
import TextField from '../inputs/TextField';
import Button from '../common/Button';
import Select from '../inputs/Select';
import NumberFormat from 'react-number-format';
import { Translate } from 'react-localize-redux';
import FormSeparator from '../inputs/FormSeparator';
import { Modal } from 'react-bootstrap';

import {
  regionOptions,
  provinceOptions,
  schoolTypeOptions,
  languagesOptions,
  prefLanguageOptions,
  gradesOptions
} from './FormOptions';

class AddSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      schoolBoard: null,
      street: '',
      street2: '',
      city: '',
      region: null,
      province: null,
      postalCode: '',
      telephone: '',
      schoolPhoneExtension: '',
      fax: '',
      website: '',
      principalName: '',
      principalEmail: '',
      principalTitle: '',
      principalPhone: '',
      schoolType: null,
      languages: null,
      prefLanguage: null,
      lowGrade: null,
      highGrade: null,
      gradeQualifier: '',
      numberStudents: '',
      milkPrice: '',
      chocMilkPrice: '',
      milkCost: '',
      chocMilkCost: '',
      newSchoolData: {},
      errors: {}
    };
  }

  componentDidMount() {
    this.props.getSchoolBoards();
  }

  handleChange = e => {
    this.setState({
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };
  handleSelectSchoolBoard = schoolBoard => {
    this.setState({ schoolBoard });
  };
  handleSelectRegion = region => {
    this.setState({ region });
  };
  handleSelectProvince = province => {
    this.setState({ province });
  };
  handleSelectSchoolType = schoolType => {
    this.setState({ schoolType });
  };
  handleSelectLanguages = languages => {
    this.setState({ languages });
  };
  handleSelectPrefLanguage = prefLanguage => {
    this.setState({ prefLanguage });
  };
  handleSelectLowGrades = lowGrade => {
    this.setState({ lowGrade });
  };
  handleSelectHighGrades = highGrade => {
    this.setState({ highGrade });
  };
  handleSelectRegistrationMethod = registrationMethod => {
    this.setState({ registrationMethod });
  };
  handleSelectRegDeclineReason = regDeclineReason => {
    this.setState({ regDeclineReason });
  };
  handleDateMembershipStart = date => {
    this.setState({ membershipStartDate: date });
  };
  handleDateRegistrationDeclined = date => {
    this.setState({ regDeclinedDate: date });
  };

  // Figures out whether it needs to register or edit a school
  handleSubmit = e => {
    e.preventDefault();
    const {
      name,
      schoolBoard,
      street,
      street2,
      city,
      region,
      province,
      postalCode,
      telephone,
      schoolPhoneExtension,
      fax,
      website,
      principalName,
      principalEmail,
      principalTitle,
      principalPhone,
      schoolType,
      languages,
      prefLanguage,
      lowGrade,
      highGrade,
      gradeQualifier,
      numberStudents,
      milkPrice,
      chocMilkPrice,
      milkCost,
      chocMilkCost
    } = this.state;

    const payload = {
      name: name,
      school_board_id: isEmpty(schoolBoard) ? null : schoolBoard.value,
      address1: street,
      address2: street2,
      city: city,
      region: isEmpty(region) ? null : region.value,
      province: isEmpty(province) ? null : province.value,
      postal_code: postalCode,
      phone: telephone,
      phone_ext: schoolPhoneExtension,
      fax: fax,
      website: website,
      school_type: isEmpty(schoolType) ? null : schoolType.value,
      language: isEmpty(languages) ? null : languages.value,
      preferred_language: isEmpty(prefLanguage) ? null : prefLanguage.value,
      low_grade: isEmpty(lowGrade) ? null : lowGrade.value,
      high_grade: isEmpty(highGrade) ? null : highGrade.value,
      grade_qualifier: gradeQualifier,
      num_students: numberStudents,
      principal_name: principalName,
      principal_email: principalEmail,
      principal_title: principalTitle,
      principal_phone: principalPhone,
      white_milk_price: isEmpty(milkPrice) ? undefined : milkPrice, // New field
      chocolate_milk_price: isEmpty(chocMilkPrice) ? undefined : chocMilkPrice, // New Field
      white_milk_cost: isEmpty(milkCost) ? undefined : milkCost,
      chocolate_milk_cost: isEmpty(chocMilkCost) ? undefined : chocMilkCost
    };

    const url = '/api/schools/';

    axios
      .post(url, payload)
      .then(res => {
        this.setState({ newSchoolData: res.data });
        this.props.history.push('/admin/dashboard/schools');
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  };

  // Component will only render if url param type is either register | edit
  render() {
    const buttonText = <Translate id='button.save' />;
    const heading = <Translate id='schoolForm.addSchool' />;

    const {
      name,
      schoolBoard,
      street,
      street2,
      city,
      region,
      province,
      postalCode,
      telephone,
      schoolPhoneExtension,
      fax,
      website,
      principalName,
      principalEmail,
      principalTitle,
      principalPhone,
      schoolType,
      languages,
      prefLanguage,
      lowGrade,
      highGrade,
      gradeQualifier,
      numberStudents,
      errors,
      milkPrice,
      chocMilkPrice,
      milkCost,
      chocMilkCost,
      newSchoolData
    } = this.state;

    return (
      <div>
        <h1>{heading}</h1>
        Please fill out the details below to add a school to the DFO database
        <div className='row'>
          <div className='col'>
            <FormSeparator text='School information' />
            <TextField
              name='name'
              placeholder='School Name'
              value={name}
              maxLength={100}
              label={<Translate id='schoolForm.schoolName' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.name ? errors.name : errors.message}
            />
            <Select
              name='schoolBoard'
              value={schoolBoard}
              options={this.props.boards}
              label={<Translate id='schoolForm.schoolBoard' />}
              orientation='horizontal'
              onChange={this.handleSelectSchoolBoard}
              error={errors.schoolBoard ? errors.schoolBoard : errors.message}
            />
            <TextField
              name='street'
              placeholder='Street'
              value={street}
              maxLength={50}
              label={<Translate id='schoolForm.street' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.address1 ? errors.address1 : errors.message}
            />
            <TextField
              name='street2'
              placeholder='Street 2'
              value={street2}
              maxLength={50}
              label={<Translate id='schoolForm.street2' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.address2 ? errors.address2 : errors.message}
            />
            <TextField
              name='city'
              placeholder='City'
              value={city}
              maxLength={40}
              label={<Translate id='schoolForm.city' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.city ? errors.city : errors.message}
            />
            <Select
              name='region'
              placeholder='Region'
              value={region}
              label={<Translate id='schoolForm.region' />}
              orientation='horizontal'
              options={regionOptions}
              onChange={this.handleSelectRegion}
              error={errors.region ? errors.region : errors.message}
            />
            <Select
              name='province'
              value={province}
              options={provinceOptions}
              label={<Translate id='schoolForm.province' />}
              orientation='horizontal'
              onChange={this.handleSelectProvince}
              error={errors.province ? errors.province : errors.message}
            />
            <TextField
              name='postalCode'
              label={<Translate id='schoolForm.postalCode' />}
              value={postalCode}
              orientation='horizontal'
              maxLength={6}
              onChange={this.handleChange}
              error={errors.postal_code ? errors.postal_code : errors.message}
            />
            <NumberFormat
              name='telephone'
              label={<Translate id='schoolForm.telephone' />}
              value={telephone}
              customInput={TextField}
              format='###-###-####'
              placeholder='555-555-5555'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.phone ? errors.phone : errors.message}
            />
            <TextField
              name='schoolPhoneExtension'
              placeholder='Phone Extension'
              value={schoolPhoneExtension}
              maxLength={5}
              label={<Translate id='schoolForm.phoneExtension' />}
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.phone_ext ? errors.phone_ext : errors.message}
            />
            <NumberFormat
              name='fax'
              label={<Translate id='schoolForm.fax' />}
              value={fax}
              customInput={TextField}
              format='###-###-####'
              placeholder='555-555-5555'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.fax ? errors.fax : errors.message}
            />
            <TextField
              type='website'
              name='website'
              value={website}
              maxLength={50}
              label={<Translate id='schoolForm.website' />}
              placeholder='Website'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.website ? errors.website : errors.message}
            />
          </div>

          <div className='col-lg'>
            <Select
              name='schoolType'
              value={schoolType}
              options={schoolTypeOptions}
              label={<Translate id='schoolForm.schoolType' />}
              placeholder='Separate, Public, Private...'
              orientation='horizontal'
              onChange={this.handleSelectSchoolType}
              error={errors.school_type ? errors.school_type : errors.message}
            />
            <Select
              name='languages'
              value={languages}
              options={languagesOptions}
              label={<Translate id='schoolForm.languages' />}
              placeholder='English, French or Both'
              orientation='horizontal'
              onChange={this.handleSelectLanguages}
              error={errors.language ? errors.language : errors.message}
            />
            <Select
              name='prefLanguage'
              value={prefLanguage}
              options={prefLanguageOptions}
              label={<Translate id='schoolForm.prefLanguage' />}
              placeholder='English or French'
              orientation='horizontal'
              onChange={this.handleSelectPrefLanguage}
              error={
                errors.preferred_language
                  ? errors.preferred_language
                  : errors.message
              }
            />
            <Select
              name='lowGrade'
              value={lowGrade}
              options={gradesOptions}
              label={<Translate id='schoolForm.lowGrade' />}
              orientation='horizontal'
              onChange={this.handleSelectLowGrades}
              error={errors.low_grade ? errors.low_grade : errors.message}
            />
            <Select
              name='highGrade'
              value={highGrade}
              options={gradesOptions}
              label={<Translate id='schoolForm.highGrade' />}
              orientation='horizontal'
              onChange={this.handleSelectHighGrades}
              error={errors.high_grade ? errors.high_grade : errors.message}
            />
            <TextField
              name='gradeQualifier'
              value={gradeQualifier}
              maxLength={50}
              label={<Translate id='schoolForm.gradeQualifier' />}
              placeholder='Grade Qualifier'
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.grade_qualifier ? errors.grade_qualifier : errors.message
              }
            />
            <NumberFormat
              name='numberStudents'
              label={<Translate id='schoolForm.numberStudents' />}
              value={numberStudents}
              customInput={TextField}
              thousandSeparator
              placeholder='Number of students'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.num_students ? errors.num_students : errors.message}
            />
            <NumberFormat
              name='milkPrice'
              value={milkPrice}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              label={<Translate id='schoolForm.milkPrice' />}
              placeholder='White Milk Price'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.milkPrice ? errors.milkPrice : errors.message}
            />
            <NumberFormat
              name='chocMilkPrice'
              value={chocMilkPrice}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              label={<Translate id='schoolForm.chocMilkPrice' />}
              placeholder='Chocolate Milk Price'
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.chocMilkPrice ? errors.chocMilkPrice : errors.message
              }
            />
            <NumberFormat
              name='milkCost'
              value={milkCost}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              label={<Translate id='schoolForm.milkCost' />}
              placeholder='White Milk Price'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.milkCost ? errors.milkCost : errors.message}
            />
            <NumberFormat
              name='chocMilkCost'
              value={chocMilkCost}
              customInput={TextField}
              thousandSeparator
              decimalScale={2}
              label={<Translate id='schoolForm.chocMilkCost' />}
              placeholder='Chocolate Milk Price'
              orientation='horizontal'
              onChange={this.handleChange}
              error={errors.chocMilkCost ? errors.chocMilkCost : errors.message}
            />

            <FormSeparator text='Principal information' />

            <TextField
              name='principalName'
              value={principalName}
              maxLength={50}
              label={<Translate id='schoolForm.principalName' />}
              placeholder='Principal Name'
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.principal_name ? errors.principal_name : errors.message
              }
            />
            <TextField
              type='email'
              name='principalEmail'
              value={principalEmail}
              maxLength={50}
              label={<Translate id='schoolForm.principalEmail' />}
              placeholder='Principal Email'
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.principal_email ? errors.principal_email : errors.message
              }
            />
            <TextField
              name='principalTitle'
              value={principalTitle}
              maxLength={45}
              label={<Translate id='schoolForm.principalTitle' />}
              placeholder='Principal Title'
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.principal_title ? errors.principal_title : errors.message
              }
            />
            <NumberFormat
              name='principalPhone'
              label={<Translate id='schoolForm.principalPhone' />}
              value={principalPhone}
              customInput={TextField}
              format='###-###-####'
              placeholder='555-555-5555'
              orientation='horizontal'
              onChange={this.handleChange}
              error={
                errors.principal_phone ? errors.principal_phone : errors.message
              }
            />
          </div>
        </div>
        <Button
          text={buttonText}
          isLoading={this.props.loading}
          className='btn btn-primary'
          onClick={this.handleSubmit}
        />
        <Modal show={this.state.success}>
          <Modal.Header>
            <Modal.Title>New school added!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {`${newSchoolData.name} has been successfully added.`}
          </Modal.Body>
          <Modal.Footer>
            <Link to='/admin/dashboard' className='btn btn-primary'>
              Back to dashboard
            </Link>
            <Link
              to={`/admin/dashboard/school/edit/${newSchoolData.id}`}
              className='btn btn-primary'
            >
              View School
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AddSchool.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  boards: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  loading: state.school.loading,
  user: state.auth.user.role,
  boards: state.school.boards,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { addSchool, getSchoolBoards }
)(AddSchool);
