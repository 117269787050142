import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '../inputs/TextField';
import DatePicker from '../inputs/DatePicker';
import { Translate } from 'react-localize-redux';
import Button from '../common/Button';
import Select from '../inputs/Select';
import { isEmpty } from '../../utils';

class AddPresentationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentationTime: null,
      topic: { label: '', value: '' },
      topicLink: '',
      gradeLevel: '',
      studentNum: '',
      teacher: '',
      room: '',
      smartBoard: null,
      lang: null,
      errors: {}
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleTimeChange = time => {
    this.setState({ presentationTime: time });
  };
  handleSelectBoard = smartBoard => {
    this.setState({ smartBoard });
  };
  handleSelectLang = lang => {
    this.setState({ lang });
  };
  handleSelectTopic = topic => {
    this.setState({ topic });
  };

  saveItem = () => {
    const item = {
      presentationTime: this.state.presentationTime,
      topic: this.state.topic.value,
      topicLink: this.state.topicLink,
      gradeLevel: this.state.gradeLevel,
      studentNum: this.state.studentNum,
      teacher: this.state.teacher,
      room: this.state.room,
      smartBoard: this.state.smartBoard,
      lang: this.state.lang
    };
    this.props.addScheduleItem(item);
  };

  render() {
    const {
      presentationTime,
      topic,
      topicLink,
      gradeLevel,
      studentNum,
      teacher,
      room,
      smartBoard,
      lang,
      errors
    } = this.state;

    const options = [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ];

    const langOptions = [
      { label: 'English', value: 'English' },
      { label: 'French', value: 'French' },
      { label: 'Both', value: 'Both' }
    ];

    const topicOptions = [
      { label: 'Dairy Farming', value: 'Dairy Farming' },
      {
        label: 'Dairy Goodness - Making the most of milk',
        value: 'Dairy Goodness - Making the most of milk'
      },
      {
        label: 'Careers in the Dairy Industry',
        value: 'Careers in the Dairy Industry'
      },
      {
        label: 'Processing - From Moo to You',
        value: 'Processing - From Moo to You'
      },
      {
        label: 'Technology - modern farming',
        value: 'Technology - modern farming'
      }
    ];

    let disableSaveButton = true;
    if (
      presentationTime !== null &&
      !isEmpty(topic) &&
      !isEmpty(gradeLevel) &&
      !isEmpty(studentNum) &&
      !isEmpty(teacher) &&
      !isEmpty(room) &&
      !isEmpty(smartBoard) &&
      !isEmpty(lang)
    ) {
      disableSaveButton = false;
    }

    return (
      <div>
        <DatePicker
          name='presentationTime'
          value={presentationTime}
          label={<Translate id='dairyEducatorForm.presentationTime' />}
          orientation='horizontal'
          required
          showTimeSelect={true}
          showTimeSelectOnly={true}
          timeIntervals={15}
          dateFormat='h:mm aa'
          popperClassName='popper-custom'
          onChange={this.handleTimeChange}
          error={
            errors.presentationTime ? errors.presentationTime : errors.message
          }
        />
        <Select
          name='topic'
          value={topic}
          label={<Translate id='dairyEducatorForm.topic' />}
          orientation='horizontal'
          onChange={this.handleSelectTopic}
          isClearable={false}
          options={topicOptions}
        />
        <TextField
          name='topicLink'
          value={topicLink}
          maxLength={100}
          label={<Translate id='dairyEducatorForm.topicLink' />}
          placeholder='Topic Link'
          orientation='horizontal'
          onChange={this.handleChange}
        />
        <TextField
          name='gradeLevel'
          value={gradeLevel}
          maxLength={100}
          label={<Translate id='dairyEducatorForm.gradeLevel' />}
          placeholder='Grade Level'
          orientation='horizontal'
          onChange={this.handleChange}
        />
        <TextField
          type='number'
          name='studentNum'
          value={studentNum}
          maxLength={10}
          label={<Translate id='dairyEducatorForm.studentNum' />}
          placeholder='Number of students'
          orientation='horizontal'
          onChange={this.handleChange}
        />
        <TextField
          name='teacher'
          value={teacher}
          maxLength={100}
          label={<Translate id='dairyEducatorForm.teacher' />}
          placeholder='Teacher'
          orientation='horizontal'
          onChange={this.handleChange}
        />
        <TextField
          name='room'
          value={room}
          maxLength={10}
          label={<Translate id='dairyEducatorForm.room' />}
          placeholder='Room number'
          orientation='horizontal'
          onChange={this.handleChange}
        />
        <Select
          name='smartBoard'
          value={smartBoard}
          options={options}
          label={<Translate id='dairyEducatorForm.smartBoard' />}
          orientation='horizontal'
          onChange={this.handleSelectBoard}
          isClearable={false}
        />
        <Select
          name='lang'
          value={lang}
          options={langOptions}
          label={<Translate id='dairyEducatorForm.lang' />}
          orientation='horizontal'
          onChange={this.handleSelectLang}
          isClearable={false}
        />

        <div className='text-right'>
          <Button
            text='Cancel'
            className='btn btn-secondary mr-3'
            onClick={this.props.hideModal}
          />
          <Button
            text='Save'
            className='btn btn-primary'
            onClick={this.saveItem}
            disabled={disableSaveButton}
          />
        </div>
      </div>
    );
  }
}

AddPresentationItem.propTypes = {
  addScheduleItem: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default AddPresentationItem;
