import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function Checkbox({
  label,
  required,
  name,
  value,
  onChange,
  helperText,
  error,
}) {
  return (
    <div className="form-group row align-items-center">
      <label className="col-sm-4 col-form-label" htmlFor={name}>
        {label}
        {required ? "*" : null}
      </label>
      <div className="col-sm-8">
        <input
          style={{ width: "15px", height: "15px" }}
          type="checkbox"
          name={name}
          value={value}
          checked={value}
          onChange={onChange}
          className={classnames("form-control-md", {
            "is-invalid": error,
          })}
        />
        {helperText && (
          <small className="form-text text-muted">{helperText}</small>
        )}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

export default Checkbox;
