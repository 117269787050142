import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TextField from "../inputs/TextField";
import Button from "../common/Button";
import { Translate } from "react-localize-redux";

export class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      emailSent: false,
      password: "",
      password2: "",
      passwordChanged: false,
      loading: false,
      errors: {}
    };

    this.submitNewPassword = this.submitNewPassword.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  // Sets loading to true, requests token via email
  // if 200 show success message
  // else show error message
  // set loading to false
  submitEmail = () => {
    const email = {
      email: this.state.email
    };
    this.setState({ loading: true });
    axios
      .post("/api/users/forgotPassword", email)
      .then(res => {
        this.setState({ loading: false, emailSent: true });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  // Sets loading to true, sends new passwords and token
  // if 200 show success message and redirect to login page after 20sec
  // else show error message
  //set loading to false
  submitNewPassword() {
    const { password, password2 } = this.state;
    const url = "/api/users/resetPassword/" + this.props.match.params.token;
    const payload = {
      password: password,
      password2: password2
    };
    this.setState({ loading: true });

    axios
      .post(url, payload)
      .then(res => {
        this.setState({ loading: false, passwordChanged: true });
        setTimeout(() => {
          this.props.history.push("/login");
        }, 10000);
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  //Reset the form if the user screwed up his email address
  resendResetLink = () => {
    const initialState = {
      email: "",
      emailSent: false,
      password: "",
      password2: "",
      passwordChanged: false,
      loading: false,
      errors: {}
    };
    this.setState(initialState);
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { token } = this.props.match.params;
    const {
      email,
      emailSent,
      password,
      password2,
      passwordChanged,
      loading,
      errors
    } = this.state;

    //If token exists, the user got the link, render password inputs
    if (token) {
      if (passwordChanged) {
        return (
          <div className="form-container">
            <h1>
              <Translate id="forgotPassword.title" />
            </h1>
            <p>
              <Translate id="forgotPassword.redirect" />
            </p>
            <Link className="btn-link" to="/login">
              <Translate id="forgotPassword.toLogin" />
            </Link>
          </div>
        );
      } else {
        return (
          <div className="form-container">
            <h1>
              <Translate id="forgotPassword.title" />
            </h1>
            <div className="mt-3 mb-3">
              <small>
                <Translate id="user.passwordHint" />
              </small>
            </div>
            <TextField
              name="password"
              value={password}
              label="Password"
              type="password"
              autoComplete="new-password"
              orientation="horizontal"
              onChange={this.onChange}
              error={errors.password ? errors.password : errors.message}
            />
            <TextField
              name="password2"
              value={password2}
              label="Confirm password"
              type="password"
              autoComplete="new-password"
              orientation="horizontal"
              onChange={this.onChange}
              error={errors.password2}
            />
            <Button
              text="Reset my password"
              isLoading={loading}
              className="btn btn-primary"
              onClick={this.submitNewPassword}
            />
          </div>
        );
      }
      //If there is no token, render email input
    } else {
      //If email was succesfully sent, show message && reset form link
      if (emailSent) {
        return (
          <div className="form-container">
            <h1>
              <Translate id="forgotPassword.checkEmail" />
            </h1>
            <div className="mt-3 mb-3">
              <p>
                <Translate id="forgotPassword.linkSent" /> {email}.
              </p>
              <span onClick={this.resendResetLink} className="btn-link">
                <Translate id="forgotPassword.resendLink" />
              </span>
            </div>
          </div>
        );
      } else {
        // Email input
        return (
          <div className="form-container">
            <h1>
              <Translate id="forgotPassword.title" />
            </h1>
            <div className="mt-3 mb-3">
              <p>
                <Translate id="forgotPassword.giveEmail" />
              </p>
            </div>
            <TextField
              name="email"
              value={email}
              label={<Translate id="user.emailAddress" />}
              type="email"
              orientation="horizontal"
              onChange={this.onChange}
              error={errors.email ? errors.email : errors.message}
            />
            <Button
              text={<Translate id="button.submit" />}
              isLoading={loading}
              className="btn btn-primary"
              onClick={this.submitEmail}
            />
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  {}
)(ForgotPassword);
