import React, { Component } from 'react';
import axios from 'axios';

export class DistributorContent extends Component {
  constructor() {
    super();
    this.state = {
      html: '',
      errors: {}
    };
  }

  componentDidMount() {
    const url = '/api/distributors/content';
    axios
      .get(url)
      .then(res => {
        this.setState({
          html: res.data.html
        });
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  }
  render() {
    const { html, errors } = this.state;
    return (
      <div className='container mt-5'>
        <p className='text-danger'>{errors.message}</p>

        <p dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  }
}

export default DistributorContent;
