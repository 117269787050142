import React from 'react';
import LanguageToggle from '../common/LanguageToggle';
import logo from '../../assets/logo-footer.png';
import fb from '../../assets/img-icon-social-facebook.svg';
import twitter from '../../assets/img-icon-social-twitter.svg';
import instagram from '../../assets/img-icon-social-instagram.svg';

const Footer = props => {
  return (
    <footer id='footer'>
      <div className='container'>
        <div className='footer-top'>
          <img className='footer-logo' src={logo} alt='milk-logo' />
          <p style={{ fontSize: '18px', marginTop: '25px' }}>
            <strong>
              Proudly brought to you by the Dairy Farmers of Ontario.
            </strong>
          </p>
        </div>
      </div>

      <div className='footer-middle container'>
        <div className='row'>
          <div className='col-6 offset-md-2 col-md-4 offset-lg-0 col-lg-3'>
            <h2>Consumers</h2>
            <div style={{ paddingLeft: '20px' }}>
              <ul className='list-unstyled'>
                <li>
                  <a className='' href=''>
                    2020 Milk Calendar
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    About Us
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Careers
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Contact
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Culinary
                  </a>
                </li>
                <li className='mb-2'>
                  <a className='' href=''>
                    Elementary School Milk Program
                  </a>
                </li>

                <li>
                  <a className='' href=''>
                    Media
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    MilkUP
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Our Milk
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Programs
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-6 col-md-4 col-lg-3'>
            <h2>Producers</h2>
            <div style={{ paddingLeft: '20px' }}>
              <ul className='list-unstyled'>
                <li>
                  <a className='' href=''>
                    Dairy Farmers of Canada
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Processors
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Producers
                  </a>
                </li>
                <li>
                  <a className='' href=''>
                    Transporters
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-12 col-md-12 col-lg-6'>
            <h2>Language</h2>
            <LanguageToggle />
            <div className='footer-social-container'>
              <h2>Follow us</h2>
              <a href='#'>
                <img className='footer-social-icon' src={fb} alt='fb-logo' />
              </a>
              <a href='#'>
                <img
                  className='footer-social-icon'
                  src={instagram}
                  alt='instagram-logo'
                />
              </a>
              <a href='#'>
                <img
                  className='footer-social-icon'
                  src={twitter}
                  alt='twitter-logo'
                />
              </a>
            </div>
          </div>
        </div>

        <div className='footer-bottom'>
          <div className='footer-bottom-inner'>
            <ul className='list-unstyled'>
              <li className='footer-bottom-link'>
                <a href=''>Privacy & Accessibility Policies</a>
              </li>
              <li className='footer-bottom-link'>
                <span>|</span>
              </li>
              <li className='footer-bottom-link'>
                <a href=''>Terms and Conditions</a>
              </li>
            </ul>
          </div>
          <p className='footer-copyright'>
            © 2019 Dairy Farmers of Ontario. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
