import {
  SET_ADMIN_LOADING,
  GET_USERS,
  GET_DISTRIBUTORS
} from '../actions/types';

const initialState = {
  users: {},
  distributors: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    case GET_DISTRIBUTORS:
      return {
        ...state,
        distributors: action.payload,
        loading: false
      };
    case SET_ADMIN_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
