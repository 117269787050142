import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSchoolOrders } from '../../redux/actions/schoolActions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import _ from 'lodash';
import OrderList from './OrderList';
import Pagination from '../common/Pagination';
import OrderSearchForm from './OrderSearchForm';
import Button from '../common/Button';
import axios from 'axios';
import moment from 'moment';

class SchoolOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      childName: '',
      orderId: '',
      status: '', // Can be active, paused, cancelled and all
      loadingExport: false,
      errors: {}
    };
  }

  componentDidMount() {
    this.props.getSchoolOrders(this.props.match.params.school, this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }
  }

  loadPage = page => {
    this.props.getSchoolOrders(
      this.props.match.params.school,
      page,
      this.state.childName,
      this.state.orderId,
      this.state.status
    );
    this.setState({ page: page });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = e => {
    e.preventDefault();
    this.props.getSchoolOrders(
      this.props.match.params.school,
      1,
      this.state.childName,
      this.state.orderId,
      this.state.status
    );
  };

  handleExport = () => {
    const { childName, status } = this.state;
    const url = `/api/schools/${this.props.match.params.school}/orders`;
    const payload = {
      page: 1,
      name: childName,
      status: status,
      csv: true
    };
    this.setState({ loadingExport: true });
    axios
      .get(url, { params: payload })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `orders-export-${moment().format('MM-DD-YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        this.setState({ loadingExport: false });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loadingExport: false });
      });
  };

  render() {
    const { errors, status, loadingExport } = this.state;
    const { orders } = this.props;
    return (
      <div className='container mt-5'>
        <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div>
            <h2>
              <Translate id='schoolOrders.title' />
            </h2>
            <p>Total orders: {orders.totalResults}</p>
            <span className='text-danger'>{errors.message}</span>
          </div>
          <OrderSearchForm
            handleChange={this.handleChange}
            handleSearch={this.handleSearch}
            loading={this.props.loading}
            status={status}
          />
        </div>

        <Button
          text={<Translate id='button.export' />}
          isLoading={loadingExport}
          className='btn btn-sm btn-primary mb-3'
          onClick={this.handleExport}
        />

        <OrderList
          orders={this.props.orders.results}
          loading={this.props.loading}
        />

        {this.props.orders.totalResults ? (
          <Pagination
            totalRecords={this.props.orders.totalResults}
            page={this.state.page.toString()}
            history={this.props.history}
            onPageChange={this.loadPage}
          />
        ) : null}
      </div>
    );
  }
}

SchoolOrders.propTypes = {
  orders: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  orders: state.school.orders,
  loading: state.school.loading,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getSchoolOrders }
)(withRouter(SchoolOrders));
