import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import EditUser from "./EditUser";

export class UserListItem extends Component {
  state = {
    showModal: false
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    const { user } = this.props;
    const { showModal } = this.state;
    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-sm-3 col-10 text-truncate">{user.email}</div>
          <div className="col-sm-3 text-truncate d-none d-md-block">
            {user.first_name}
          </div>
          <div className="col-sm-3 text-truncate d-none d-md-block">
            {user.last_name}
          </div>
          <div className="col-sm-2 text-truncate d-none d-md-block">
            {user.role}
          </div>
          <div className="col-sm-1 col-2 text-right">
            <i onClick={this.showModal} className="fas fa-ellipsis-v" />
          </div>
        </div>
        <Modal centered size="lg" show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>User details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditUser id={user.id} />
          </Modal.Body>
        </Modal>
      </li>
    );
  }
}
UserListItem.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserListItem;
