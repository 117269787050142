import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardNavThumb from '../layout/DashboardNavThumb';
import { Translate } from 'react-localize-redux';

export class AdminDashboard extends Component {
  componentDidMount() {
    document.title = 'Admin Dashboard - Dairy Farmers of Ontario ESMP Portal';
  }
  render() {
    return (
      <div>
        <h1>
          <Translate id='adminDashboard.title' />
        </h1>
        <p>
          <Translate id='adminDashboard.welcome' />
        </p>
        <p>
          <Translate id='adminDashboard.hint' />
        </p>
        <div className='dashboradNavThumb-container'>
          <DashboardNavThumb
            text={<Translate id='adminDashboard.manageSchools' />}
            link='/admin/dashboard/schools'
          />

          <DashboardNavThumb
            text={<Translate id='adminDashboard.manageContent' />}
            link='/admin/dashboard/distributor-content'
          />

          <DashboardNavThumb
            text={<Translate id='adminDashboard.notes' />}
            link='/admin/dashboard/school-notes'
          />
          <DashboardNavThumb
            text={<Translate id='adminDashboard.addschool' />}
            link='/admin/dashboard/addschool'
          />
          {this.props.role === 'SUPER' ? (
            <React.Fragment>
              <DashboardNavThumb
                text={<Translate id='adminDashboard.addDistributor' />}
                link='/admin/dashboard/add-distributor'
              />
              <DashboardNavThumb
                text={<Translate id='adminDashboard.manageUsers' />}
                link='/admin/dashboard/manage-users'
              />
              <DashboardNavThumb
                text={<Translate id='adminDashboard.manageDistributors' />}
                link='/admin/dashboard/distributors'
              />
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

AdminDashboard.propTypes = {
  role: PropTypes.string.isRequired
};

const mapStatetoProps = state => ({
  role: state.auth.user.role
});

export default connect(
  mapStatetoProps,
  {}
)(AdminDashboard);
