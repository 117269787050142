import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../utils';

export class OrderPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteMilkSchedule: {},
      chocolateMilkSchedule: {}
    };
  }

  render() {
    const {
      text,
      whiteMilkSchedule,
      chocolateMilkSchedule,
      whiteMilkPrice,
      chocolateMilkPrice
    } = this.props;

    let school_wm_price = whiteMilkPrice ? whiteMilkPrice : 0;
    let school_cm_price = chocolateMilkPrice ? chocolateMilkPrice : 0;
    let wprice = 0;
    let cprice = 0;

    for (var key in whiteMilkSchedule) {
      let val;
      if (
        whiteMilkSchedule.hasOwnProperty(key) &&
        !isNaN(parseInt(whiteMilkSchedule[key]))
      ) {
        val = parseInt(whiteMilkSchedule[key]);
        wprice = wprice + val;
      }
    }

    for (var yek in chocolateMilkSchedule) {
      let val;
      if (
        chocolateMilkSchedule.hasOwnProperty(yek) &&
        !isNaN(parseInt(chocolateMilkSchedule[yek]))
      ) {
        val = parseInt(chocolateMilkSchedule[yek]);
        cprice = cprice + val;
      }
    }

    let price = wprice * school_wm_price + cprice * school_cm_price;

    const txt = isEmpty(text) ? null : `${text} `;

    return (
      <div>
        {txt}
        <strong className='text-truncate'>
          $
          {price
            .toFixed(2)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
        </strong>
      </div>
    );
  }
}

OrderPrice.propTypes = {
  text: PropTypes.string,
  whiteMilkPrice: PropTypes.number.isRequired,
  chocolateMilkPrice: PropTypes.number.isRequired,
  whiteMilkSchedule: PropTypes.object.isRequired,
  chocolateMilkSchedule: PropTypes.object.isRequired
};

export default OrderPrice;
