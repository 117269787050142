export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOADING = 'SET_LOADING';

// School
export const GET_SCHOOL = 'GET_SCHOOL';
export const GET_SCHOOLS = 'GET_SCHOOLS';
export const SET_SCHOOL_LOADING = 'SET_SCHOOL_LOADING';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_ORDERS = 'GET_ORDERS';

// Admin
export const GET_USERS = 'GET_USERS';
export const SET_ADMIN_LOADING = 'SET_ADMIN_LOADING';
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';
export const GET_NOTES = 'GET_NOTES';
export const CLEAR_NOTES = 'CLEAR_NOTES';

// DISTRIBUTORS
export const SET_DIST_LOADING = 'SET_DIST_LOADING';
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR';
export const GET_DISTRIBUTOR_ORDERS = 'GET_DISTRIBUTOR_ORDERS';
export const CLEAR_DISTRIBUTOR = ' CLEAR_DISTRIBUTOR';
