import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getSchoolById,
  clearSchool,
  clearOrders,
  clearNotes,
} from "../../redux/actions/schoolActions";
import { Redirect, Link } from "react-router-dom";
import SchoolProfile from "./SchoolProfile";
import SchoolOrders from "./SchoolOrders";
import SchoolHeader from "./SchoolHeader";
import SchoolRegistration from "./SchoolRegistration";
import { isEmpty } from "../../utils";
import Button from "../common/Button";
import { Modal } from "react-bootstrap";
import CreateNote from "../notes/CreateNote";
import SchoolNotes from "../notes/SchoolNotes";

class School extends Component {
  constructor() {
    super();
    this.state = {
      prevPath: "",
      showModal: false,
    };
  }
  componentDidMount() {
    this.props.getSchoolById(this.props.match.params.school);
  }

  renderTabs = (tab) => {
    if (!isEmpty(this.props.school)) {
      switch (tab) {
        case "orders":
          return <SchoolOrders />;
        case "registration":
          return <SchoolRegistration />;
        case "profile":
          return <SchoolProfile />;
        case "notes":
          return (
            <SchoolNotes
              schoolId={this.props.match.params.school}
              schoolName={this.props.school.name}
            />
          );

        default:
          const admin = this.props.user === "COORDINATOR" ? "" : "/admin";
          // This is temporary until we decide what we want on the school page
          return (
            <Redirect
              to={`${admin}/dashboard/school/${this.props.match.params.school}/profile`}
            />
          );
      }
    }
  };

  componentWillUnmount() {
    this.props.clearSchool();
    this.props.clearOrders();
    this.props.clearNotes();
  }

  backToMySchools = () => {
    if (this.props.user === "COORDINATOR") {
      return (
        <Link className="btn btn-link" to="/dashboard/my-schools">
          Back to my schools
        </Link>
      );
    } else return null;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
  };

  componentWillUnmount = () => {
    this.props.clearNotes();
  };

  render() {
    const { showModal } = this.state;
    let schoolName = "Loading..";
    if (!isEmpty(this.props.school)) {
      schoolName = this.props.school.name;
    }

    if (this.props.school.uses_SCO && this.props.user === "COORDINATOR")
      return <Redirect to="/sco" />;

    return (
      <div className="container">
        {this.backToMySchools()}
        <div className="d-flex justify-content-between align-items-center">
          <h1>School dashboard</h1>
        </div>
        <h2>{schoolName}</h2>
        <SchoolHeader user={this.props.user} />
        {this.renderTabs(this.props.match.params.type)}
        <Modal show={showModal} size="md" onHide={this.hideModal}>
          <Modal.Header>
            <Modal.Title>New note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateNote
              schoolId={this.props.match.params.school}
              hideModal={this.hideModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user.role,
  school: state.school.profile,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getSchoolById,
  clearSchool,
  clearOrders,
  clearNotes,
})(School);
