import React from 'react';
// import Select from 'react-select/lib/Async';
import Async from 'react-select/async';
import PropTypes from 'prop-types';

const AsyncSelect = ({
  name,
  label,
  value,
  error,
  loadOptions,
  onChange,
  helperText,
  isClearable,
  placeholder,
  disabled,
  required,
  isLoading,
  orientation // horizontal || vertical(default)
}) => {
  if (orientation === 'horizontal') {
    return (
      <div className='form-group row'>
        <label className='col-sm-4 col-form-label' htmlFor={name}>
          {label}
          {required ? '*' : null}
        </label>
        <div className='col-sm-8'>
          <Async
            name={name}
            value={value}
            cacheOptions
            placeholder={placeholder}
            loadOptions={loadOptions}
            onChange={onChange}
            isClearable={isClearable}
            isDisabled={disabled}
            isLoading={isLoading}
            noOptionsMessage={() => 'Start typing to search'}
          />
          {helperText && (
            <small className='form-text text-muted'>{helperText}</small>
          )}
          {error && <small className='text-danger'>{error}</small>}
        </div>
      </div>
    );
  } else {
    return (
      <div className='form-group'>
        <label htmlFor={name}>
          {label}
          {required ? '*' : null}
        </label>
        <Async
          name={name}
          value={value}
          cacheOptions
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          isClearable={isClearable}
          isDisabled={disabled}
          isLoading={isLoading}
          noOptionsMessage={() => 'Start typing to search'}
        />
        {helperText && (
          <small className='form-text text-muted'>{helperText}</small>
        )}
        {error && <small className='text-danger'>{error}</small>}
      </div>
    );
  }
};

AsyncSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  orientation: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  required: PropTypes.bool,
  isLoading: PropTypes.bool
};

AsyncSelect.defaultProps = {
  type: 'text',
  orientation: 'vertical',
  isClearable: true,
  helperText: 'Start typing to search',
  required: false
};

export default AsyncSelect;
