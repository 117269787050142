import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import schoolReducer from './schoolReducer';
import adminReducer from './adminReducer';
import distributorReducer from './distributorReducer';

export default combineReducers({
  auth: authReducer,
  school: schoolReducer,
  admin: adminReducer,
  distributor: distributorReducer,
  errors: errorReducer
});
