import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TextArea = ({
  name,
  placeholder,
  value,
  label,
  error,
  type,
  onChange,
  helperText,
  autoComplete,
  orientation, // horizontal || vertical(default)
  maxLength,
  required,
  disabled
}) => {
  if (orientation === 'horizontal') {
    return (
      <div className='form-group row'>
        <label className='col-sm-4 col-form-label' htmlFor={name}>
          {label}
          {required ? '*' : null}
        </label>
        <div className='col-sm-8'>
          <textarea
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={classnames('form-control', {
              'is-invalid': error
            })}
            autoComplete={autoComplete}
            maxLength={maxLength}
            disabled={disabled}
            rows={5}
          />
          {helperText && (
            <small className='form-text text-muted'>{helperText}</small>
          )}
          {error && <div className='invalid-feedback'>{error}</div>}
        </div>
      </div>
    );
  } else {
    return (
      <div className='form-group'>
        <label htmlFor={name}>
          {label}
          {required ? '*' : null}
        </label>

        <textarea
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={classnames('form-control', {
            'is-invalid': error
          })}
          autoComplete={autoComplete}
          maxLength={maxLength}
          disabled={disabled}
          rows={5}
        />
        {helperText && (
          <small className='form-text text-muted'>{helperText}</small>
        )}
        {error && <div className='invalid-feedback'>{error}</div>}
      </div>
    );
  }
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  orientation: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool
};

TextArea.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  orientation: 'vertical',
  required: false
};

export default TextArea;
