import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNotes } from '../../redux/actions/schoolActions';
import PropTypes from 'prop-types';
import TextArea from '../inputs/TextArea';
import Button from '../common/Button';
import axios from 'axios';

export class EditNote extends Component {
  state = {
    text: '',
    loading: false,
    errors: {}
  };

  componentDidMount() {
    const { note } = this.props;
    if (note) {
      this.setState({ text: note.text });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const noteId = this.props.note.id;
    const { schoolId } = this.props;
    const payload = {
      text: this.state.text
    };
    const url = `/api/notes/${noteId}`;
    axios
      .put(url, payload)
      .then(res => {
        //call hideModal on success
        this.props.getNotes(schoolId);
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  };

  handleDelete = () => {
    const noteId = this.props.note.id;
    const { schoolId } = this.props;

    const url = `/api/notes/${noteId}`;
    axios
      .delete(url)
      .then(res => {
        //call hideModal on success
        this.props.getNotes(schoolId);
      })
      .catch(err => {
        this.setState({ errors: err.response.data });
      });
  };

  render() {
    const { text, loading, errors } = this.state;
    return (
      <div className='container'>
        <p className='text-danger'>{errors.text}</p>
        <TextArea
          name='text'
          value={text}
          label='Note text'
          onChange={this.handleChange}
        />
        <div className='d-flex justify-content-between align-items-center'>
          <Button
            className='btn btn-primary'
            text='Save'
            onClick={this.handleSubmit} // Change to handleSubmit
            loading={loading}
          />
          <Button
            className='btn btn-danger'
            text='Delete'
            onClick={this.handleDelete} // Change to handleSubmit
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  getNotes
};

EditNote.propTypes = {
  note: PropTypes.object.isRequired,
  getNotes: PropTypes.func.isRequired,
  schoolId: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNote);
