import React from 'react';
import { Translate } from 'react-localize-redux';

export default function DistOrdersHeader() {
  return (
    <div className='row table-header'>
      <div className='col-md-1 col-2 text-truncate'>
        <Translate id='orderList.id' />
      </div>
      <div className='col-md-3 col-8 text-truncate'>
        <Translate id='orderList.childName' />
      </div>
      <div className='col-md-1 d-none d-md-block'>Cost</div>
      <div className='col-sm-4 text-truncate d-none d-md-block'>
        <Translate id='orderList.school' />
      </div>
      <div className='col-sm-2 d-none d-md-block'>
        <Translate id='orderList.status' />
      </div>
      <div className='col-sm-1 col-2 text-right'>
        {/* <Translate id='schoolList.actions' /> */}
      </div>
    </div>
  );
}
