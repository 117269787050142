import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UserSchoolListHeader from "./UserSchoolListHeader";
import Spinner from "../common/Spinner";
import { Translate } from "react-localize-redux";
import { isEmpty } from "../../utils";

const UserSchoolList = ({ schools, userRole }) => {
  let schoolList;
  const urlPrefix =
    userRole === "COORDINATOR"
      ? "/dashboard"
      : `/${userRole.toLowerCase()}/dashboard`;
  if (!isEmpty(schools)) {
    schoolList = schools.map((school) => {
      return (
        <li key={school.id} className="list-group-item">
          <div className="row">
            <div className="col-md-3 col-10 text-truncate">
              <Link to={`${urlPrefix}/school/${school.id}`}>
                {school.name}{" "}
              </Link>
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.registration.schoolYear.name}
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.city}
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.phone}
            </div>
            <div className="col-sm-2 text-truncate d-none d-md-block">
              {school.registration.is_registered ? (
                <Translate id="schoolList.registered" />
              ) : (
                <Translate id="schoolList.notRegistered" />
              )}
            </div>
            <div className="col-sm-1 col-2 text-right">
              <Link to={`${urlPrefix}/school/${school.id}`}>
                <i className="fas fa-ellipsis-v" />
              </Link>
            </div>
          </div>
        </li>
      );
    });
  } else {
    schoolList = <Spinner />;
  }

  return (
    <div>
      <UserSchoolListHeader />
      <ul className="list-group">{schoolList}</ul>
    </div>
  );
};

UserSchoolList.propTypes = {
  schools: PropTypes.array,
  userRole: PropTypes.string.isRequired,
};

export default UserSchoolList;
