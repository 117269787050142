import React from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import DummyField from '../inputs/DummyField';
import FormSeparator from '../inputs/FormSeparator';
import { Translate } from 'react-localize-redux';

const DistOrderDetails = props => {
  const renderMilkSchedule = () => {
    const { whiteMilkSchedule, chocolateMilkSchedule } = props.order;

    return (
      <div className='row'>
        <div className='col'>
          <FormSeparator text='White milk schedule' />
          <DummyField
            orientation='horizontal'
            label='Monday'
            value={whiteMilkSchedule.monday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Tuesday'
            value={whiteMilkSchedule.tuesday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Wednesday'
            value={whiteMilkSchedule.wednesday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Thursday'
            value={whiteMilkSchedule.thursday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Friday'
            value={whiteMilkSchedule.friday.toString()}
          />
        </div>

        <div className='col'>
          <FormSeparator text='Chocolate milk schedule' />
          <DummyField
            orientation='horizontal'
            label='Monday'
            value={chocolateMilkSchedule.monday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Tuesday'
            value={chocolateMilkSchedule.tuesday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Wednesday'
            value={chocolateMilkSchedule.wednesday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Thursday'
            value={chocolateMilkSchedule.thursday.toString()}
          />
          <DummyField
            orientation='horizontal'
            label='Friday'
            value={chocolateMilkSchedule.friday.toString()}
          />
        </div>
      </div>
    );
  };
  return (
    <div className='p-3'>
      <DummyField
        orientation='horizontal'
        label={<Translate id='orders.childName' />}
        value={props.order.child_name}
      />
      <DummyField
        orientation='horizontal'
        label={<Translate id='schoolList.schoolName' />}
        value={props.order.school.label}
      />

      {renderMilkSchedule()}

      <Button
        text={<Translate id='button.close' />}
        className='btn btn-secondary'
        onClick={props.closeModal}
      />
    </div>
  );
};

DistOrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default DistOrderDetails;
