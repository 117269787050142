import React from "react";
import spinner from "./spinner.gif";

export default function Spinner(props) {
  return (
    <div>
      <img src={spinner} style={{ width: "50px" }} alt="Loading..." />
    </div>
  );
}
