import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDistributorProfile } from '../../redux/actions/distributorActions';
import { isEmpty } from '../../utils';
import { Translate } from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import DistProfile from './DistProfile';
import DistOrders from './DistOrders';
import DistHeader from './DistHeader';
import DistributorContent from './DistributorContent';

export class DistDashboard extends Component {
  componentDidMount() {
    if (isEmpty(this.props.auth.user.distributor)) {
      this.props.history.push('/distributor/claim');
    } else {
      this.props.getDistributorProfile(this.props.auth.user.distributor.id);
    }
  }

  renderTabs = tab => {
    switch (tab) {
      case 'orders':
        return <DistOrders />;
      case 'profile':
        return <DistProfile />;
      case 'home':
        return <DistributorContent />;
      default:
        return <Redirect to='/distributor/dashboard/home' />;
    }
  };

  render() {
    return (
      <div>
        <h1>
          <Translate id='dist.title' />
        </h1>
        <DistHeader />
        {this.renderTabs(this.props.match.params.tab)}
      </div>
    );
  }
}

DistDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  loading: state.distributor.loading,
  errors: state.errors
});

const mapDispatchToProps = {
  getDistributorProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistDashboard);
