import React, { Component } from 'react';
import axios from 'axios';
import { Translate } from 'react-localize-redux';

import TextField from '../inputs/TextField';
import NumberFormat from 'react-number-format';
import Button from '../common/Button';

import { Modal } from 'react-bootstrap';

export class ProfileSettings extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      email: '',
      password: '',
      newPassword: '',
      loading: true,
      success: '',
      showModal: false,
      errors: {}
    };
  }

  componentDidMount() {
    document.title = 'Profile Settings - Dairy Farmers of Ontario ESMP Portal';
    // Get user profile data and set as state
    axios
      .get('/api/users/getProfile')
      .then(res => {
        this.setState({
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          title: res.data.title,
          phone: res.data.phone,
          email: res.data.email,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  }

  // Submit user data
  handleSubmitProfileData = e => {
    e.preventDefault();
    this.setState({ loading: true, errors: {} });
    const payload = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      title: this.state.title,
      phone: this.state.phone,
      email: this.state.email //Do we want users to be able to change their email address?
    };
    axios
      .put('/api/users/editProfile', payload)
      .then(res => {
        this.setState({
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          title: res.data.title,
          phone: res.data.phone,
          email: res.data.email, //Do we want users to be able to change their email address?
          success: 'Saved',
          loading: false
        });
      })
      .catch(err => {
        this.setState({ errors: err.response.data, loading: false });
      });
  };

  // Change password
  handlePasswordChange = e => {
    e.preventDefault();
    this.setState({ loading: true, errors: {} });
    const payload = {
      password: this.state.password,
      newPassword: this.state.newPassword
    };
    axios
      .put('/api/users/changePassword', payload)
      .then(res => {
        this.setState({
          loading: false,
          success: 'Password changed',
          password: '',
          newPassword: ''
        });
      })
      .catch(err => {
        this.setState({ loading: false, errors: err.response.data });
      });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };
  hideModal = () => {
    this.setState({ showModal: false });
    this.props.getParentOrders();
  };

  render() {
    const {
      firstName,
      lastName,
      title,
      phone,
      email,
      password,
      newPassword,
      loading,
      success,
      showModal,
      errors
    } = this.state;

    return (
      <div>
        <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <h1>
            <Translate id='profileSettings.title' />
          </h1>
          <Button
            text={<Translate id='button.changePassword' />}
            className='btn btn-primary mt-3'
            onClick={this.showModal}
          />
        </div>
        {errors.message ? (
          <small className='text-danger'>{errors.message}</small>
        ) : (
          <small className='text-success'>{success}</small>
        )}
        <div className='row'>
          <div className='col-sm-6'>
            <form className='form-container mb-3 mt-3'>
              <TextField
                name='firstName'
                value={firstName}
                label={<Translate id='user.firstName' />}
                type='text'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.first_name}
              />
              <TextField
                name='lastName'
                value={lastName}
                label={<Translate id='user.lastName' />}
                type='text'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.last_name}
              />
              <TextField
                name='title'
                value={title}
                label={<Translate id='user.title' />}
                type='text'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.title}
              />
              <TextField
                name='email'
                value={email}
                label={<Translate id='user.emailAddress' />}
                type='email'
                orientation='horizontal'
                onChange={this.onChange}
                disabled
                error={errors.email}
              />
              <NumberFormat
                name='phone'
                label={<Translate id='user.phone' />}
                value={phone}
                customInput={TextField}
                format='###-###-####'
                placeholder='+1'
                orientation='horizontal'
                onChange={this.onChange}
                error={errors.phone}
              />

              <Button
                text={<Translate id='button.save' />}
                className='btn btn-primary mt-3'
                onClick={this.handleSubmitProfileData}
                isLoading={loading}
              />
            </form>
          </div>
        </div>

        <Modal centered size='lg' show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Change your password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='mt-3 mb-3'>
                <small>
                  <Translate id='user.passwordHint' />
                </small>
              </div>
              <form>
                <TextField
                  name='password'
                  value={password}
                  label={<Translate id='user.oldPassword' />}
                  type='password'
                  autoComplete='current-password'
                  orientation='horizontal'
                  onChange={this.onChange}
                  error={errors.password}
                />
                <TextField
                  name='newPassword'
                  value={newPassword}
                  label={<Translate id='user.newPassword' />}
                  type='password'
                  autoComplete='new-password'
                  orientation='horizontal'
                  onChange={this.onChange}
                  error={errors.newPassword}
                />
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                  <Button
                    text={<Translate id='button.cancel' />}
                    className='btn btn-secondary mt-3'
                    onClick={this.hideModal}
                  />
                  <Button
                    text={<Translate id='button.changePassword' />}
                    className='btn btn-primary mt-3'
                    onClick={this.handlePasswordChange}
                    isLoading={loading}
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ProfileSettings;
