import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getUsers } from '../../redux/actions/adminActions';
import UserListHeader from './UserListHeader';
import UserList from './UserList';
import Pagination from '../common/Pagination';
import Spinner from '../common/Spinner';
import { Translate } from 'react-localize-redux';

export class ManageUsers extends Component {
  constructor() {
    super();
    this.state = {
      roles: ['COORDINATOR'],
      allRoles: ['PARENT', 'DISTRIBUTOR', 'ADMIN', 'SUPER'],
      errors: {}
    };
  }

  componentDidMount() {
    document.title = 'Manage Users - Dairy Farmers of Ontario ESMP Portal';
    let page = 1;

    if (this.props.match.params.page) {
      page = this.props.match.params.page;
    }
    this.props.getUsers(page, this.state.roles);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  loadPage = page => {
    this.props.getUsers(page, this.state.roles);
    this.props.history.push('/admin/dashboard/manage-users/' + page);
  };

  // TODO: REFACTOR THIS SHIT PLEASE
  addToShowing = (item, index) => {
    // Add to roles
    const roles = this.state.roles;
    roles.push(item);
    // Remove from allRoles
    const allRoles = this.state.allRoles;
    allRoles.splice(index, 1);
    this.setState({ roles: roles, allRoles: allRoles });
    this.loadPage(1);
  };
  removeFromShowing = (item, index) => {
    if (this.state.roles.length > 1) {
      // Remove from roles
      const roles = this.state.roles;
      roles.splice(index, 1);
      // Add to allRoles
      const allRoles = this.state.allRoles;
      allRoles.push(item);
      this.setState({ roles: roles, allRoles: allRoles });
      this.loadPage(1);
    }
  };

  renderRoles() {
    const roles = this.state.roles.map((val, index) => {
      return (
        <p
          className='btn-link text-danger m-2'
          onClick={() => this.removeFromShowing(val, index)}
          key={index}
        >
          <i className='far fa-minus-square mr-1' />
          {val}
        </p>
      );
    });
    const allRoles = this.state.allRoles.map((val, index) => {
      return (
        <p
          className='btn-link m-2'
          onClick={() => this.addToShowing(val, index)}
          key={index}
        >
          <i className='far fa-plus-square mr-1' />
          {val}
        </p>
      );
    });
    return (
      <div className='d-flex justify-content-between align-items-center flex-wrap m-3'>
        <div className='d-flex align-items-center flex-wrap'>
          <strong>
            <Translate id='manageUsers.nowShowing' />:
          </strong>
          {roles}
        </div>
        <div className='d-flex align-items-center flex-wrap'>{allRoles}</div>
      </div>
    );
  }

  renderUserList = () => {
    const { loading, errors, users, history } = this.props;
    if (loading) {
      return <Spinner />;
    } else if (errors.message === 'No users found') {
      return null;
    } else
      return (
        <React.Fragment>
          <UserList users={users.results} />
          {users.totalResults ? (
            <Pagination
              totalRecords={users.totalResults}
              page={this.props.match.params.page}
              history={history}
              onPageChange={this.loadPage}
            />
          ) : null}
        </React.Fragment>
      );
  };

  render() {
    const { errors } = this.state;
    if (this.props.userRole === 'SUPER') {
      return (
        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <h1>
                <Translate id='manageUsers.title' />
              </h1>
              <span className='text-danger'>{errors.message}</span>
            </div>

            {this.props.userRole !== 'SUPER' ? null : (
              <Link
                to='/admin/dashboard/manage-users/add/new-admin'
                className='btn btn-primary'
              >
                <Translate id='button.addAdmin' />
              </Link>
            )}
          </div>
          {this.renderRoles()}
          <UserListHeader />

          {this.renderUserList()}
        </div>
      );
    } else return <Redirect to='/dashboard' />;
  }
}

ManageUsers.propTypes = {
  userRole: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  userRole: state.auth.user.role,
  users: state.admin.users,
  loading: state.admin.loading,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getUsers }
)(ManageUsers);
